import { createSlice } from '@reduxjs/toolkit'


export const WishList = createSlice({
    name: 'wishlist',
    initialState : {
        list : [],
    },
    reducers: {
        setWishList( state , action ) {
            state.list = action.payload;
        },
        setNewWishList ( state , action ) {
            state.list = [
                ...state.list,
                { ...action?.payload }
            ];
        },
        removeWishList( state , action ){
            state.list = state?.list?.filter((val)=> parseInt(val?.id) !== parseInt(action?.payload));
        },
    },
})

// Action creators are generated for each case reducer function
export const { setWishList , setNewWishList , removeWishList } = WishList.actions

export default WishList.reducer