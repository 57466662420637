import { createSlice } from '@reduxjs/toolkit'

export const Blog = createSlice({
    name: 'blogs',
    initialState : {
        blogs : [],
        blog : null
    },
    reducers: {
        setBlogs(state,action) {
          state.blogs = action.payload;
        },
        setBlog (state,action) {
            state.blog = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setBlogs , setBlog } = Blog.actions

export default Blog.reducer