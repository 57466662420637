import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {blogDetailApi, blogsApi, packageApi, packagePlaceOrderApi, packagesApi} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {setBlog, setBlogs, setPackage, setPackages} from "./Reducer";
import {headers} from "../headers";


export const getPackagesRequest = createAsyncThunk('get-packages-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(packagesApi,headers());
        dispatch(setPackages(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getPackageRequest = createAsyncThunk('get-package-request',async (
    id,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    }) => {
    try {
        let resp = await axios.get(packageApi(id),headers());
        dispatch(setPackage(resp.data.response.data));
        return fulfillWithValue(true);
    } catch ( e ) {
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const placeOrderPackageRequest = createAsyncThunk('package-place-order-request',async (
    data,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    }) => {
    try {
        let resp = await axios.post(packagePlaceOrderApi,data,headers());
        dispatch( setError([{
            message : 'Thank you for placing your order!' , color : 'success'
        }]));
        return fulfillWithValue(true);
    } catch ( e ) {
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});