import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StripeInjected from "../Stripe/StripeInjected";
import PaymentMethodWrapper from "../PaymentMethod/Wrapper";
import {
	addCardRequest,
	deleteWishListRequest,
	getCardListRequest,
	getWishListRequest,
} from "../../redux/Cards/Action";
const Cards = () => {
	const dispatch = useDispatch();
	const [step, setStep] = useState(false);
	const cards = useSelector((state) => state?.cards?.list);
	const [stripeToken, setStripeToken] = useState({
		generate: false,
		token: "",
	});

	const onSubmitHandler = async (token) => {
		await dispatch(
			addCardRequest({
				source: token,
			}),
		);
		setStep(false);
	};

	const onDeleteHandler = async (id) => {
		await dispatch(deleteWishListRequest(id));
	};

	useEffect(() => {
		dispatch(getCardListRequest());
	}, []);
	// console.log("Profi;le",form);

	return (
		<>
			<div className="form-tabs">
				{step ? (
					<PaymentMethodWrapper type={"stripe"}>
						<div className="row align-items-start mb-2">
							<div className="col-lg-12">
								<div className="row">
									<StripeInjected
										stripeToken={stripeToken}
										setStripeToken={setStripeToken}
										customFunction={onSubmitHandler}
									/>
								</div>
							</div>
						</div>
					</PaymentMethodWrapper>
				) : (
					<div className="row">
						<div className="col-lg-12 d-flex justify-content-end">
							<div className="col-lg-3 p-lg-0 mb-2">
								<button
									type={"button"}
									onClick={() => setStep(true)}
									className="btn-review5 btn-review-btn-wrapper mt-0"
								>
									Add Card
								</button>
							</div>
						</div>
						<div className="col-lg-12 p-lg-0">
							<div
								className="table-wrapper"
								style={{ marginTop: "20px", marginBottom: "0px" }}
							>
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th scope="col">
													<span>CardType</span>
												</th>
												<th scope="col">
													<span>Card No</span>
												</th>
												<th scope="col">
													<span>Exp</span>
												</th>
												<th scope="col">
													<span>Action</span>
												</th>
											</tr>
										</thead>
										<tbody>
											{cards?.map((val, key) => (
												<tr key={key}>
													<td>{val?.brand}</td>
													<td>**** **** **** {val?.last4}</td>
													<td>
														<span className={"badge bg-info p-2 text-white"}>
															{val?.exp_month}/{val?.exp_year}
														</span>
													</td>
													<td>
														<button
															type={"button"}
															className="btntable"
															onClick={() => onDeleteHandler(val?.id)}
														>
															Delete
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Cards;
