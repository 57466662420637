import React from "react";
import '../../assets/css/blog-detail.css';
import { cookies, LinkImg, rib, ribion, ring, shopgroup3, shopImage } from "../../constant/Index";
import { filePreviewer, profilePic } from "../../constant/helpers";
import moment from "moment";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";

const DetailSection = ({ blog }) => {

    return (
        <section className="blogs-sec">
            <div className="side-image-1-row">
                <img src={cookies} className="img-fluid cookies" alt="" />
                <img src={rib} className="img-fluid rib" alt="" />
                <img src={shopgroup3} className="img-fluid rib1" alt="" />
                <img src={ribion} className="img-fluid link" alt="" />
                <img src={ring} className="img-fluid links" alt="" />
                <img src={LinkImg} className="img-fluid links1" alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10">
                        <div className="blogs-detail-heading"
                        // data-aos="fade-down"
                        // data-aos-anchor-placement="center-center"
                        // data-aos-duration="3000"
                        >
                            <h2>{blog?.title}</h2>
                            <p className="orci-para">
                                {blog?.sub_title}
                            </p>
                        </div>
                        <div className="blog-detail-pera">
                            <div className="smileman-heading">
                                <div className="double-div">
                                    <img src={profilePic(blog?.admin?.first_name + " " + blog?.admin?.last_name)}
                                        className="img-fluid" alt="" />
                                    <div className="heading-para-blogs">
                                        <h5>{blog?.admin?.first_name + ' ' + blog?.admin?.last_name}</h5>
                                        <p>Published on {moment(new Date()).format('MMMM Do YYYY')}</p>
                                    </div>
                                </div>
                                <div className="share-items">
                                    <h6>Share:</h6>
                                    <ul>
                                        <li>
                                            <FacebookShareButton url={window.location.href}>
                                                <i className="shareIcon fa fa-brands fa-facebook-f"></i>
                                            </FacebookShareButton>
                                            {/*<a href="">*/}
                                            {/*</a>*/}
                                        </li>
                                        <li>
                                            <TwitterShareButton url={window.location.href}>
                                                <i className="shareIcon fa fa-brands fa-twitter"></i>
                                            </TwitterShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url={window.location.href}>
                                                <i className="shareIcon fa fa-brands fa-linkedin"></i>
                                            </LinkedinShareButton>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <img src={filePreviewer(blog?.banner_image)}
                            className="img-fluid little-girl" width={"100%"}
                            alt=""
                        />
                        <p
                            // data-aos="fade-down"
                            // data-aos-anchor-placement="start-start"
                            // data-aos-duration="1000"
                            dangerouslySetInnerHTML={{ __html: blog?.description }}
                        />
                    </div>
                </div>
            </div>
        </section>

    )
};

export default DetailSection;
