
import React from "react";
import { useSelector } from "react-redux";
import { filePreviewer } from "../../constant/helpers";
import { useNavigate } from "react-router-dom";
import {
	APPLES,
	BAGS,
	CANDY, CHOCCLATE_PLATTER_ITEM, CHOCOLATE_PLATTER_ITEM,
	COOKIES,
	OREOS,
	RODS,
} from "../../routes/RouteLinks";

const BottomSection = () => {
	const navigate = useNavigate();
	const platters = useSelector((state) => state?.pretzels);

	return (
		<section className="care-pakage">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<h4
							className="holiday"
							// data-aos="fade-down" data-aos-anchor-placement="center-center"
							// data-aos-duration="3000"
						>
							Chocolate And Pretzels (Great for parties, get-togethers, or
							sharing at the office!)
						</h4>
					</div>
				</div>

				<div className="row">
					{/*{*/}
					{/*    platters?.cookies &&*/}
					{/*    <div className="col-lg-3 col-md-6">*/}
					{/*        <div className="card-wrapper"*/}
					{/*            // data-aos="flip-left" data-aos-easing="ease-out-cubic"*/}
					{/*            // data-aos-duration="1000"*/}
					{/*        >*/}
					{/*            <img src={filePreviewer(platters?.cookies?.image)} className="img-fluid" alt=""/>*/}
					{/*            <div className="card-border-heading">*/}
					{/*                <div className="list-price">*/}
					{/*                    <h6>{platters?.cookies?.title}</h6>*/}
					{/*                    <ul>*/}
					{/*                        <li><i className="fa fa-solid fa-check"></i>*/}
					{/*                            <span>{platters?.cookies?.description}</span>*/}
					{/*                        </li>*/}
					{/*                    </ul>*/}
					{/*                    /!*<div className="price">*!/*/}
					{/*                    /!*    <h5>Price</h5>*!/*/}
					{/*                    /!*    <h5>${platters?.cookies?.price}</h5>*!/*/}
					{/*                    /!*</div>*!/*/}
					{/*                </div>*/}
					{/*                /!*<a href="chocolate-product-detail.php">*!/*/}
					{/*                    <button type={"button"}*/}
					{/*                            onClick={() => navigate(COOKIES)}*/}
					{/*                            className="btn-price">Buy Now</button>*/}
					{/*                /!*</a>*!/*/}
					{/*            </div>*/}
					{/*        </div>*/}
					{/*    </div>*/}
					{/*}*/}

					{platters?.rods && parseInt(platters?.rods?.is_active) === 1 && (
						<div className="col-lg-3 col-md-6">
							<div
								className="card-wrapper"
								// data-aos="flip-left" data-aos-easing="ease-out-cubic"
								// data-aos-duration="1000"
								style={{ padding: "0px" }}
							>
								<img
									src={filePreviewer(platters?.rods?.image)}
									className="img-fluid"
									alt=""
								/>
								<div className="card-border-heading">
									<div className="list-price">
										<h6 style={{ fontSize: "17px" }}>
											{platters?.rods?.title}
										</h6>
										<ul>
											<li>
												<i className="fa fa-solid fa-check"></i>
												<span>{platters?.rods?.description}</span>
											</li>
										</ul>
										{/*<div className="price">*/}
										{/*    <h5>Price</h5>*/}
										{/*    <h5 className="pratzel">${platters?.rods?.price}</h5>*/}
										{/*    /!*<h5 className="pratzel">$1.50/pretzel or 6 for $7.00</h5>*!/*/}
										{/*</div>*/}
									</div>
									{/*<a href="pretzelrodsdetails.php">*/}
									<button
										type={"button"}
										onClick={() => navigate(RODS)}
										className="btn-price"
									>
										Buy Now
									</button>
									{/*</a>*/}
								</div>
							</div>
						</div>
					)}

					{platters?.oreos && parseInt(platters?.oreos?.is_active) === 1 && (
						<div className="col-lg-3 col-md-6">
							<div
								className="card-wrapper"
								// data-aos="flip-left" data-aos-easing="ease-out-cubic"
								// data-aos-duration="1000"
								style={{ padding: "0px" }}
							>
								<img
									src={filePreviewer(platters?.oreos?.image)}
									className="img-fluid"
									alt=""
								/>
								<div className="card-border-heading">
									<div className="list-price">
										<h6 style={{ fontSize: "17px" }}>
											{platters?.oreos?.title}
										</h6>
										<ul>
											<li>
												<i className="fa fa-solid fa-check"></i>
												<span>{platters?.oreos?.description}</span>
											</li>
										</ul>
										{/*/!*<div className="price">*!/*/}
										{/*//     <h5>Price</h5>*/}
										{/*//     <h5>${platters?.oreos?.price} per dozen</h5>*/}
										{/*//     /!*<h5>$12.00 per dozen</h5>*!/*/}
										{/*/!*</div>*!/*/}
									</div>
									{/*<a href="choclateoreos.php">*/}
									<button
										type={"button"}
										onClick={() => navigate(OREOS)}
										className="btn-price"
									>
										Buy Now
									</button>
									{/*</a>*/}
								</div>
							</div>
						</div>
					)}

					{platters?.candy && parseInt(platters?.candy?.is_active) === 1 && (
						<div className="col-lg-3 col-md-6">
							<div
								className="card-wrapper"
								// data-aos="flip-left" data-aos-easing="ease-out-cubic"
								// data-aos-duration="1000"
								style={{ padding: "0px" }}
							>
								<img
									src={filePreviewer(platters?.candy?.image)}
									className="img-fluid"
									alt=""
								/>
								<div className="card-border-heading">
									<div className="list-price">
										<h6 style={{ fontSize: "17px" }}>
											{platters?.candy?.title}
										</h6>
										<ul>
											<li>
												<i className="fa fa-solid fa-check"></i>
												<span>{platters?.candy?.description}</span>
											</li>
										</ul>
										{/*<div className="price">*/}
										{/*    <h5>Price</h5>*/}
										{/*    /!*<h5>$5.00 or $10.00</h5>*!/*/}
										{/*    <h5>${platters?.candy?.price}</h5>*/}
										{/*</div>*/}
									</div>
									{/*<a href="hardcandy.php">*/}
									<button
										type={"button"}
										onClick={() => navigate(CANDY)}
										className="btn-price"
									>
										Buy Now
									</button>
									{/*</a>*/}
								</div>
							</div>
						</div>
					)}
					{platters?.bags && parseInt(platters?.bags?.is_active) === 1 && (
						<div className="col-lg-3 col-md-6">
							<div
								className="card-wrapper"
								// data-aos="flip-left" data-aos-easing="ease-out-cubic"
								// data-aos-duration="1000"
								style={{ padding: "0px" }}
							>
								<img
									src={filePreviewer(platters?.bags?.image)}
									className="img-fluid"
									alt=""
								/>
								<div className="card-border-heading">
									<div className="list-price">
										<h6 style={{ fontSize: "17px" }}>
											{platters?.bags?.title}
										</h6>
										<ul>
											<li>
												<i className="fa fa-solid fa-check"></i>
												<span>{platters?.bags?.description}</span>
											</li>
										</ul>
										{/*<div className="price">*/}
										{/*    <h5>Price</h5>*/}
										{/*    <h5>(${platters?.bags?.price} per bag)</h5>*/}
										{/*</div>*/}
									</div>
									{/*<a href="bagsofpretzels.php">*/}
									<button
										type={"button"}
										onClick={() => navigate(BAGS)}
										className="btn-price"
									>
										Buy Now
									</button>
									{/*</a>*/}
								</div>
							</div>
						</div>
					)}

					{platters?.apples && parseInt(platters?.apples?.is_active) === 1 && (
						<div className="col-lg-3 col-md-6">
							<div
								className="card-wrapper"
								// data-aos="flip-left" data-aos-easing="ease-out-cubic"
								// data-aos-duration="1000"
								style={{ padding: "0px" }}
							>
								<img
									src={filePreviewer(platters?.apples?.image)}
									className="img-fluid"
									alt=""
								/>
								<div className="card-border-heading">
									<div className="list-price">
										<h6 style={{ fontSize: "17px" }}>
											{platters?.apples?.title}
										</h6>
										<ul>
											<li>
												<i className="fa fa-solid fa-check"></i>
												<span>{platters?.apples?.description}</span>
											</li>
										</ul>
										{/*<div className="price">*/}
										{/*    <h5>Price</h5>*/}
										{/*    <h5>${platters?.apples?.price} each</h5>*/}
										{/*</div>*/}
									</div>
									{/*<a href="apples.php">*/}
									<button
										type={"button"}
										onClick={() => navigate(APPLES)}
										className="btn-price"
									>
										Buy Now
									</button>
									{/*</a>*/}
								</div>
							</div>
						</div>
					)}

					{platters?.chocolatePlatters && parseInt(platters?.chocolatePlatters?.is_active) === 1 && (
						<div className="col-lg-3 col-md-6">
							<div
								className="card-wrapper"
								// data-aos="flip-left" data-aos-easing="ease-out-cubic"
								// data-aos-duration="1000"
								style={{ padding: "0px" }}
							>
								<img
									src={filePreviewer(platters?.chocolatePlatters?.image)}
									className="img-fluid"
									alt=""
								/>
								<div className="card-border-heading">
									<div className="list-price">
										<h6 style={{ fontSize: "17px" }}>
											{platters?.chocolatePlatters?.title}
										</h6>
										<ul>
											<li>
												<i className="fa fa-solid fa-check"></i>
												<span>{platters?.chocolatePlatters?.description}</span>
											</li>
										</ul>
										{/*<div className="price">*/}
										{/*    <h5>Price</h5>*/}
										{/*    <h5>${platters?.apples?.price} each</h5>*/}
										{/*</div>*/}
									</div>
									{/*<a href="apples.php">*/}
									<button
										type={"button"}
										onClick={() => navigate(CHOCOLATE_PLATTER_ITEM)}
										className="btn-price"
									>
										Buy Now
									</button>
									{/*</a>*/}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default BottomSection;
