import React, {useEffect, useState} from "react";
import {cookies, rib, ribion, shopgroup3} from "../../constant/Index";
import {filePreviewer} from "../../constant/helpers";
import DonationForm from "./DonationForm";
import {useDispatch, useSelector} from "react-redux";
import {checkPaymentFreeRequest, sendDonationRequest, updateDonationRequest} from "../../redux/Donation/Action";
import PaymentMethodWrapper from "../../components/PaymentMethod/Wrapper";
import {setError} from "../../redux/Errors/Reducer";
import {useNavigate} from "react-router-dom";
import {THANKYOU} from "../../routes/RouteLinks";

const DonateSection = (props) => {

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const donationObject = {donation_id: 0, order_id: 0};
    const { isLogin , user } = useSelector((state) => state?.auth);
    const [loading, setLoading] = useState(false);
    const [feeLoading, setFeeLoading] = useState(false);
    const [fee, setFee] = useState({fee: 0, total: 0});
    const [stripeToken, setStripeToken] = useState({generate: false, token: ''});
    const [donationResponse, setDonationResponse] = useState(donationObject);
    const initial = {
        total_amount: '',
        user_id: 0,
        first_name: '',
        last_name: '',
        email: '',
        contact_no: '',
        payment_method: 'stripe',
        donation_reason_id: 1,
        stripe_token: '',
        order_id: '122',
        fee: false,
    }
    const [form, setForm] = useState({...initial});

    const checkCardAdded = () => {
        if (form?.payment_method === "stripe" && stripeToken?.generate === false && stripeToken?.token === '') {
            dispatch(setError([{message: 'Card required!', color: 'warning'}]));
            return false;
        }
        if (form?.total_amount === "" || parseInt(form?.total_amount) <= 0) {
            dispatch(setError([{
                message: (form?.total_amount === "")? 'Donation amount is required!' : 'Donation amount must be greater than 0!',
                color: 'warning'
            }]));
            return false;
        }
        return true;
    }

    const applyFeeAmount = async (amount = null) => {
        setFeeLoading(true);
        // console.log("Amount",amount);
        let x = await dispatch(checkPaymentFreeRequest({
            // ...form, total_amount : (amount)? amount : form?.total_amount
            total_amount : amount,
            currency : "usd"
        }));
        setFee({fee: x?.payload?.fee, total: x?.payload?.total});
        setFeeLoading(false);
    }

    const paypalCreateOrderHandler = async (information) => {
        let x = await dispatch(sendDonationRequest({...form, ...information}));
        if (x.payload) {
            setForm({...initial});
        }
    }

    const onSubmitHandler = async (e = null) => {
        if (e) e.preventDefault();
        if ( checkCardAdded() ) {
            setLoading(true);
            let x = await dispatch(sendDonationRequest({
                ...form,
                stripe_token: stripeToken?.token,
                total_amount : (form?.fee)? fee?.total : form?.total_amount,
                user_id : (isLogin)? user?.id : 0
            }));
            if ( x.payload ) {
                setStripeToken({generate: false, token: ''});
                if(form?.payment_method === "paypal") {
                    window.location.href = x?.payload?.paypal_link;
                }
                window.scrollTo(0, 0);
                setForm({...initial});
                navigation(THANKYOU+'?success=true&message=Your donation has been submitted.');
            }
            setLoading(false);
        }
    }

    useEffect(()=>{
        if(isLogin){
            setForm((prevState) => ({
                ...prevState,
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                email: user?.email || '',
                contact_no: user?.contact_no || '',
            }))
        }
        return () => console.log("");
    },[])

    return (
        <section className="about-us-main-page donate-page">
            <div className="side-image-1-row">
                <img src={cookies} className="img-fluid cookies" alt=""/>
                <img src={rib} className="img-fluid rib" alt=""/>
                <img src={ribion} className="img-fluid link" alt=""/>
                <img src={shopgroup3} className="img-fluid links" alt=""/>
            </div>
            <div className="container">
                <PaymentMethodWrapper
                    fee={fee}
                    type={form?.payment_method}
                    stripeToken={stripeToken}
                    setStripeToken={setStripeToken}
                >
                    <form id={'donationSubmitHandler'} onSubmit={onSubmitHandler}>
                        <div className="row">
                            <div className="col-lg-6">
                                <DonationForm
                                    fee={fee}
                                    feeLoading={feeLoading}
                                    stripeToken={stripeToken}
                                    setStripeToken={setStripeToken}
                                    paypalCreateOrderHandler={(information) => paypalCreateOrderHandler(information)}
                                    form={form}
                                    setForm={setForm}
                                    applyFeeAmount={(amount = null) => applyFeeAmount(amount)}
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="second-img-wrapper">
                                    <figure>
                                        <img src={filePreviewer(props?.discountContent?.banner_image)}
                                             className="img-fluid"
                                             alt=""/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">

                            </div>
                            <div className="col-lg-6"></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 ">
                                <div className="bottom-btn-wrappert">
                                    {
                                        // form?.payment_method === "stripe" &&
                                        <button type={"submit"} disabled={loading}
                                                className="btn-donate  last-samurai">
                                            {loading ? <i className={"fa fa-spin fa-spinner"}></i> : 'Donate Now'}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </PaymentMethodWrapper>
            </div>
        </section>
    )
}

export default DonateSection;