import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/style.css";
import "../../assets/css/profile.css";
import { logout } from "../../redux/Auth/Reducer";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../routes/RouteLinks";
import { setError } from "../../redux/Errors/Reducer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { filePreviewer, profilePic } from "../../constant/helpers";
import Profile from "../../components/Dashboard/Profile";
import Password from "../../components/Dashboard/Password";
import Orders from "../../components/Dashboard/Orders";
import Donations from "../../components/Dashboard/Donations";
import {
	changeProfilePicRequest,
	deleteProfilePicRequest,
	geteProfilePicRequest,
} from "../../redux/Auth/Action";
// =======
// import {
// 	changeProfilePicRequest,
// 	geteProfilePicRequest,
// } from "../../redux/Auth/Action";
// >>>>>>> ce3a8c7a49083d8f36488e9cf33f5d75846e6a1c
import Cards from "../../components/Dashboard/Cards";

const Dashboard = () => {
	// 	const dispatch = useDispatch();
	// 	const navigate = useNavigate();
	// 	const user = useSelector((state) => state?.auth?.user);
	// 	const [profile, setProfile] = useState("");
	// 	const imageTypes = [
	// 		"image/png",
	// 		"image/jpg",
	// 		"image/jpeg",
	// 		"image/svg",
	// 		"image/webp",
	// 	];
	// 	const onChangeProfile = async (e) => {
	// 		if (
	// 			e.target.files.length > 0 &&
	// 			imageTypes.includes(e.target.files[0]?.type)
	// 		) {
	// 			setProfile(URL.createObjectURL(e.target.files[0]));
	// 			let formData = new FormData();
	// 			formData.append("avatar", e.target.files[0]);
	// 			await dispatch(changeProfilePicRequest(formData));
	// 		} else {
	// 			dispatch(
	// 				setError([
	// 					{ message: "Image format is incorrect!!", color: "warning" },
	// 				]),
	// 			);
	// 		}
	// 	};
	//
	// <<<<<<< HEAD
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state?.auth?.user);
	const [profile, setProfile] = useState("");
	const imageTypes = [
		"image/png",
		"image/jpg",
		"image/jpeg",
		"image/svg",
		"image/webp",
	];
	const onChangeProfile = async (e) => {
		if (
			e.target.files.length > 0 &&
			imageTypes.includes(e.target.files[0]?.type)
		) {
			setProfile(URL.createObjectURL(e.target.files[0]));
			let formData = new FormData();
			formData.append("avatar", e.target.files[0]);
			await dispatch(changeProfilePicRequest(formData));
		} else {
			dispatch(
				setError([
					{ message: "Image format is incorrect!!", color: "warning" },
				]),
			);
		}
	};
	const removeProfilePic = async () => {
		await dispatch(deleteProfilePicRequest());
	};
	// =======
	const getProfile = async () => {
		await dispatch(geteProfilePicRequest());
	};
	// >>>>>>> ce3a8c7a49083d8f36488e9cf33f5d75846e6a1c

	useEffect(() => {
		setProfile(
			user?.image
				? filePreviewer(user?.image)
				: profilePic(user?.first_name + " " + user?.last_name),
		);
	}, [user]);

	// <<<<<<< HEAD
	//     useEffect(() => {
	//         setProfile((user?.image) ? filePreviewer(user?.image) : profilePic(user?.first_name + " " + user?.last_name));
	//     }, [user])

	useEffect(() => {
		getProfile();
		window.scrollTo(0, 0);
		return () => {};
	}, []);

	return (
		<>
			{/*<InjectAxiosInterceptors />*/}
			<InnerBanner PageName="Dashboard" />
			<section className="profile-sec">
				<div className="container">
					<div className="tabs-profile">
						<div className="row">
							<div className="col-lg-4">
								<div className="tabs-profile-headings">
									<input
										type="file"
										name="image"
										style={{ visibility: "hidden" }}
										accept={"image/*"}
										id="profilePic"
										onChange={onChangeProfile}
									/>
									<div className="profile-img position-relative">
										{/*<label  className={""}>*/}
										<i
											onClick={() => {
												let q = document.querySelector("#profilePic");
												q.click();
											}}
											className={
												"fa fa-pencil customEditPencil text-white p-2 position-absolute left-0"
											}
										></i>
										{/*</label>*/}
										<figure>
											<img src={profile} className="img-fluid" alt="" />
											{user?.image && (
												<span
													className={"position-absolute"}
													style={{
														bottom: "17px",
														width: "100%",
														left: "33px",
													}}
													onClick={removeProfilePic}
												>
													<i className={"fa fa-trash text-danger"}></i>
												</span>
											)}
										</figure>
										<h4>{user?.first_name + " " + user?.last_name}</h4>
									</div>
									<ul className="nav nav-tabs" id="myTab" role="tablist">
										<li className="nav-item">
											<a
												className="nav-link  show active"
												id="home-tab"
												data-toggle="tab"
												href="#home"
												role="tab"
												aria-controls="home"
												aria-selected="true"
											>
												My Profile
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												id="profile-tab"
												data-toggle="tab"
												href="#profile"
												role="tab"
												aria-controls="profile"
												aria-selected="false"
											>
												Change Password{" "}
											</a>
										</li>
										{/*<li className="nav-item">*/}
										{/*    <a className="nav-link" id="messages-tab" data-toggle="tab" href="#messages"*/}
										{/*       role="tab"*/}
										{/*       aria-controls="messages" aria-selected="false">My Addresses</a>*/}
										{/*</li>*/}
										<li className="nav-item">
											<a
												className="nav-link"
												id="Cards-tab"
												data-toggle="tab"
												href="#Cards"
												role="tab"
												aria-controls="Cards"
												aria-selected="false"
											>
												Cards
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												id="settings-tab"
												data-toggle="tab"
												href="#settings"
												role="tab"
												aria-controls="settings"
												aria-selected="false"
											>
												My Orders
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												id="donation-tab"
												data-toggle="tab"
												href="#donation"
												role="tab"
												aria-controls="donation"
												aria-selected="false"
											>
												Donations
											</a>
										</li>
										<li className="nav-item">
											<a
												className="nav-link"
												onClick={() => {
													dispatch(logout());
													dispatch(
														setError([
															{
																message: "Logout Successfully!",
																color: "success",
															},
														]),
													);
													navigate(HOME);
												}}
												// href="javascript:void(0);"
											>
												Logout
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-8 p-lg-0">
								<div className="tabs-content">
									<div className="tab-content">
										<div
											className="tab-pane active"
											id="home"
											role="tabpanel"
											aria-labelledby="home-tab"
										>
											<Profile />
										</div>
										<div
											className="tab-pane"
											id="profile"
											role="tabpanel"
											aria-labelledby="profile-tab"
										>
											<Password />
										</div>
										<div
											className="tab-pane"
											id="messages"
											role="tabpanel"
											aria-labelledby="messages-tab"
										>
											<section className="main-sec">
												<div className="container-fluid p-0 m-0">
													<div className="row">
														<div className="col-lg-12">
															<div className="main-top ">
																<h2>My Addresses</h2>
															</div>
														</div>
													</div>
												</div>
											</section>
											<div className="form-tabs-password-adrress">
												<div className="shadowed_box">
													<div className="address_complete_section">
														<div className="current_addresses">
															<div className="all_adresses">
																<div className="single_address">
																	<input
																		type="radio"
																		id="address1"
																		className="cutom_radio_btn"
																		checked=""
																		name="address_select"
																	/>
																	<label htmlFor="address1">
																		<div className="address_start">
																			<div className="detail_information_information">
																				<div className="row">
																					<div className="col-lg-2 col-sm-2 col-2">
																						<div className="custom_check_box">
																							<div className="for_the_label">
																								<span></span>
																								<span className="custom_radio"></span>
																							</div>
																						</div>
																					</div>
																					<div className="col-lg-10 col-sm-10 col-10">
																						<div className="main-row">
																							<div className="the_row">
																								<span className="question">
																									Country:
																								</span>
																								<span className="answer">
																									United States
																								</span>
																							</div>
																							<div className="the_row left-row">
																								<span className="question">
																									State:
																									<span className="answer answer1">
																										Texas
																									</span>
																								</span>
																							</div>
																						</div>
																						<div className="the_row">
																							<span className="question">
																								Address:
																							</span>
																							<span className="answer">
																								10515 Fox Ave Fairdale,
																								Kentucky(KY), 40118
																							</span>
																						</div>
																						<div className="the_row">
																							<span className="question">
																								Phone:
																							</span>
																							<span className="answer">
																								+44-123-456-789
																							</span>
																						</div>
																						<div className="btn-raio-main">
																							<button className="btn-radio">
																								<a href="">Edit</a>
																							</button>
																						</div>
																						<div className="radiocheck"></div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</label>
																	<div className="single_address">
																		<input
																			type="radio"
																			id="address2"
																			className="cutom_radio_btn"
																			name="address_select"
																		/>
																		<label htmlFor="address2">
																			<div className="address_start">
																				<div className="detail_information_information">
																					<div className="row">
																						<div className="col-lg-2 col-sm-2 col-2">
																							<div className="custom_check_box">
																								<div className="for_the_label">
																									<span></span>
																									<span className="custom_radio"></span>
																								</div>
																							</div>
																						</div>
																						<div className="col-lg-10 col-sm-10 col-10">
																							<div className="main-row">
																								<div className="the_row">
																									<span className="question">
																										Country:
																									</span>
																									<span className="answer">
																										United States
																									</span>
																								</div>
																								<div className="the_row left-row">
																									<span className="question">
																										State:
																										<span className="answer answer1">
																											Texas
																										</span>
																									</span>
																								</div>
																							</div>
																							<div className="the_row">
																								<span className="question">
																									Address:
																								</span>
																								<span className="answer">
																									10515 Fox Ave Fairdale,
																									Kentucky(KY), 40118
																								</span>
																							</div>
																							<div className="the_row">
																								<span className="question">
																									Phone:
																								</span>
																								<span className="answer">
																									+44-123-456-789
																								</span>
																							</div>
																							<div className="btn-raio-main">
																								<button className="btn-radio">
																									<a href="">Edit</a>
																								</button>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</label>
																	</div>
																	<div className="add_new_address">
																		<button className="btn13  address-wrapper">
																			<a href="javascript:void(0)"> Add New</a>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="form-tabs adderess-form ">
												<form action="">
													<div
														className="row align-items-start"
														style={{ height: "350px" }}
													>
														<div className="col-lg-12">
															<div className="row">
																<div className="col-lg-12">
																	<div className="form-group1">
																		<label htmlFor="formGroupExampleInput">
																			Address
																		</label>
																		<input
																			type="name"
																			className="form-control"
																			id="formGroupExampleInput"
																			placeholder="10515 Fox Ave Fairdale, Kentucky(KY), 40118"
																		/>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-lg-6">
																	<div className="form-group1">
																		<label htmlFor="formGroupExampleInput">
																			City
																		</label>
																		<input
																			type="text"
																			className="form-control"
																			id="formGroupExampleInput"
																			placeholder="Enter City"
																		/>
																	</div>
																</div>
																<div className="col-lg-6">
																	<div className="form-group1">
																		<label htmlFor="formGroupExampleInput">
																			Country
																		</label>
																		<input
																			type="text"
																			className="form-control"
																			id="formGroupExampleInput"
																			placeholder="Enter Country"
																		/>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-lg-6">
																	<div className="form-group1">
																		<label htmlFor="formGroupExampleInput">
																			State
																		</label>
																		<input
																			type="text"
																			className="form-control"
																			id="formGroupExampleInput"
																			placeholder="Enter State"
																		/>
																	</div>
																</div>
																<div className="col-lg-6">
																	<div className="form-group1">
																		<label htmlFor="formGroupExampleInput">
																			Zip Code
																		</label>
																		<input
																			type="number"
																			className="form-control"
																			id="formGroupExampleInput"
																			placeholder="Enter Zip code"
																		/>
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-lg-12">
																	<button className="btn13">
																		<a href="">Save</a>
																	</button>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
										<div
											className="tab-pane"
											id="settings"
											role="tabpanel"
											aria-labelledby="settings-tab"
										>
											<Orders />
										</div>
										<div
											className="tab-pane"
											id="Cards"
											role="tabpanel"
											aria-labelledby="Cards-tab"
										>
											<Cards />
										</div>
										<div
											className="tab-pane"
											id="donation"
											role="tabpanel"
											aria-labelledby="donation-tab"
										>
											<Donations />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Dashboard;
