import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPageRequest} from "../../redux/Setting/Action";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import ListSection from "./ListSection";
import '../../assets/css/blogs.css';
import {getBlogsRequest} from "../../redux/Blogs/Action";
import {setLoader} from "../../redux/Loader/Reducer";

const Blogs = () => {

	const dispatch = useDispatch();
	const blogs = useSelector(state => state?.blogs?.blogs);

	const get = async () => {
		dispatch(setLoader(true));
		await dispatch(getBlogsRequest());
		window.scrollTo(0, 0);
		dispatch(setLoader(false));
	}

	useEffect(()=>{
		window.scrollTo(0, 0);
		get();
	},[])

	return (
		<>
			{/* Banner Start Here */}
			<InnerBanner PageName={'Blogs'}/>
			{/* Banner End Here */}
			<ListSection blogs={blogs} />
			
		</>
	);
};

export default Blogs;
