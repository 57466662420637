import { createSlice } from '@reduxjs/toolkit'

export const Faqs = createSlice({
    name: 'faqs',
    initialState : {
        faqs : [],
    },
    reducers: {
        setFaqs(state,action) {
          state.faqs = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setFaqs } = Faqs.actions

export default Faqs.reducer