import React from "react";
import { Link } from "react-router-dom";
import { FooterLogo } from "../../constant/Index";
import Newsletter from "../Newsletter/Newsletter";
import {
	ABOUT,
	BlOGS,
	CONTACTUS,
	DONATE,
	FAQS,
	FILTER,
	HOME,
	INGREDIENT,
	PRIVACY_POLICY,
	SHOP,
	TERM_CONDITION,
} from "../../routes/RouteLinks";
import { useSelector } from "react-redux";
import moment from "moment";

const Footer = () => {
	const setting = useSelector((state) => state?.setting?.setting);

	return (
		<>
			{/* Newsletter Sec */}
			<Newsletter />
			{/* Newsletter Sec */}
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-5">
							<div className="footer-widget-1-wrapper">
								<div className="logo-wrapper">
									<figure>
										<Link to={HOME}>
											<img
												src={FooterLogo}
												style={{
													transform: "translate(0px, 0px)",
												}}
												width="180px"
												height="180px"
												className="img-fluid"
												alt=""
											/>
										</Link>
									</figure>
								</div>
								<div className="content-wrapper">
									<p>{setting?.footer_sentence}</p>
								</div>
								{/* <div className="copyright-wrapper">
									<p>
										© {new Date().getFullYear()} {setting?.copyright}
									</p>
								</div> */}
							</div>
						</div>
						<div className="col-lg-2 col-md-3">
							<div className="footer-widget-2-wrapper">
								<div className="heading-wrapper">
									<h4>Menu</h4>
								</div>
								<div className="menu-wrapper">
									<ul>
										<li>
											<Link to={ABOUT}>About Us</Link>
										</li>
										<li>
											<Link to={FILTER}>Shop</Link>
										</li>
										<li>
											<Link to={DONATE}>Donate</Link>
										</li>
										<li>
											<Link to={BlOGS}>Blogs</Link>
										</li>
										<li>
											<Link to={CONTACTUS}>Contact Us</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-3">
							<div className="footer-widget-2-wrapper">
								<div className="heading-wrapper">
									<h4>Quick Links</h4>
								</div>
								<div className="menu-wrapper">
									<ul>
										<li>
											<Link to={INGREDIENT}>Ingredients</Link>
										</li>
										<li>
											<Link to={FAQS}>FAQs</Link>
										</li>
										<li>
											<Link to={TERM_CONDITION}>Terms and Condition</Link>
										</li>
										<li>
											<Link to={PRIVACY_POLICY}>Privacy Policy</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4">
							<div className="footer-widget-4-wrapper">
								<div className="heading-wrapper">
									<h4>Contact</h4>
								</div>
								<div className="icon-menu-wrapper">
									<ul>
										<li>
											<a
												target="_blank"
												href="https://goo.gl/maps/L6Hgnmn1iozYA76x7"
											>
												<i className="fa fa-map-marker" aria-hidden="true"></i>
												<span>{setting?.address}</span>
											</a>
										</li>
										<li>
											<a href={`tel:${setting?.contact_phone}`}>
												<i className="fa fa fa-phone"></i>
												<span>{setting?.contact_phone}</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-1 col-md-1">
							<div className="footer-widget5-wrapper">
								<div className="social-icon-wrapper">
									<ul>
										<li>
											<a href={setting?.whatsapp}>
												<i className="fa fa-whatsapp" aria-hidden="true"></i>
											</a>
										</li>
										<li>
											<a href={setting?.instagram}>
												<i className="fa fa-instagram" aria-hidden="true"></i>
											</a>
										</li>
										<li>
											<a href={setting?.facebook}>
												<i className="fa fa-facebook" aria-hidden="true"></i>
											</a>
										</li>
										<li>
											<a href={setting?.twitter}>
												{/* <i className="fa fa-twitter" aria-hidden="true"></i> */}
												<svg
													xmlns="http://www.w3.org/2000/svg"
													height="1em"
													viewBox="0 0 512 512"
												>
													<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
												</svg>
											</a>
										</li>
										<li>
											<a href={setting?.youtube}>
												<i
													className="fa fa-youtube-play"
													aria-hidden="true"
												></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="copyright-wrapper">
								<p>
									© {new Date().getFullYear()} {setting?.copyright}
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
