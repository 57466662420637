import React, { useEffect, useState } from "react";
import { CustomPhoneNumber, Input as InputMask } from "../FormComponent";
import {
	onDirectChangeHandler,
	onInputChangeHandler,
} from "../../constant/helpers";
import { useDispatch, useSelector } from "react-redux";
// import 'react-phone-number-input/style.css';
// import Input from 'react-phone-number-input/input';

const OrderPlace = ({ title, content, request, type = "event" }) => {
	const inital = {
		user_id: 0,
		first_name: "",
		last_name: "",
		email: "",
		contact_no: "",
		// event_type: '',
		details: "",
		instructions: "",
	};
	const dispatch = useDispatch();
	const { isLogin, user } = useSelector((state) => state?.auth);
	const [form, setForm] = useState({ ...inital });
	const [loading, setLoading] = useState(false);

	const onSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		let x = await request(form);
		setLoading(false);
		if (x.payload) {
			setForm({ ...inital });
		}
	};

	useEffect(() => {
		if (type === "event") {
			setForm((prevState) => ({ ...prevState, event_type: "" }));
		}

		if (isLogin) {
			setForm((prevState) => ({
				...prevState,
				first_name: user?.first_name || "",
				last_name: user?.last_name || "",
				email: user?.email || "",
				contact_no: user?.contact_no || "",
			}));
		}
	}, [type]);

	return (
		<>
			<div className="event-form-wrapper">
				<h2>{title}</h2>
				<p>{content}</p>
			</div>
			<div className="form-wrapper">
				<form onSubmit={onSubmitHandler}>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="first_name">First Name</label>
								{InputMask(
									"text",
									"first_name",
									true,
									form?.first_name,
									(e) => onInputChangeHandler(e, setForm),
									"form-control",
									"first_name",
									"Enter Name Here",
								)}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="last_name">Last Name</label>

								{InputMask(
									"text",
									"last_name",
									true,
									form?.last_name,
									(e) => onInputChangeHandler(e, setForm),
									"form-control",
									"last_name",
									"Enter Last Name Here",
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="contact_no">Phone</label>

								{/* <div className={"form-group"}> */}
								<CustomPhoneNumber
									value={form?.contact_no}
									onChange={(e) => {
										onDirectChangeHandler(
											"contact_no",
											e.target.value,
											setForm,
										);
									}}
								/>
								{/*<Input*/}
								{/*    className={"form-control"}*/}
								{/*    required={true}*/}
								{/*    style={{width:'100%'}}*/}
								{/*    placeholder="Your Phone Here"*/}
								{/*    value={form?.contact_no}*/}
								{/*    onChange={(e)=>onDirectChangeHandler('contact_no',e,setForm)}/>*/}
								{/* </div> */}

								{/*{Input(*/}
								{/*    'tel',*/}
								{/*    'contact_no',*/}
								{/*    true,*/}
								{/*    form?.contact_no,*/}
								{/*    (e) => onInputChangeHandler(e, setForm),*/}
								{/*    'form-control',*/}
								{/*    'contact_no',*/}
								{/*    'Enter Phone Here'*/}
								{/*)}*/}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="email">Email</label>
								{InputMask(
									"email",
									"email",
									true,
									form?.email,
									(e) => onInputChangeHandler(e, setForm),
									"form-control",
									"email",
									"Enter Your Email Here",
								)}
							</div>
						</div>
					</div>
					{type === "event" && (
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group">
									<label htmlFor="event_type">Event Type</label>
									{InputMask(
										"text",
										"event_type",
										true,
										form?.event_type,
										(e) => onInputChangeHandler(e, setForm),
										"form-control",
										"event_type",
										"Enter event type Here",
									)}
								</div>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-lg-12">
							<div className="form-group">
								<label htmlFor="details">Order Details</label>
								<textarea
									className="form-control"
									name={"details"}
									required={true}
									placeholder="Enter Order Details"
									id="details"
									rows="3"
									value={form?.details}
									onChange={(e) => onInputChangeHandler(e, setForm)}
								></textarea>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="form-group">
								<label htmlFor="instructions">
									Special Instructions
									<span>(If any)</span>
								</label>
								{InputMask(
									"text",
									"instructions",
									false,
									form?.instructions,
									(e) => onInputChangeHandler(e, setForm),
									"form-control",
									"instructions",
									"Special Instructions",
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<button type="submit" className="btn-event btn1">
								{loading ? (
									<i className={"fa fa-spin fa-spinner"}></i>
								) : (
									"Submit"
								)}
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default OrderPlace;
