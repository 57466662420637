import React from "react";
import { filePreviewer } from "../../constant/helpers";
import { Link } from "react-router-dom";
import { CARE_PACKAGE_DETAIL } from "../../routes/RouteLinks";

const Package = ({ item }) => {
	return (
		<div className="col-lg-4 col-md-6">
			<div
				className="card-wrapper"
				style={{ padding: "0px" }}
				// data-aos="flip-left" data-aos-easing="ease-out-cubic"
				// data-aos-duration="1000"
			>
				<img
					src={filePreviewer(item?.image)}
					className="img-fluid"
					style={{ height: "218px" }}
					alt=""
				/>
				<div className="card-border-heading">
					<div className="list-price">
						<h6 style={{ fontSize: "25px" }}>{item?.name}</h6>
						<ul>
							{item?.includes?.map((val, key) => (
								<li key={key} style={{ justifyContent: "flex-start" }}>
									<i className="fa fa-solid fa-check"></i>
									<span style={{ fontSize: "15px" }}>{val}</span>
								</li>
							))}
						</ul>
					</div>
					<Link to={CARE_PACKAGE_DETAIL(item?.slug)}>
						<button className="btn-price">View Detail</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Package;
