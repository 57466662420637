import React, { useState } from "react";
import { onInputChangeHandler } from "../../constant/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
	changePasswordRequest,
	updateProfileRequest,
} from "../../redux/Auth/Action";

const Password = () => {
	const dispatch = useDispatch();
	const [current, setCurrent] = useState("password");
	const [newPass, setNewPass] = useState("password");
	const [confirm, setConfirm] = useState("password");
	const [show, setshow] = useState(true);

	// const profile = useSelector(state => state?.auth?.user);
	const [loading, setLoading] = useState(false);
	const initial = {
		current_password: "",
		password: "",
		confirm_password: "",
	};
	const [form, setForm] = useState({ ...initial });

	const onSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		let x = await dispatch(changePasswordRequest(form));
		setLoading(false);
		if (x.payload) setForm({ ...initial });
	};

	const handleShow = () => {
		setshow(false);
	};

	return (
		<>
			<div className="form-tabs-password">
				<form onSubmit={onSubmitHandler}>
					<div className="row align-items-start" style={{ height: "350px" }}>
						<div className="col-lg-12">
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group password">
										<label htmlFor="formGroupExampleInput">
											Current Password
										</label>
										{!show ? (
											<i
												onClick={() => {
													setCurrent((preVal) =>
														preVal === "password" ? "text" : "password",
													);
												}}
												className={
													current === "password"
														? "fa fa-eye-slash"
														: "fa fa-eye"
												}
												style={{ right: "0px" }}
											></i>
										) : (
											""
										)}

										<input
											type={current}
											onChange={(e) => onInputChangeHandler(e, setForm)}
											name={"current_password"}
											value={form?.current_password}
											required={true}
											className="form-control"
											id="password"
											placeholder="*********"
											disabled={show == false ? false : true}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group password">
										<label htmlFor="formGroupExampleInput">New Password</label>

										{!show ? (
											<i
												onClick={() => {
													setNewPass((preVal) =>
														preVal === "password" ? "text" : "password",
													);
												}}
												className={
													newPass === "password"
														? "fa fa-eye-slash"
														: "fa fa-eye"
												}
												style={{ right: "0px" }}
											></i>
										) : (
											""
										)}
										<input
											type={newPass}
											onChange={(e) => onInputChangeHandler(e, setForm)}
											name={"password"}
											value={form?.password}
											required={true}
											className="form-control"
											id="formGroupExampleInput"
											placeholder="********"
											disabled={show == false ? false : true}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group password">
										<label htmlFor="formGroupExampleInput">
											Confirm Password
										</label>

										{!show ? (
											<i
												onClick={() => {
													setConfirm((preVal) =>
														preVal === "password" ? "text" : "password",
													);
												}}
												className={
													confirm === "password"
														? "fa fa-eye-slash"
														: "fa fa-eye"
												}
												style={{ right: "0px" }}
											></i>
										) : (
											""
										)}

										<input
											type={confirm}
											className="form-control"
											name={"confirm_password"}
											value={form?.confirm_password}
											required={true}
											onChange={(e) => onInputChangeHandler(e, setForm)}
											id="formGroupExampleInput"
											placeholder="*********"
											disabled={show == false ? false : true}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									{show ? (
										<button className="btn13 btn-jquiry" onClick={handleShow}>
											Edit
										</button>
									) : (
										<button
											disabled={loading}
											type={"submit"}
											className="btn13 btn-jquiry"
										>
											{loading ? (
												<i className={"fa fa-spin fa-spinner"}></i>
											) : (
												"Save Changes"
											)}
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default Password;
