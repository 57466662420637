import React from "react";
import { useSelector } from "react-redux";

const ContactSection = () => {
	const setting = useSelector((state) => state?.setting?.setting);

	return (
		<section className="email-wrapper-main">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6">
						<a
							className="email-anchor-tag"
							href={`mailto:${setting?.contact_email}`}
						>
							<div className="contact-three-wrapper">
								<div className="email-img">
									<i className="fa fa-solid fa-envelope"></i>
								</div>
								<div className="email-heading">
									<h4>Email us</h4>
									<h5>
										<a href={`mailto:${setting?.contact_email}`}>
											{setting?.contact_email}
										</a>
									</h5>
								</div>
							</div>
						</a>
					</div>
					<div className="col-lg-4 col-md-6">
						<a
							className="email-anchor-tag"
							href={`tel:${setting?.contact_phone}`}
						>
							<div className="contact-three-wrapper">
								<div className="email-img">
									<i className="fa fa-solid fa-phone"></i>
								</div>
								<div className="email-heading">
									<h4>Call us</h4>
									<h5>
										{" "}
										<a href={`tel:${setting?.contact_phone}`}>
											{" "}
											{setting?.contact_phone}
										</a>
									</h5>
								</div>
							</div>
						</a>
					</div>
					<div className="col-lg-4 col-md-12">
						<a
							className="email-anchor-tag"
							target="_blank"
							href="https://goo.gl/maps/L6Hgnmn1iozYA76x7"
						>
							<div className="contact-three-wrapper">
								<div className="email-img">
									<i className="fa fa-sharp fa-solid fa-map-marker"></i>
								</div>
								<div className="email-heading">
									<h4>Office</h4>
									<h5>
										{" "}
										<a href="https://goo.gl/maps/L6Hgnmn1iozYA76x7">
											{setting?.address}
										</a>
									</h5>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactSection;
