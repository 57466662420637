export const headers = (token = null,file = false) => {
    let x = {
        headers : {
            'Accept' : 'application/json',
            'Access-Control-Allow-Origin': '*',
            'credentials': 'same-origin'
        }
    }
    if(token){
        x = {
            ...x,
            headers: {
                ...x.headers,
                'Accept' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization' : `Bearer ${token}`,
            }
        }
    }
    if(file){
        x = {
            ...x,
            headers: {
                ...x.headers,
                'Accept' : 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type' : 'multipart/form-data',
            }
        }
    }
    return x;
}