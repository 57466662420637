import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import {filePreviewer} from '../../constant/helpers'
import {CookiesImg, RibbonImg2, RibImg, ShopGroup3} from '../../constant/Index'
import {getEventRequest} from '../../redux/Setting/Action'
import "../../assets/css/event.css"
import OrderPlace from '../../components/Event/OrderPlace'
import BottomSection from "../../components/Event/BottomSection";
import {setLoader} from "../../redux/Loader/Reducer";

const SpecialEvents = () => {
    const dispatch = useDispatch();

    const event = useSelector(state => state?.setting?.event);

    const get = async () => {
        dispatch(setLoader(true));
        dispatch(getEventRequest());
        window.scrollTo(0, 0);
        dispatch(setLoader(false));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        get();
    }, [])
    return (
        <>
            {/* Inner Banner Start Here */}
            <InnerBanner PageName='Special Events'/>
            {/* Inner Banner End Here */}

            {/* Event Sec Start Here */}
            <BottomSection event={event} />
            {/* Event Sec End Here */}
        </>
    )
}

export default SpecialEvents