import React from "react";
import {CookiesImg, RibbonImg2, RibImg, ShopGroup3} from "../../constant/Index";
import {filePreviewer} from "../../constant/helpers";
import OrderPlace from "./OrderPlace";
import {eventRequest} from "../../redux/Events/Action";
import {useDispatch} from "react-redux";

const BottomSection = ({event}) => {

    const dispatch = useDispatch();


    return (
        <section className="contact-us-main-page">
            <div className="side-image-1-row">
                <img src={CookiesImg} className="img-fluid cookies" alt=""/>
                <img src={RibImg} className="img-fluid rib" alt=""/>
                <img src={RibbonImg2} className="img-fluid link" alt=""/>
                <img src={ShopGroup3} className="img-fluid links" alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="event-wrapper">
                            <img src={filePreviewer(event?.banner_image)} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="main-event">

                            <span dangerouslySetInnerHTML={{ __html: event?.content_heading }}></span>

                            <span dangerouslySetInnerHTML={{ __html: event?.content_sub_heading }}></span>

                            <span dangerouslySetInnerHTML={{ __html: event?.content_para }}></span>

                            <ul>
                                {event?.content_includes?.map((data, index) => (
                                    <li key={index}>{data}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <OrderPlace
                            content={ `Complete information below with your request or call or email to discuss your event and ideas
                                    with Sue. Lead time needed and pricing will be confirmed during your discussion with Sue.`}
                            title={ 'Place Your Order Now' }
                            type={ 'event' }
                            request={ (form) => dispatch(eventRequest(form)) }
                        />
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BottomSection;