import React, {useEffect} from "react";
import "../../assets/css/term-condition.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import BottomSection from "./BottomSection";

const ContactUs = () => {

	useEffect(()=>{
		window.scrollTo(0, 0);
		return ()=> {}
	},[])

	return (
		<>
			{/* InnerBanner Start */}
			<InnerBanner PageName={'CONTACT US'}/>
			{/* InnerBanner End */}

			<BottomSection />
		</>
	);
};

export default ContactUs;
