import React, {useState} from "react";
import PromoCode from "./PromoCode";
import {useDispatch, useSelector} from "react-redux";
import {sendPromoCodeRequest} from "../../redux/CheckOut/Action";
import {amountTypeConvert, filePreviewer, onDirectChangeHandler, total} from "../../constant/helpers";
import moment from "moment";

const SummerySection = (props) => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state?.cart?.items);
    const { codeData, setCodeData } = props;
    const [loading, setLoading] = useState(false);


    const applyCode = async (code) => {
        let total = cart?.reduce((prev, curr) => {
            return prev + parseInt(curr?.qty) * parseInt(curr?.price);
        }, 0);
        setLoading(true);
        let x = await dispatch(
            sendPromoCodeRequest({
                promo_code: code,
                item_total: total,
            })
        );
        if(props?.form?.fee) await props?.applyFeeAmount();
        if (x.payload) {
            setCodeData(x.payload);
            onDirectChangeHandler("promo_code", code, props?.setForm);
        }
        setLoading(false);
    };

    const clearCoupon = () => {
        setCodeData(null);
        onDirectChangeHandler("promo_code", "", props?.setForm);
        props?.applyFeeAmount();
    };

    // console.log(
    //     "Total",
    //     total(cart),
    //     parseFloat(props?.fee?.fee || 0),
    //     parseFloat(props?.form?.donation_amount || 0),
    //     (codeData ? amountTypeConvert(cart,codeData) : 0)
    // );

    return (
        <div className="post-reviews-order">
            <h3>Order Summary</h3>
            <div className="row d-flex flex-row justify-content-center">
                {!codeData ? (
                    <PromoCode loading={loading} applyCode={applyCode}/>
                ) : (
                    <div
                        className={
                            "py-3 mb-2 col-lg-11 d-flex justify-content-between align-items-center"
                        }
                        style={{
                            backgroundColor: "#f6e6c7",
                            color: "#ca8d12",
                            borderRadius: "7px",
                        }}
                    >
                        <span>{codeData?.code}</span>
                        <i
                            onClick={clearCoupon}
                            className={"fa fa-close text-danger ml-2"}
                        ></i>
                    </div>
                )}
            </div>
            <div className="row mb-5 ">
                <div className="col-lg-12">
                    <div className="order-product-detail">
                        <div className="heading-warpper">
                            <h4>Products Details</h4>
                        </div>
                        <div className="order-wrapper">
                            {cart?.map((val, key) => (
                                <div key={key} className="order-img-wrapper">
                                    <div className="main-order-heading-wrapper">
                                        <img
                                            src={filePreviewer(val?.image)}
                                            width={"15%"}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <h4>
                                            {(() => {
                                                if (val?.product_type === "pretzel-rods") {
                                                    return val?.parentTitle + " (" + val?.title + ")";
                                                } else if (
                                                    val?.product_type === "chocolate-covered-oreos"
                                                ) {
                                                    return val?.parentTitle + " (" + val?.title + ")";
                                                } else if (val?.product_type === "hard-tack-candy") {
                                                    return val?.parentTitle + " (" + val?.title + ")";
                                                } else if (val?.product_type === "apples") {
                                                    return val?.parentTitle + " (" + val?.title + ")";
                                                } else if (val?.product_type === "bags-of-pretzels") {
                                                    let variants = "";
                                                    val?.variants?.map((val) => {
                                                        variants +=
                                                            val?.qty > 0
                                                                ? val?.title + " x" + val?.qty + ","
                                                                : "";
                                                    });
                                                    return val?.title + " ( x" + val?.qty + " )";
                                                } else {
                                                    return val?.title;
                                                }
                                            })()}
                                        </h4>
                                    </div>

                                    <h5>
                                        $
                                        {(() => {
                                            if (val?.product_type === "pretzel-rods") {
                                                return parseFloat(val?.price) * parseInt(val?.qty);
                                            } else if (
                                                val?.product_type === "chocolate-covered-oreos"
                                            ) {
                                                return parseFloat(val?.price) * parseInt(val?.qty);
                                            } else if (val?.product_type === "cookies") {
                                                return parseFloat(val?.price);
                                            } else if (val?.product_type === "hard-tack-candy") {
                                                return parseFloat(val?.price) * parseInt(val?.qty);
                                            } else if (val?.product_type === "apples") {
                                                return parseFloat(val?.price) * parseInt(val?.qty);
                                            } else if (val?.product_type === "bags-of-pretzels") {
                                                return parseFloat(val?.price) * parseInt(val?.qty);
                                            } else {
                                                return parseFloat(val?.price) * parseInt(val?.qty);
                                                // return parseFloat(val?.price)?.toFixed(2);
                                            }
                                        })()}
                                        {/*<span></span>*/}
                                    </h5>
                                </div>
                            ))}
                        </div>

                        <div className="order-wrapper-list">
                            <ul>
                                <li>
                                    Item Total : <span>${total(cart)?.toFixed(2)}</span>
                                </li>
                                {codeData && (
                                    <li>
                                        Promo Applied :<span>-${amountTypeConvert(cart,codeData)}</span>
                                    </li>
                                )}
                                <li>
                                    Pick up Date :{" "}
                                    <span>{moment(props?.orderDate).format("DD-MMM-YYYY")}</span>
                                </li>

                                <li className="donat" style={{marginTop: "10px"}}>
                                    Donation : <span>${props?.form?.donation_amount || 0}</span>
                                </li>

                                {
                                    props?.paymentType === "pay_now" && props?.form?.fee && (
                                        <>
                                            {
                                                props?.feeLoading ?
                                                    (
                                                        <li>Fees calculating...</li>
                                                    ) : (
                                                        <li className="donat" style={{marginTop: "10px"}}>
                                                            Fee : <span>${props?.fee?.fee || 0}</span>
                                                        </li>
                                                    )}
                                        </>
                                    )
                                }


                                <li style={{marginTop: "10px"}}>
                                    {" "}
                                    <span style={{fontWeight: "bold", fontSize: "20px"}}>
                                        Order Total :
                                    </span>{" "}
                                    <span style={{fontWeight: "bold", fontSize: "20px"}}>$
                                        {
                                            parseFloat(
                                                (
                                                    total(cart)
                                                    + parseFloat(props?.fee?.fee || 0)
                                                    + parseFloat(props?.form?.donation_amount || 0)
                                                )
                                                - (codeData ? amountTypeConvert(cart,codeData) : 0)
                                            ).toFixed(2)
                                        }
                  </span>
                                </li>

                                {/*{*/}
                                {/*    props?.paymentType === "pay_now" && props?.form?.fee && (*/}
                                {/*        <>*/}
                                {/*            {props?.feeLoading ? (*/}
                                {/*                <li>Fees calculating...</li>*/}
                                {/*            ) : (*/}
                                {/*                <li>*/}
                                {/*                    Fees (if Checked below)<span>${props?.fee?.fee}</span>*/}
                                {/*                </li>*/}
                                {/*            )}*/}
                                {/*        </>*/}
                                {/*    )*/}
                                {/*}*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Summery = React.memo(SummerySection);
export default Summery;
