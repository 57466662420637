import React, { useEffect, useMemo, useState } from "react";
import "../../assets/css/checkout2.css";
import Form from "../../components/Ckeckout/Form";
import Summery from "../../components/Ckeckout/Summery";
import {
	amountTypeConvert,
	onDirectChangeHandler,
	onInputChangeHandler,
	total,
} from "../../constant/helpers";
import { useDispatch, useSelector } from "react-redux";
import { checkOutRequest } from "../../redux/CheckOut/Action";
import PaymentMethodWrapper from "../../components/PaymentMethod/Wrapper";
import { paypal, paypal1 } from "../../constant/Index";
import StripeInjected from "../../components/Stripe/StripeInjected";
import { checkPaymentFreeRequest } from "../../redux/Donation/Action";
import { createSearchParams, useNavigate } from "react-router-dom";
import { removeAll } from "../../redux/Cart/Reducer";
import { THANKYOU } from "../../routes/RouteLinks";
import { setError } from "../../redux/Errors/Reducer";

const BottomSection = (props) => {
	const initial = {
		user_id: 0,
		first_name: "",
		last_name: "",
		email: "",
		contact_no: "",
		address: "",
		note: "",
		payment_method: "stripe",
		payment_type: "",
		donation_amount: 0,
		stripe_token: "",
		order_id: "",
		promo_code: "",
		fee: false,
	};

	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [selectCard, setSelectCard] = useState("");
	const [feeLoading, setFeeLoading] = useState(false);
	const [codeData, setCodeData] = useState(null);
	const [fee, setFee] = useState({ fee: 0, total: 0 });
	const cart = useSelector((state) => state?.cart?.items);
	const user = useSelector((state) => state?.auth?.user);
	const isLogin = useSelector((state) => state?.auth?.isLogin);
	const list = useSelector((state) => state?.cards?.list);
	const [form, setForm] = useState({ ...initial });
	const [loading, setLoading] = useState(false);
	const [stripeToken, setStripeToken] = useState({
		generate: false,
		token: "",
	});

	useMemo(() => {
		onDirectChangeHandler("payment_type", props?.paymentType, setForm);
	}, [props?.paymentType]);

	const onSubmitHandler = async (e = null) => {
		e.preventDefault();
		if (
			form?.payment_type === "pay_now" &&
			form?.payment_method === "stripe" &&
			!stripeToken?.generate
		) {
			dispatch(
				setError([
					{ message: "You need to add cart first!", color: "warning" },
				]),
			);
			return false;
		}
		setLoading(true);
		let newDate = new Date(props?.orderDate);
		console.log("form",newDate.getFullYear()+"-"+((newDate.getMonth()+1).toString().padStart(2,"0"))+"-"+newDate.getDate().toString().padStart(2,"0"));
		let x = await dispatch(
			checkOutRequest({
				...form,
				products: cart,
				stripe_token: stripeToken?.generate ? stripeToken?.token : "",
				donation_amount: form?.fee ? parseFloat(form?.donation_amount) : parseFloat(form?.donation_amount),
				fee_amount: form?.fee ? fee?.fee : 0,
				selectCard: selectCard,
				order_date: newDate.getFullYear()+"-"+((newDate.getMonth()+1).toString().padStart(2,"0"))+"-"+newDate.getDate().toString().padStart(2,"0"),
			}),
		);
		if (x.payload) {
			setStripeToken({ generate: false, token: "" });
			if (form?.payment_method === "paypal") {
				if (x?.payload && x?.payload?.paypal_link)
					window.location.href = x?.payload?.paypal_link;
			}
			else {
				setForm({ ...initial });
				dispatch(removeAll());
				navigation({
					pathname: THANKYOU,
					search:
						"?" +
						createSearchParams({
							success: true,
							message: "Thank you for placing an Order!",
						}),
				});
			}
		}
		setLoading(false);
	};

	const applyFeeAmount = async () => {
		let total_amount = total(cart);

		total_amount += parseFloat(form?.donation_amount || 0);
		total_amount -= codeData ? amountTypeConvert(cart, codeData) : 0;
		// if(form?.fee)
		// console.log("total_amount",total_amount);
		setFeeLoading(true);
		let x = await dispatch(
			checkPaymentFreeRequest({
				total_amount: total_amount,
				payment_method: form?.payment_method,
			}),
		);
		// console.log("applyFeeAmount",x.payload);
		if (x?.payload) {
			setFee({ fee: x?.payload?.fee, total: x?.payload?.total });
		}
		setFeeLoading(false);
	};

	useEffect(() => {
		if (isLogin) {
			setForm((prevState) => ({
				...prevState,
				first_name: user?.first_name || "",
				last_name: user?.last_name || "",
				email: user?.email || "",
				contact_no: user?.contact_no || "",
				address: user?.address || "",
			}));
		}
		return () => console.log("");
	}, []);

	return (
		<section className="review-sec">
			<div className="container">
				<PaymentMethodWrapper type={form?.payment_method}>
					<form onSubmit={onSubmitHandler}>
						<div className="row">
							<div className="col-lg-6">
								<div className="img-check-out">
									<div className="post-reviews">
										<Form form={form} setForm={setForm} />
										{props?.paymentType === "pay_now" && (
											<>
												<div className="row">
													<div className="col-lg-12">
														<h4 className="payment-detail-heading">
															Payment Details
														</h4>
														{isLogin && (
															<div className="new">
																<div className="form-group">
																	<select
																		name="stripe_token"
																		onChange={(e) => {
																			setSelectCard(e.target.value);
																			console.log(e.target.value);
																			if (
																				e.target.value === "" ||
																				e.target.value === "add-new"
																			) {
																				// console.log("if");
																				setStripeToken({
																					generate: false,
																					token: "",
																				});
																			} else {
																				setStripeToken({
																					generate: true,
																					token: e.target.value,
																				});
																				console.log("else");
																			}
																		}}
																		className={"form-control"}
																		id=""
																	>
																		<option value="">Select Saved Card</option>
																		{list?.map((val, key) => (
																			<option key={key} value={val?.id}>
																				{val?.brand} - **** **** ****{" "}
																				{val?.last4} - {val?.exp_month}/
																				{val?.exp_year}{" "}
																			</option>
																		))}
																		<option value="add-new">
																			Add New Card
																		</option>
																	</select>
																</div>
															</div>
														)}
													</div>
												</div>
												{(selectCard === "add-new" || !isLogin) && (
													<div className="row">
														<div className="col-lg-12 pb-5">
															{form?.payment_method === "stripe" && (
																<StripeInjected
																	stripeToken={stripeToken}
																	setStripeToken={setStripeToken}
																/>
															)}
														</div>
													</div>
												)}
											</>
										)}
									</div>
								</div>
							</div>
							<div className="col-lg-6 ">
								<Summery
									applyFeeAmount={applyFeeAmount}
									codeData={codeData}
									setCodeData={setCodeData}
									paymentType={props?.paymentType}
									orderDate={props?.orderDate}
									form={form}
									setForm={setForm}
									feeLoading={feeLoading}
									fee={fee}
								/>
							</div>
						</div>
						<div className="check-top-border">
							<div className="row mt-3 mb-5">
								<div className="col-lg-9 align-self-center">
									{props?.paymentType === "pay_now" && (
										<div className="new1">
											{/*<form>*/}
											<div className="form-group-checkbox">
												<input
													type="checkbox"
													defaultChecked={form?.fee}
													name={"fee"}
													onClick={(e) => {
														onDirectChangeHandler(
															"fee",
															e.target.checked,
															setForm,
														);
														if (e.target.checked) {
															applyFeeAmount();
														} else {
															onDirectChangeHandler("fee", false, setForm);
															setFee({ fee: 0, total: 0 });
														}
													}}
													id="html1"
												/>
												<label htmlFor="html1">
													<span>
														I’d like to cover the fees associated with my
														donation, so 100% of my donation goes directly to
														Cookies4aCure.
													</span>
												</label>
											</div>

											{/*<div className="lock-heading mb-0">*/}
											{/*	<i className="fa fa-solid fa-fee"></i>*/}
											{/*	{form?.fee && (*/}
											{/*		<p>*/}
											{/*			{feeLoading*/}
											{/*				? "fee calculating..."*/}
											{/*				: `Fee is $${fee?.fee} and total amount is $${fee?.total}.`}*/}
											{/*		</p>*/}
											{/*	)}*/}
											{/*</div>*/}
										</div>
									)}
								</div>
								<div className="col-lg-3">
									<button
										type={"submit"}
										className="btn-review5 btn-review-btn-wrapper mt-0"
									>
										{loading ? (
											<i className={"fa fa-spin fa-spinner"}></i>
										) : props?.paymentType === "pay_now" ? (
											"Pay Now"
										) : (
											"Order Now"
										)}
									</button>
								</div>
							</div>
						</div>
					</form>
				</PaymentMethodWrapper>
			</div>
		</section>
	);
};

export default BottomSection;
