import React from 'react';
import { usePagination, DOTS } from '../../hooks/usePagination';
import {arrow2} from "../../constant/Index";

const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={'pagination'}
        >


            <>
                {
                    currentPage !== 1 ?
                        <li onClick={onPrevious} >
                            <a href="#" className="prev">
                                Prev
                                <img src={arrow2} className="img-fluid" alt=""/>
                            </a>
                        </li>
                        :
                        <li></li>
                }
            </>

            {paginationRange.map((pageNumber,key) => {
                if (pageNumber === DOTS) {
                    return (
                        <li key={key} className="pageNumber pagination-item dots">
                            <a>
                                &#8230;
                            </a>
                        </li>
                    )
                }

                return (
                    <li
                        // className={{
                        //     // selected: pageNumber === currentPage
                        // }}
                        key={key}
                        className={`pageNumber ${pageNumber === currentPage ? 'active' : ''}`}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        <a>
                            {pageNumber}
                        </a>
                    </li>
                );
            })}
            <>
                {
                    currentPage !== lastPage ?
                    <li
                        // className={{
                        //     // disabled: currentPage === lastPage
                        // }}
                        onClick={onNext}
                    >
                        <a className="next">
                            Next
                            <img src={arrow2} className="img-fluid" alt=""/>
                        </a>
                    </li>
                        :
                        <li></li>
                }
            </>

        </ul>
    );
};

export default Pagination;
