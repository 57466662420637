import React, {useEffect, useState} from "react";
import "../../assets/css/shop.css";
import {Link, useNavigate} from "react-router-dom";
import {CHECKOUT, SHOP} from "../../routes/RouteLinks";
import {useDispatch, useSelector} from "react-redux";
import {
    removeAll,
    removeItem,
    removePlattersItem, removePlattersItemWithKey,
    updateQtyNumber,
} from "../../redux/Cart/Reducer";
import {filePreviewer} from "../../constant/helpers";
// import { responseError } from "../../Netwrok/responseHandler";
import {setError} from "../../redux/Errors/Reducer";

const BottomSection = () => {
    const [adres, setadres] = useState(false);
    const navigate = useNavigate();
    const items = useSelector((state) => state?.cart?.items);
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.innerWidth <= 480) {
            setadres(true);
        }
    }, []);

    const onChangeQuantityHandler = (e, id, stock) => {
        let value = parseInt(e.target.value);
        value = !value && value === 0 && value < 0 ? 1 : value;
        if (value <= stock) {
            dispatch(
                updateQtyNumber({
                    qty: value,
                    id: id,
                }),
            );
        } else {
            dispatch(
                setError([{message: `Stock limit is ${stock} !!`, color: "warning"}]),
            );
        }
    };

    const updateQty = (type, qty, id, stock) => {
        if (type === "+" && qty < stock) {
            dispatch(
                updateQtyNumber({
                    qty: type === "+" ? qty + 1 : qty - 1,
                    id: id,
                }),
            );
        } else if (type === "-" && qty > 1) {
            dispatch(
                updateQtyNumber({
                    qty: type === "+" ? qty + 1 : qty - 1,
                    id: id,
                }),
            );
        }
    };

    const total = () => {
        let x = 0;
        items?.map((val) => {
            if (val?.product_type === "pretzel-rods") {
                x += parseFloat(val?.price) * parseInt(val?.qty);
            } else if (val?.product_type === "chocolate-covered-oreos") {
                x += parseFloat(val?.price) * parseInt(val?.qty);
            } else if (val?.product_type === "cookies") {
                x += parseFloat(val?.price);
            } else if (val?.product_type === "hard-tack-candy") {
                x += parseFloat(val?.price) * parseInt(val?.qty);
            } else if (val?.product_type === "apples") {
                x += parseFloat(val?.price) * parseInt(val?.qty);
            } else if (val?.product_type === "bags-of-pretzels") {
                x += parseFloat(val?.price) * parseInt(val?.qty);
            } else {
                x += parseFloat(val?.price) * parseInt(val?.qty);
            }
            // x += (val?.price * val?.qty)
        });
        return x;
    };

    return (
        <section className="whishlist-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11  mx-auto mt-5">
                        <div className={adres ? "table-wrapper lol" : "table-wrapper"}>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">
                                            {" "}
                                            <span
                                                style={{
                                                    width: "70px",
                                                    display: "block",
                                                }}
                                            >
													S No
												</span>{" "}
                                        </th>
                                        <th scope="col">
                                            {" "}
                                            <span
                                                style={{
                                                    width: "202px",
                                                    display: "block",
                                                }}
                                                className="product-name"
                                            >
													PRODUCT NAME
												</span>
                                        </th>
                                        <th scope="col">
                                            {" "}
                                            <span
                                                style={{
                                                    width: "100px",
                                                    display: "block",
                                                    margin: "0px auto",
                                                }}
                                            >
													UNIT PRICE
												</span>
                                        </th>
                                        <th scope="col">
                                            {" "}
                                            <span>QUANTITY</span>
                                        </th>
                                        <th scope="col">
                                            {" "}
                                            <span
                                                style={{
                                                    width: "100px",
                                                    display: "block",
                                                    margin: "0px auto",
                                                }}
                                            >
													SUB TOTAL
												</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items?.map((val, index) => (
                                        <React.Fragment key={index}>
                                            {val?.product_type === "cookies" ||
                                            val?.product_type === "apples" ||
                                            val?.product_type === "pretzel-rods" ||
                                            val?.product_type === "bags-of-pretzels" ||
                                            val?.product_type === "hard-tack-candy" ||
                                            val?.product_type === "chocolate-covered-oreos" ? (
                                                <tr>
                                                    <th
                                                        style={{textAlign: "left", paddingLeft: "20px"}}
                                                        scope="row"
                                                    >
                                                        {++index}
                                                    </th>
                                                    <td>
                                                        <div className="name-img-wrapper">
                                                            <img
                                                                src={filePreviewer(val?.image)}
                                                                width={"20%"}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            {/* <span> */}
                                                            <span className="title-name">
																	{(() => {
                                                                        if (val?.product_type === "pretzel-rods") {
                                                                            return (
                                                                                val?.parentTitle +
                                                                                " (" +
                                                                                val?.title +
                                                                                ")"
                                                                            );
                                                                        } else if (
                                                                            val?.product_type ===
                                                                            "chocolate-covered-oreos"
                                                                        ) {
                                                                            return (
                                                                                val?.parentTitle +
                                                                                " (" +
                                                                                val?.title +
                                                                                ")"
                                                                            );
                                                                        } else if (
                                                                            val?.product_type === "hard-tack-candy"
                                                                        ) {
                                                                            return (
                                                                                val?.parentTitle +
                                                                                " (" +
                                                                                val?.title +
                                                                                ")"
                                                                            );
                                                                        } else if (val?.product_type === "apples") {
                                                                            return (
                                                                                val?.parentTitle +
                                                                                " (" +
                                                                                val?.title +
                                                                                ")"
                                                                            );
                                                                        } else if (
                                                                            val?.product_type === "bags-of-pretzels"
                                                                        ) {
                                                                            let variants = "";
                                                                            val?.variants?.map((val) => {
                                                                                variants +=
                                                                                    val?.qty > 0
                                                                                        ? val?.title + " x" + val?.qty + ","
                                                                                        : "";
                                                                            });
                                                                            return (
                                                                                val?.title + " ( " + variants + " )"
                                                                            );
                                                                        } else {
                                                                            return val?.title;
                                                                        }
                                                                    })()}
																</span>
                                                            {/* </span> */}
                                                        </div>
                                                    </td>
                                                    <td>${parseFloat(val?.price)?.toFixed(2)}</td>
                                                    <td>
                                                        {(() => {
                                                            if (val?.product_type === "pretzel-rods") {
                                                                return val?.qty;
                                                            } else if (
                                                                val?.product_type ===
                                                                "chocolate-covered-oreos"
                                                            ) {
                                                                return parseInt(val?.qty);
                                                            } else if (val?.product_type === "cookies") {
                                                                return (
                                                                    // (val?.types
                                                                    // 	?.find((type) => type?.id === val?.type)
                                                                    // 	?.sizes?.find(
                                                                    // 		(size) => size?.id === val?.size,
                                                                    // 	)?.qty_in_dozen || 0)
                                                                    val?.sizeData?.qty_in_dozen + " dozen"
                                                                );
                                                            } else if (
                                                                val?.product_type === "hard-tack-candy"
                                                            ) {
                                                                return parseInt(val?.qty);
                                                            } else if (val?.product_type === "apples") {
                                                                return parseInt(val?.qty);
                                                            } else if (
                                                                val?.product_type === "bags-of-pretzels"
                                                            ) {
                                                                return parseInt(val?.qty) + " bags";
                                                            } else {
                                                                return "";
                                                            }
                                                        })()}
                                                    </td>
                                                    <td>
                                                        $
                                                        {(() => {
                                                            if (val?.product_type === "pretzel-rods") {
                                                                return (
                                                                    parseFloat(val?.price) * parseInt(val?.qty)
                                                                );
                                                            } else if (
                                                                val?.product_type ===
                                                                "chocolate-covered-oreos"
                                                            ) {
                                                                return (
                                                                    parseFloat(val?.price) * parseInt(val?.qty)
                                                                );
                                                            } else if (val?.product_type === "cookies") {
                                                                return parseFloat(val?.price);
                                                            } else if (
                                                                val?.product_type === "hard-tack-candy"
                                                            ) {
                                                                return (
                                                                    parseFloat(val?.price) * parseInt(val?.qty)
                                                                );
                                                            } else if (val?.product_type === "apples") {
                                                                return (
                                                                    parseFloat(val?.price) * parseInt(val?.qty)
                                                                );
                                                            } else if (
                                                                val?.product_type === "bags-of-pretzels"
                                                            ) {
                                                                return (
                                                                    parseFloat(val?.price) * parseInt(val?.qty)
                                                                );
                                                            } else {
                                                                // return parseFloat(val?.price) * parseInt(val?.qty);
                                                                return parseFloat(val?.price)?.toFixed(2);
                                                            }
                                                        })()}
                                                    </td>
                                                    <td>
                                                        <a
                                                            onClick={() =>
                                                                dispatch(
                                                                    (val?.product_type !== "cookies") ?
                                                                        removePlattersItem({
                                                                            id: val?.id,
                                                                            product_type: val?.product_type,
                                                                        })
                                                                        :
                                                                        removePlattersItemWithKey({
                                                                            key: val?.key,
                                                                        }),
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-solid fa-close"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <th
                                                        scope="row"
                                                        style={{textAlign: "left", paddingLeft: "20px"}}
                                                    >
                                                        {++index}
                                                    </th>
                                                    <td>
                                                        <div className="name-img-wrapper">
                                                            <img
                                                                src={filePreviewer(val?.image)}
                                                                width={"10%"}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            {/* <span> */}
                                                            <span className="title-name">{val?.title}</span>
                                                            {/* </span> */}
                                                        </div>
                                                    </td>
                                                    <td>${parseFloat(val?.price)?.toFixed(2)}</td>
                                                    <td>
                                                        <div className="quantityOne">
                                                            <input
                                                                className="spinNumberOutput"
                                                                type="number"
                                                                min={1}
                                                                max={val?.stock_qty}
                                                                onChange={(e) =>
                                                                    onChangeQuantityHandler(
                                                                        e,
                                                                        val?.id,
                                                                        val?.stock_qty,
                                                                    )
                                                                }
                                                                // readOnly
                                                                value={val?.qty}
                                                            />
                                                            <div className="spinNumber">
                                                                <div
                                                                    className="incrimentButton"
                                                                    onClick={() =>
                                                                        updateQty(
                                                                            "+",
                                                                            val?.qty,
                                                                            val?.id,
                                                                            val?.stock_qty,
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-solid fa-chevron-up"></i>
                                                                </div>

                                                                <div
                                                                    className="decrimentButton"
                                                                    onClick={() =>
                                                                        updateQty(
                                                                            "-",
                                                                            val?.qty,
                                                                            val?.id,
                                                                            val?.stock_qty,
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-solid fa-chevron-down"></i>
                                                                </div>
                                                            </div>
                                                            {/*<div className="regularPrice">90</div>*/}
                                                            {/*<div className="quantityOutput">0</div>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        $
                                                        {(
                                                            parseFloat(val?.price) * parseInt(val?.qty)
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td>
                                                        <a onClick={() => dispatch(removeItem(val?.id))}>
                                                            <i className="fa fa-solid fa-close"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="total-cost-wrapper">
                                <div className="total-recol ms-auto ml-auto">
                                    <div className="total-heading-wrapper">
                                        <div className="total-heading">
                                            <h5>Subtotal</h5>
                                            {/*<h5> Estimated Shipping Cost</h5>*/}
                                        </div>
                                        <div className="total-heading">
                                            <h5>${total()?.toFixed(2)}</h5>
                                            {/*<h5>Free Shipping</h5>*/}
                                        </div>
                                    </div>
                                    <div className="total-heading-wrapper1">
                                        <div className="total-heading1">
                                            <h5> TOTAL COST </h5>
                                            <h5>$ {total()?.toFixed(2)}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  cart-margin-down">
                            <div className="col-lg-6 col-md-6">
                                <div className="btn-wrapper-card ">
                                    <button
                                        type={"button"}
                                        onClick={() => navigate(SHOP)}
                                        className="btn"
                                    >
                                        Continue Shopping
                                    </button>
                                    <button
                                        type={"button"}
                                        onClick={() => dispatch(removeAll())}
                                        className="btn ml-2 px-5 clear-btn"
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="btn-card-wrapper">
                                    <Link to={CHECKOUT}>
                                        <button className="btn">Proceed to Checkout</button>
                                    </Link>
                                    <p>You’ll still have a chance to review your order</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BottomSection;
