import React, {useEffect, useState} from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import '../../assets/css/shop.css';
import Filter from "./Filter";
import Item from "../../components/Shop/Item";
import {chunks} from "../../constant/helpers";
import Pagination from "../../components/Pagination/pagination";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const BottomSection = ({get}) => {

    const products = useSelector(state => state?.product?.items);

    return (
       <section className="support-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <Filter get={get}/>
                        </div>
                        <div className="col-lg-9">
                            {
                                products?.data?.length <= 0 ?
                                <div className="alert alert-dark" style={{marginTop:'85px'}} role="alert">
                                    No Cookies found!
                                </div>
                                    :
                                <>
                                    {
                                        chunks(products?.data, 4).map((item, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-lg-12">
                                                    <div className="main-card-shop">
                                                        {
                                                            item.map((val, key) => (
                                                                <Item item={val} key={key}/>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <h4 className="pagination1">
                                                Showing {parseInt(products?.from)}–{parseInt(products?.to)} of {products?.total} results
                                            </h4>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="pagination-wrapper">
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={products?.current_page}
                                                    totalCount={products?.total}
                                                    pageSize={products?.per_page}
                                                    onPageChange={ page => {
                                                        // console.log(page);
                                                        get(page);
                                                    } }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </section>
    );
};

export default BottomSection;
