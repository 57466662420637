import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {
    donationsApi,
    newsletterApi,
    ordersApi,
    payDonationApi,
    payPalFeeApi,
    stripeFeeApi,
    updateDonationApi
} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {headers} from "../headers";
import {setOrders} from "../CheckOut/Reducer";
import {setDonation} from "./Reducer";

export const checkPaymentFreeRequest = createAsyncThunk('payment-method-fee-request', async (
    data,
    {
        dispatch,
        rejectWithValue,
        fulfillWithValue
    }) => {
    try {
        let x = await axios.post((data?.payment_method === "paypal") ? payPalFeeApi : stripeFeeApi,
            {
                amount: data?.total_amount,
                currency: 'usd'
            },
            headers()
        );
        return fulfillWithValue(x.data?.response?.data);
    } catch (e) {
        dispatch(setError(responseError(e)));
        return rejectWithValue(false);
    }
});

export const sendDonationRequest = createAsyncThunk('donation-request', async (
    data,
    {
        dispatch,getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
    try {
        let token = getState().auth?.token;
        let x = await axios.post(payDonationApi, data, headers(token));
        dispatch(setError([{
            message: 'Thank you for donation', color: 'success'
        }]));
        return fulfillWithValue(x.data.response.data);
    } catch (e) {
        dispatch(setError(responseError(e)));
        return rejectWithValue(false);
    }
});

export const updateDonationRequest = createAsyncThunk('update-donation-request', async (
    data,
    {
        dispatch,
        rejectWithValue,
        fulfillWithValue
    }) => {
    try {
        await axios.post(updateDonationApi, data, headers());
        dispatch(setError([{
            message: 'Thank you for donation', color: 'success'
        }]));
        return fulfillWithValue(true);
    } catch (e) {
        dispatch(setError(responseError(e)));
        return rejectWithValue(false);
    }
});

export const getDonationsRequest = createAsyncThunk('get-donation-request',async (
    data = null,
    {
        dispatch, getState,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let x = await axios.get(donationsApi,headers(token));
        dispatch(setDonation(x.data?.response?.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});