import React, { useEffect } from "react";
import "../../assets/css/ingredient.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {getIngredientRequest} from "../../redux/Setting/Action";
import BottomSection from "../../components/Ingredient/BottomSection";
import {setLoader} from "../../redux/Loader/Reducer";

const Ingredient = () => {
	const dispatch = useDispatch();
	const content = useSelector(state => state?.setting?.ingredient);
	const get = async() => {
		dispatch(setLoader(true));
		await dispatch(getIngredientRequest());
		window.scrollTo(0, 0);
		dispatch(setLoader(false));
	}

	useEffect(()=>{
		window.scrollTo(0, 0);
		get();
	},[])
	return (
		<>

			{/* InnerBanner Start Here */}
			<InnerBanner PageName="INGREDIENTS" />
			{/* InnerBanner End Here */}
			<BottomSection data={content} />
		</>
	);
};

export default Ingredient;
