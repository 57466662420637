import { createSlice } from '@reduxjs/toolkit'


export const Donations = createSlice({
    name: 'donations',
    initialState : {
        donations : [],
    },
    reducers: {
        setDonation : (state,action) => {
            state.donations = [
                ...action.payload
            ]
        }
    }
});

// Action creators are generated for each case reducer function
export const { setDonation } = Donations.actions

export default Donations.reducer