import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {
    addWishListApi,
    blogDetailApi,
    blogsApi, deleteCardApi, deleteWishListApi, getCardApi,
    packageApi,
    packagePlaceOrderApi,
    packagesApi, storeCardApi,
    wishListApi
} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {headers} from "../headers";
import {removeList, removeWishList, setList, setNewList, setNewWishList, setWishList} from "./Reducer";

export const getCardListRequest = createAsyncThunk('get-wishlist-request',async (
    url = null,
    {
        getState,
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let resp = await axios.get(getCardApi,headers(token));
        // console.log("fkdlfkf");
        dispatch(setList(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const addCardRequest = createAsyncThunk('add-card-request',async (
    data,
    {
        getState,
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let resp = await axios.post(storeCardApi,data,headers(token));
        dispatch(setNewList(resp.data.response.data));
        await dispatch(setError([
            { message : 'Card added successfully!', color : 'success'}
        ]));
        return fulfillWithValue(true);

    }catch (e){

        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);

    }
});

export const deleteWishListRequest = createAsyncThunk('delete-wishlist-request',async (
    id,
    {
        getState,
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    }) => {
    try {
        let token = getState().auth?.token;
        let resp = await axios.delete(deleteCardApi(id),headers(token));
        dispatch(removeList(id));
        await dispatch(setError([
            { message : 'Card removed successfully!', color : 'success'}
        ]));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});