import {createAsyncThunk} from "@reduxjs/toolkit";
import header from "../../components/Header/Header";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {
    AboutApi, datesApi,
    donationsPageApi,
    donationsReasonsApi,
    EventPageApi,
    FactApi,
    homePageApi, homeShopPageApi, ingredientPageApi,
    pagesApi, payPalFeeApi,
    settingApi, stripeFeeApi,
    TeamsApi,
} from "../../Netwrok/api";

import {responseError} from "../../Netwrok/responseHandler";
import {
    setAbout, setDates,
    setDonate,
    setDonateReason,
    setEvent,
    setFact,
    setHome, setHomeShop,
    setIngredient,
    setPage, setPaypal,
    setSetting, setStripe,
    setTeam
} from "./Reducer";
import {headers} from "../headers";


export const getPageRequest = createAsyncThunk('get-page-request',async (
    slug,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(pagesApi(slug),headers());
        dispatch(setPage(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getSettingRequest = createAsyncThunk('get-setting-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(settingApi,headers());
        dispatch(setSetting(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getDonateReasonsRequest = createAsyncThunk('get-donate-reasons-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(donationsReasonsApi,headers());
        dispatch(setDonateReason(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getDatesRequest = createAsyncThunk('get-dates-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(datesApi,headers());
        // console.log("Dates",resp);
        dispatch(setDates(resp.data.response.data || []));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getDonatePageRequest = createAsyncThunk('get-donate-page-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(donationsPageApi,headers());
        dispatch(setDonate(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

// Home Api
export const getHomeRequest = createAsyncThunk('get-home-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(homePageApi,headers());
        dispatch(setHome(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});
export const homeShopPageRequest = createAsyncThunk('get-shop-home-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(homeShopPageApi,headers());
        dispatch(setHomeShop(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

// Fact Api
export const getFactRequest = createAsyncThunk('get-fact-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(FactApi,headers());
        dispatch(setFact(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

// Index Api
export const getAboutRequest = createAsyncThunk('get-about-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(AboutApi,headers());
        dispatch(setAbout(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

// Team Api
export const getTeamRequest = createAsyncThunk('get-team-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(TeamsApi,headers());
        dispatch(setTeam(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

// Event Api
export const getEventRequest = createAsyncThunk('get-event-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(EventPageApi,headers());
        dispatch(setEvent(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});


// Ingredient Api
export const getIngredientRequest = createAsyncThunk('get-ingredient-request',async (
    data = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(ingredientPageApi,headers());
        dispatch(setIngredient(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getPayPalFeeRequest = createAsyncThunk('get-paypal-request',async (
    data,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.post(payPalFeeApi,data,headers());
        dispatch(setPaypal(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getStripeFeeRequest = createAsyncThunk('get-stripe-request',async (
    data,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.post(stripeFeeApi,data,headers());
        dispatch(setStripe(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});