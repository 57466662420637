import React from "react";
import {filePreviewer} from "../../constant/helpers";
import {Link} from "react-router-dom";
import {arrow_blog} from "../../constant/Index";
import {BLOG_DETAIL} from "../../routes/RouteLinks";

const Item = ({item}) => {

    return (
        <div className="col-lg-4 col-md-6">
            <div className="blog-img"
                 // data-aos="flip-left" data-aos-easing="ease-out-cubic"
                 // data-aos-duration="1500"
            >
                <img src={filePreviewer(item?.banner_image)} className="img-fluid baby-wrapper" alt=""/>
                <h4>{item?.title}</h4>
                <p>
                    {item?.sub_title}
                </p>
                <h4>
                    <Link to={BLOG_DETAIL(item?.slug)}>
                        Continue Reading
                        <img src={arrow_blog} className="img-fluid" alt=""/>
                    </Link>
                </h4>
            </div>
        </div>
    )
}

export default Item;