import React from "react";
// import PhoneInput from "react-phone-input-2";
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import InputMask from 'react-input-mask';


export const Input = (
    type,
    name,
    required,
    value,
    onChangeHandler,
    className="form-control",
    id,
    placeholder=""
) => {
    return (
        <input type={type}
               name={name}
               required={required}
               value={value}
               onChange={onChangeHandler}
               className={className || 'form-control'}
               id={id}
               placeholder={placeholder}
        />
    )
}

export const TextArea = (
    name,
    required,
    value,
    onChangeHandler,
    className="form-control",
    id,
    placeholder=""
) => {
    return (
        <textarea
               name={name}
               required={required}
               defaultValue={value}
               onChange={onChangeHandler}
               className={className || 'form-control'}
               id={id}
               placeholder={placeholder}
        ></textarea>
    )
}

export const CustomPhoneNumber = (props) => {
    return (
        <InputMask
            placeholder={"Enter phone number"}
            className={"form-control"}
            mask='(999)999-9999'
            value={props.value?.replace(/[- )(]/g,'')}
            onChange={props.onChange}>
        </InputMask>
    )
}