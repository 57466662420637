import React , {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import '../../assets/css/style.css';
import '../../assets/css/order-detail.css';
import {useNavigate, useParams} from "react-router-dom";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {setLoader} from "../../redux/Loader/Reducer";
import {getOrderDetailRequest, getOrdersRequest} from "../../redux/CheckOut/Action";
import {filePreviewer} from "../../constant/helpers";

const OrderDetail = () => {

    const dispatch = useDispatch();
    const {id} = useParams();
    const order = useSelector(state => state?.orders?.order);

    const navigate = useNavigate();

    const get= async()=>{
        dispatch(setLoader(true));
        await dispatch(getOrderDetailRequest(id));
        dispatch(setLoader(false));
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        get();
    },[id])

    return (
        <>
            <InnerBanner PageName="ORDERS DETAILS"/>

            <section className="order-detail-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6  mx-auto">
                            <div className="detail-order">
                                <h4>ID #{order?.id?.toString()?.padStart(4,'0')}</h4>
                                <div className="order-detail-wrapper">
                                    {
                                        order?.order_details?.map((val,key)=>(
                                            <div key={key} className="row mt-4">

                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <div className="cokkies-order">
                                                        <img src={filePreviewer(
                                                            (val?.product?.product_type === "bags-of-pretzel-twists")?
                                                                val?.product?.platter?.image
                                                             :
                                                                val?.product?.image
                                                        )} className="img-fluid" alt="" />
                                                        <div className="quantity-order">
                                                            <h6>{val?.product?.title}</h6>
                                                            <h5>Quantity: {val?.qty} </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-6">
                                                    <div className="cokkies-order1">
                                                        <div className="quantity-order">
                                                            <h6>${(parseInt(val?.qty) * parseFloat(val?.price))?.toFixed(2)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="orderdetail">
                                                <h5>Order Detail</h5>
                                            </div>
                                            <div className="order-detail-complete">
                                                <div className="details-child">
                                                    <ul>
                                                        <li>Date : <span>{order?.created_at}
                                                </span>
                                                        </li>
                                                        <li>Subtotal : <span>${parseFloat(order?.order_amount)?.toFixed(2)}
                                                </span>
                                                        </li>
                                                {/*        <li>Shipping Fee : <span>$5.00*/}
                                                {/*</span>*/}
                                                {/*        </li>*/}
                                                        <li>Coupon Discount : <span>${parseFloat(order?.discount_amount)?.toFixed(2)}
                                                </span>
                                                        </li>
                                                        <li>Order Status <span>{order?.order_status}</span></li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default OrderDetail;
