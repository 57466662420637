import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { filePreviewer } from "../../constant/helpers";

const MultiSlider = (props) => {
	const [navs, setNavs] = useState({
		nav1: null,
		nav2: null,
	});
	const [slider1, setSlider1] = useState([]);
	const [slider2, setSlider2] = useState([]);

	useEffect(() => {
		setSlider1([]);
		setSlider2([]);
		return () => {
			setSlider1([]);
			setSlider2([]);
		};
	}, []);

	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();

	useEffect(() => {
		// console.log("Slider change", slider1, slider2);

		setNavs({ nav1: slider1, nav2: slider2 });
	}, [slider1, slider2]);

	const settings = {
		slidesToShow: 1,
		arrows: true,
		// asNavFor: '.slider-nav',
		// vertical: true,
		autoplay: true,
		// verticalSwiping: true,
		// centerMode: true,
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToScroll: 1,
	};

	return (
		<>
			<Slider
				// asNavFor={nav2} ref={(slider1) => setNav1(slider1)}
				{...settings}
			>
				{props?.attachments?.map((val, key) => (
					<div className="all-borders" key={key}>
						<img
							className="img-fluid  on-small-border"
							width={"100%"}
							src={filePreviewer(val?.file_name)}
						/>
					</div>
				))}
			</Slider>
			{/* 
			<Slider
				asNavFor={nav1}
				ref={(slider2) => setNav2(slider2)}
				slidesToShow={4}
				swipeToSlide={true}
				focusOnSelect={true}
			>
				{props?.attachments?.map((val, key) => (
					<div className="" key={key}>
						<img
							className="img-fluid on-small-border"
							width={"60%"}
							src={filePreviewer(val?.file_name)}
						/>
					</div>
				))}
			</Slider> */}
		</>
	);
};

// const Index = ;

export default MultiSlider;
