import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {blogDetailApi, blogsApi} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {setBlog, setBlogs} from "./Reducer";
import {headers} from "../headers";


export const getBlogsRequest = createAsyncThunk('get-blogs-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(blogsApi,headers());
        dispatch(setBlogs(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const getBlogRequest = createAsyncThunk('get-blog-request',async (
    id,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(blogDetailApi(id),headers());
        dispatch(setBlog(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});