import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {eventOrderApi, newsletterApi} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {headers} from "../headers";


export const eventRequest = createAsyncThunk('event-request',async (
    data,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        await axios.post(eventOrderApi,data,headers());
        dispatch( setError([ {
        message : 'Thank you for placing your order!' , color : 'success'
        }] ) );
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});