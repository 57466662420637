import { createSlice } from '@reduxjs/toolkit'


export const Product = createSlice({
    name: 'product',
    initialState : {
        items : {
            current_page : 0,
            data : [],
            first_page_url : "",
            from : null,
            last_page : 0,
            last_page_url : "",
            links : [],
            next_page_url: null,
            path: "",
            per_page: 12,
            prev_page_url: null,
            to: null,
            total: 0
        },
        item : null
    },
    reducers: {
        setProducts(state,action) {
          state.items = action.payload;
        },
        setProduct (state,action) {
            state.item = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProducts , setProduct } = Product.actions

export default Product.reducer