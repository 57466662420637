import React, {useEffect, useState} from "react";
import {log_out} from "../../constant/Index";
import {onDirectChangeHandler, onInputChangeHandler} from "../../constant/helpers";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FORGOT, HOME, LOGIN, RESET_PASSWORD, SIGNUP} from "../../routes/RouteLinks";
import {useDispatch, useSelector} from "react-redux";
import {
    forgotRequest,
    loginRequest,
    resetPasswordRequest,
    signUpRequest,
    verifyOtpRequest
} from "../../redux/Auth/Action";

const Reset = () => {

    const initial = {
        email: '',
        otp: '',
        password: '',
        confirm_password: '',
    }
    const dispatch = useDispatch();
    const {state} = useLocation();
    const navigation = useNavigate();
    const [form, setForm] = useState({...initial});
    const [loading, setLoading] = useState(false);
    const isLogin = useSelector(state => state?.auth?.isLogin);

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        let x = await dispatch(resetPasswordRequest({
            ...form,
            email : state?.email,
            otp : state?.otp
        }));
        setLoading(false);
        if (x.payload) {
            setForm({...initial});
            navigation(LOGIN);
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        if (isLogin) {
            navigation(HOME);
        }
        // console.log(state);
        if (state === null || state?.otp == "" || state?.email == "") {
            navigation(FORGOT);
        }
    }, [isLogin, state?.otp, state?.email])

    return (
        <section className="review-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="img-check-out">
                            <img src={log_out} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <form onSubmit={onSubmitHandler}>
                            <div className="post-reviews">
                                <h3>
                                    Reset Your Password
                                </h3>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput1">Email</label>
                                            <div className={"form-control"}>
                                                        <span>
                                                            {state?.email}
                                                        </span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput1">New Password</label>
                                            <input type="password" className="form-control"
                                                   id="exampleFormControlInput1"
                                                   value={form?.password} required={true} name={'password'}
                                                   onChange={(e) => onInputChangeHandler(e, setForm)}
                                                   placeholder="*************"/>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput1">Confirm Password</label>
                                            <input type="password" className="form-control"
                                                   id="exampleFormControlInput1"
                                                   value={form?.confirm_password} required={true}
                                                   name={'confirm_password'}
                                                   onChange={(e) => onInputChangeHandler(e, setForm)}
                                                   placeholder="*************"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                        <button type={"submit"} disabled={loading} className="btn-review">
                                            {loading ? <i className={"fa fa-spin fa-spinner"}></i> : `Change Password`}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Reset;
