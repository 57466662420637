import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {Provider} from "react-redux";
import {store , persistor} from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'animate.css';
import AOS from 'aos';
// import 'font-awesome/css/font-awesome.min.css';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();
global.jQuery = require('jquery');
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
);
