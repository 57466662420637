import React, {useState} from 'react';

const PromoCode = ({loading , applyCode }) => {

    const [code , setCode] = useState('');

    return (
        <>
            <div className="col-lg-7 pr-0 mb-3
                              align-self-center">
                <div className="form-group-order">
                    <input type="text" className="form-control" value={code}
                           id="exampleFormControlInput1" placeholder="Enter Promo Code"
                           onChange={(e)=>setCode(e.target.value)} />
                </div>
            </div>
            <div className="col-lg-5">
                <div className="form-group-order1">
                    <button type={"button"} onClick={() => applyCode(code)} className="btn-review5 order-btn-wrapper mt-0">
                        {
                            loading?
                                <i className={"fa fa-spin fa-spinner"}></i>
                                :
                                'Apply'
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

// const Form =  React.memo(FormSection);
export default PromoCode;