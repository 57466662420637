import React, { useState } from "react";
import {
	onDirectChangeHandler,
	onInputChangeHandler,
} from "../../constant/helpers";
import { useSelector } from "react-redux";
import StripeInjected from "../../components/Stripe/StripeInjected";
import { CustomPhoneNumber } from "../../components/FormComponent";
// import Input from 'react-phone-number-input/input';
// import 'react-phone-number-input/style.css';

const DonationForm = (props) => {
	const [otherAmount, setOtherAmount] = useState(false);
	const discount = useSelector((state) => state?.setting?.donate);

	const { form, setForm } = props;

	return (
		<>
			<div
				className="heading-about-img-wrapper "
			// data-aos-anchor-placement="center-center"
			>
				<div dangerouslySetInnerHTML={{ __html: discount?.content_heading }} />

				<p
					className=" fullent-para"
					dangerouslySetInnerHTML={{ __html: discount?.content_para }}
				/>

				<h6>
					Payment Type:<span>One Time</span>
				</h6>

				<p>Choose Amount</p>

				{!otherAmount && (
					<div className="radio-wrapper">
						<input
							type="radio"
							required={false}
							id="amount1"
							onClick={(e) => {
								onInputChangeHandler(e, setForm);
								props?.applyFeeAmount(e.target.value);
							}}
							defaultChecked={form?.total_amount === "10" ? true : false}
							name="total_amount"
							value="10"
						/>
						<label htmlFor="amount1">$10</label>
						<br />
						<input
							type="radio"
							required={false}
							id="amount2"
							defaultChecked={form?.total_amount === "25" ? true : false}
							onClick={(e) => {
								onInputChangeHandler(e, setForm);
								props?.applyFeeAmount(e.target.value);
							}}
							name="total_amount"
							value="25"
						/>
						<label htmlFor="amount2">$25</label>
						<br />
						<input
							type="radio"
							required={false}
							id="amount3"
							defaultChecked={form?.total_amount === "50" ? true : false}
							onClick={(e) => {
								onInputChangeHandler(e, setForm);
								props?.applyFeeAmount(e.target.value);
							}}
							name="total_amount"
							value="50"
						/>
						<label htmlFor="amount3">$50</label>
						<br />
						<input
							type="radio"
							required={false}
							id="amount4"
							defaultChecked={form?.total_amount === "100" ? true : false}
							onClick={(e) => {
								onInputChangeHandler(e, setForm);
								props?.applyFeeAmount(e.target.value);
							}}
							name="total_amount"
							value="100"
						/>
						<label htmlFor="amount4">$100</label>
						<br />
						<br />
					</div>
				)}

				<div className="form-group12">
					<input
						type="checkbox"
						className="checkbox"
						defaultChecked={otherAmount}
						onClick={(e) => {
							setOtherAmount(e.target.checked);
							props?.applyFeeAmount(0);
							onDirectChangeHandler(
								"total_amount",
								(e.target.checked) ? 0 : '',
								setForm
							);
						}}
						id="other_amount"
					/>
					<label htmlFor="other_amount">Other Amount</label>
				</div>
				{/*<div class="form-group">*/}
				{/*	<label for="exampleFormControlSelect1">Donation Reason</label>*/}
				{/*	<select class="form-control" id="exampleFormControlSelect1">*/}
				{/*		<option>Select Donation Reason</option>*/}
				{/*		<option>Select Donation Reason</option>*/}
				{/*		<option>Select Donation Reason</option>*/}
				{/*		<option>Select Donation Reason</option>*/}
				{/*	</select>*/}
				{/*</div>*/}
				{otherAmount && (
					<div className="form-group">
						<input
							type="number"
							className="form-control"
							id="exampleInputEmail1"
							name={"total_amount"}
							required={true}
							value={form?.total_amount}
							onChange={(e) => {
								onInputChangeHandler(e, setForm);
								if (e.target.value != "") props?.applyFeeAmount(e.target.value);
							}}
							aria-describedby="emailHelp"
							placeholder="Enter your Amount"
						/>
					</div>
				)}
			</div>

			<div
				className="img-check-out" /* data-aos-anchor-placement="center-center"*/
			>
				<div className="post-reviews">
					<h3> Personal Information </h3>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="exampleFormControlInput1">First Name</label>
								<input
									type="text"
									required={true}
									name={"first_name"}
									value={form?.first_name}
									onChange={(e) => onInputChangeHandler(e, setForm)}
									className="form-control"
									id="exampleFormControlInput1"
									placeholder="Enter Your Name"
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="exampleFormControlInput1">Last Name</label>
								<input
									type="text"
									required={true}
									name={"last_name"}
									value={form?.last_name}
									onChange={(e) => onInputChangeHandler(e, setForm)}
									className="form-control"
									id="exampleFormControlInput1"
									placeholder="Enter Last Name"
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="exampleFormControlInput1">Phone</label>
								{/* <div className={"form-group"}> */}
								<CustomPhoneNumber
									value={form?.contact_no}
									onChange={(e) => {
										onDirectChangeHandler(
											"contact_no",
											e.target.value,
											setForm,
										);
									}}
								/>
								{/*<Input*/}
								{/*    className={"form-control"}*/}
								{/*    required={true}*/}
								{/*    style={{width:'100%'}}*/}
								{/*    placeholder="Your Phone"*/}
								{/*    value={form?.contact_no}*/}
								{/*    onChange={(e)=>onDirectChangeHandler('contact_no',e,setForm)}/>*/}
								{/* </div> */}
								{/*<input type="tel" required={true} name={'contact_no'}*/}
								{/*       value={form?.contact_no}*/}
								{/*       onChange={(e) => onInputChangeHandler(e, setForm)}*/}
								{/*       className="form-control" id="exampleFormControlInput1"*/}
								{/*       placeholder="Enter Phone"/>*/}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="form-group">
								<label htmlFor="exampleFormControlInput1">Email</label>
								<input
									type="email"
									required={true}
									name={"email"}
									value={form?.email}
									onChange={(e) => onInputChangeHandler(e, setForm)}
									className="form-control"
									id="exampleFormControlInput1"
									placeholder="Enter Email"
								/>
							</div>
						</div>
					</div>
					{form?.payment_method === "stripe" && (
						<div className="row">
							<div className="col-lg-12 ">
								<h4 className="payment-detail-heading">Payment Details</h4>
								<StripeInjected
									stripeToken={props?.stripeToken}
									setStripeToken={props?.setStripeToken}
								/>
							</div>
						</div>
					)}

					<div className="row ">
						<div className="col-lg-12 ">
							<div className="form-group-checkbox">
								<input
									type="checkbox"
									onClick={(e) => {
										onDirectChangeHandler("fee", e.target.checked, setForm);
										if (e.target.checked && form?.total_amount !== "")
											props?.applyFeeAmount(form?.total_amount);
									}}
									id="html1"
								/>
								<div>
									<label htmlFor="html1">
										<span style={{ fontSize: "14px", color: "#6a6a6a" }}>
											I’d like to cover the fees associated with my donation, so
											100% of my donation goes directly to Cookies4aCure.
										</span>
									</label>
								</div>

							</div>

							{
								form?.total_amount !== "" &&
								<div className="lock-heading mb-0">
									<i className="fa fa-solid fa-fee"></i>
									{form?.fee && (
										<p>
											{props.feeLoading
												? "fee calculating..."
												: `Fee is $${props?.fee?.fee} and total amount is $${props?.fee?.total}.`}
										</p>
									)}
								</div>
							}

							<div className="lock-heading">
								<i className="fa fa-solid fa-lock"></i>
								<p>This is secure encrypted payment.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DonationForm;
