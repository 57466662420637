import React from "react";
import { Link } from "react-router-dom";
import {filePreviewer} from "../../constant/helpers";

const Product = (props) => {
	return (
		<>
			<div className="product-card-wrapper wow animate__animated animate__flipInY">
				<div className="product-img-wrapper">
					<img src={filePreviewer(props.Img)} className="img-fluid" alt="" />
				</div>
				<div className="product-content-wrapper">
					<h6>{props.name}</h6>
					<p className={"m-0"} dangerouslySetInnerHTML={{__html: props?.description}} />
					<h5>{props.price}</h5>
					<Link to={props?.link} className="btn">
						Shop Now
					</Link>
				</div>
			</div>
		</>
	);
};

export default Product;
