import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {MODE} from "../constant/Variables";
import storage from 'redux-persist/lib/storage';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import thunk from "redux-thunk";

// Import Reducers :
import Auth from './Auth/Reducer';
import Orders from './CheckOut/Reducer';
import Cart from './Cart/Reducer';
import Product from './Products/Reducer';
import Error from './Errors/Reducer';
import Setting from './Setting/Reducer';
import Blogs from './Blogs/Reducer';
import Faqs from './Faqs/Reducer';
import Package from './Packages/Reducer';
import Pretzels from "./ChocolateAndPretzels/reducer";
import WishList from "./Wishlist/Reducer";
import Donations from "./Donation/Reducer";
import Loader from "./Loader/Reducer";
import Cards from "./Cards/Reducer";

const combineReducer = combineReducers({
    auth: Auth,
    cart: Cart,
    product : Product,
    error : Error,
    setting : Setting,
    blogs : Blogs,
    faqs : Faqs,
    package : Package,
    pretzels : Pretzels,
    wishlist : WishList,
    orders : Orders,
    donations : Donations,
    loader : Loader,
    cards : Cards,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth','cart','setting','pretzels']
}

const persistedReducer = persistReducer(persistConfig, combineReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: MODE !== 'production',
    // middleware: [thunk]
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});


export const persistor = persistStore(store)
