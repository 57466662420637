import React from "react";
import '../../assets/css/cookies-item.css';
import {Link} from "react-router-dom";

const CookiesItem = ({ item }) => {
    return (
        <>
            <div
                className="card-wrapper wow animate__animated animate__flipLeft align-items-stretch card-design"
            >
                <img
                    src={item?.image}
                    className="img-fluid set-images"
                    alt=""
                />
                <div className="card-border-heading d-flex flex-column">
                    <h6>{item?.title}</h6>
                    <Link
                        to={item?.slug}
                        className={`btn11 align-self-end mx-auto`}
                    >
                        View
                    </Link>
                    {/*</Link>*/}
                </div>
            </div>
        </>
    );
};

export default CookiesItem;
