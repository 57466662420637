import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {blogDetailApi, blogsApi, faqsApi} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {setBlog, setBlogs, setFaqs} from "./Reducer";
import {headers} from "../headers";


export const getFaqsRequest = createAsyncThunk('get-faqs-request',async (
    url = null,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let resp = await axios.get(faqsApi,headers());
        dispatch(setFaqs(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});