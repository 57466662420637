export const HOME = '/';
export const ABOUT = '/about-us';
export const FILTER = '/filter';
export const SHOP = '/shops';
export const SHOPDETAIL = '/shop';
export const CHOCOLATE_PLATTERS = '/chocolate-platters';
export const APPLES = '/chocolate-platters/apples';
export const CHOCOLATE_PLATTER_ITEM = '/chocolate-platters/chocolate';
export const BAGS = '/chocolate-platters/bags';
export const CANDY = '/chocolate-platters/candy';
export const OREOS = '/chocolate-platters/oreos';
export const RODS = '/chocolate-platters/rods';
export const COOKIES = '/chocolate-platters/cookies';
export const SHOP_DETAIL = (id) => `${SHOPDETAIL}/${id}`;
export const CONTACTUS = '/contact-us';
export const TERM_CONDITION = '/term-condition';
export const PRIVACY_POLICY = '/privacy-policy';
export const CART = '/cart';
export const WISHLIST = '/wishlist';
export const ORDERDETAIL = '/order-detail';
export const ORDER_DETAIL = (id) => `/${ORDERDETAIL}/${id}`;
export const THANKYOU = '/thankyou';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const FORGOT = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const FAQS = '/faqs';
export const DONATE = '/donate';
export const INGREDIENT = '/ingredient';
export const BlOGS = '/blogs';
export const BlOG = '/blog';
export const BLOG_DETAIL = (id) => `${BlOG}/${id}`;
export const EVENT = '/special-event';
export const CARE_PACKAGE = '/care-package';
export const CARE_PACKAGE_DETAIL = (id) => `${CARE_PACKAGE}/${id}`;

export const DASHBOARD = '/profile';
export const CHECKOUT = '/checkout';
