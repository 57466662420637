import React, {useEffect, useState} from 'react';
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import PickUpDate from "../../components/PickUpDate";
import {useDispatch, useSelector} from "react-redux";
import { getDatesRequest } from "../../redux/Setting/Action";
import BottomSection from "./BottomSection";
import {setLoader} from "../../redux/Loader/Reducer";
import {useNavigate} from "react-router-dom";
import {SHOP} from "../../routes/RouteLinks";
import moment from "moment";
import {getCardListRequest} from "../../redux/Cards/Action";


const Checkout = () => {
    // console.log("process.env.REACT_STRIPE_PUBLISHED_KEY",process.env.REACT_APP_STRIPE_SECRET_KEY);

    const [ orderDate , setOrderDate ] = useState(null);
    const [ step , setStep ] = useState(0);
    const [ paymentType , setPaymentType ] = useState('');
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const isLogin = useSelector(state => state?.auth?.isLogin);
    const cart = useSelector(state => state?.cart?.items);

    const onHandleStep = ( stepNo , type ) => {
        setStep(stepNo);
        setPaymentType(type);
    }

    const getDates = async () => {
        dispatch(setLoader(true));
        await dispatch( getDatesRequest() );
        dispatch(setLoader(false));
    }

    useEffect(() => {

        window.scrollTo(0, 0);
        if( cart.length <= 0 ) navigation(SHOP);
        // let monthName = moment(new Date(), 'DD-MM-YYYY').format('MMM');
        // if( monthName === "Dec" ) {
        //     getDates();
        // }
        getDates();
        if(isLogin) dispatch( getCardListRequest() );

    },[])

    return (
        <>
            <InnerBanner PageName={'CHECKOUT'} />
            {
                step === 0 ?
                    <PickUpDate
                        orderDate={orderDate} setOrderDate={setOrderDate}
                        onHandleStep={onHandleStep}
                        getDates={getDates}
                    />
                    :
                    <BottomSection
                        orderDate={orderDate}
                        paymentType={paymentType}
                    />
            }
        </>
    );
}

export default Checkout;