import { createSlice } from '@reduxjs/toolkit'


export const Cards = createSlice({
    name: 'cards',
    initialState : {
        list : [],
    },
    reducers: {
        setList( state , action ) {
            state.list = action.payload;
        },
        setNewList ( state , action ) {
            state.list = [
                ...state.list,
                { ...action?.payload }
            ];
        },
        removeList( state , action ){
            state.list = state?.list?.filter((val)=> parseInt(val?.id) !== parseInt(action?.payload));
        },
    },
})

// Action creators are generated for each case reducer function
export const { setList , setNewList , removeList } = Cards.actions

export default Cards.reducer