import React, {useEffect, useState} from "react";
import { FilterImg } from '../../constant/Index';
import { SHOP } from "../../routes/RouteLinks";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {openSearchBar} from "../../constant/helpers";
import {downloadProductsApi} from "../../Netwrok/api";
import {getFilterPageRequest} from "../../redux/Products/Action";
import {setLoader} from "../../redux/Loader/Reducer";

const Filter = (props) => {

    const navigation = useNavigate();
    const category = useSelector(state => state?.product?.category);
    const [value, setValue] = useState('');
    const [sorting, setSorting] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const onSubmitHandler = (e) => {
        e.preventDefault();
        props.get(null, value, categoryId,sorting);
    }


    return (
        <>
            {/* Filter Start Here */}
            <div className="shop-list">
                <div className="list-heading-shop">
                    <h2>Cookies</h2>
                    <div className="header-search-wrapper">
                        <span className="search-main" onClick={()=>openSearchBar('open')}>
                            <i className="fa-sharp fa fa-search fa-solid fa-magnifying-glass"></i>
                        </span>
                    </div>
                </div>

                <h5 className="by-wrapper">By the Dozen</h5>
                <div className="filter-shop">
                    <div className="filter-wrapper">
                        <h4>Filters</h4>
                        <img src={FilterImg} className="img-fluid" alt="" />
                    </div>

                    <form onSubmit={onSubmitHandler}>
                        <div className="multiselect">
                            <div className="selectBox"
                                data-toggle="collapse"
                                href="#filterSelection"
                                role="button"
                                aria-expanded="false"
                                aria-controls="filterSelection">
                                <select>
                                    <option>Cookies by the dozen</option>
                                </select>
                                <div className="overSelect"></div>
                            </div>

                            <div id="filterSelection" className={"collapse show"}>
                                <label>
                                    <input type="radio" className="radio-inline"
                                        onClick={(e) => {
                                            setValue("");
                                            setCategoryId('');
                                        }}
                                        defaultChecked={(value === "") ? true : false}
                                        name="filter" value="" />
                                    <span className="changeColor">All</span>
                                </label>
                                <label>
                                    <input type="radio" className="radio-inline"
                                        onClick={(e) => {
                                            setValue("alphabetical");
                                            setCategoryId('');
                                        }}
                                        defaultChecked={(value === "alphabetical") ? true : false}
                                        name="filter" value="alphabetical" />
                                    <span className="changeColor">
                                        Alphabetical (A-Z)
                                    </span>
                                </label>

                                <label>
                                    <input type="radio" className="radio-inline"
                                        onClick={(e) => {
                                            setValue("best_sellers");
                                            setCategoryId('');
                                        }}
                                        defaultChecked={(value === "best_sellers") ? true : false}
                                        name="filter" value="best_sellers" />
                                    <span className="changeColor">
                                        Best Sellers
                                    </span>
                                </label>

                                {
                                    category?.filter((val)=>val?.slug !== "cookies")?.map((val, key) => (
                                        <label key={key}>
                                            <input type="radio" className="radio-inline"
                                                   onClick={(e) => {
                                                       setCategoryId(val?.slug);
                                                       setValue('');
                                                   }}
                                                   id={'category'}
                                                   defaultChecked={(categoryId === val?.slug) ? true : false}
                                                   name="filter" value={val?.slug} />
                                            <span className="changeColor">
                                                {val?.name}
                                            </span>
                                        </label>
                                    ))
                                }

                            </div>
                        </div>

                        <div className="multiselect d-none">
                            <div className="selectBox"
                                 data-toggle="collapse"
                                 href="#sortFilterSelection"
                                 role="button"
                                 aria-expanded="false"
                                 aria-controls="sortFilterSelection">
                                <select>
                                    <option>Sort by</option>
                                </select>
                                <div className="overSelect"></div>
                            </div>

                            <div id="sortFilterSelection" className={"collapse show"}>
                                <label>
                                    <input type="radio" className="radio-inline"
                                           onClick={(e) => {
                                               setSorting("high-to-low");
                                           }}
                                           defaultChecked={(value === "high-to-low") ? true : false}
                                           name="sorting" value="high-to-low" />
                                    <span className="changeColor">High to low</span>
                                </label>
                                <label>
                                    <input type="radio" className="radio-inline"
                                           onClick={(e) => {
                                               setSorting("low-to-high");
                                           }}
                                           defaultChecked={(value === "low-to-high") ? true : false}
                                           name="sorting" value="low-to-high" />
                                    <span className="changeColor">
                                        Low to high
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="multiselect d-none">
                            <div className="selectBox"
                                data-toggle="collapse"
                                href="#categoryFilterSelection"
                                role="button"
                                aria-expanded="false"
                                aria-controls="categoryFilterSelection">
                                <select>
                                    <option>Filter by category</option>
                                </select>
                                <div className="overSelect"></div>
                            </div>
                            <div id="categoryFilterSelection" className={"collapse show"}>
                                {/*<label>*/}
                                {/*    <input type="radio" className="radio-inline"*/}
                                {/*        onClick={(e) => {*/}
                                {/*            setCategoryId("");*/}
                                {/*        }}*/}
                                {/*        defaultChecked={(value === "") ? true : false}*/}
                                {/*        name="category" value="" />*/}
                                {/*    <span className="changeColor">All</span>*/}
                                {/*</label>*/}
                                {
                                    category?.filter((val)=>val?.slug !== "cookies")?.map((val, key) => (
                                        <label key={key}>
                                            <input type="radio" className="radio-inline"
                                                onClick={(e) => {
                                                    setCategoryId(val?.slug);
                                                }}
                                                defaultChecked={(categoryId === val?.slug) ? true : false}
                                                name="category" value={val?.slug} />
                                            <span className="changeColor">
                                                {val?.name}
                                            </span>
                                        </label>
                                    ))
                                }

                            </div>
                        </div>
                        <button type={"submit"} className="btn11 text-center">Filter</button>
                    </form>
                </div>
                <a href={downloadProductsApi} className={""} target="_blank">
                    <button className="btn11 download-btn text-center">
                        <i className="fa fa-solid fa-download"></i>
                        Download Product List
                    </button>
                </a>
            </div>
            {/* Filter End Here */}
        </>
    );
};

export default Filter;
