import React, {useEffect, useState} from "react";
import "../../assets/css/review.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { useDispatch } from "react-redux";
import {removeAll} from "../../redux/Cart/Reducer";
import { Link } from "react-router-dom/dist/umd/react-router-dom.development";

const Index = () => {

	const dispatch = useDispatch();
	const [message , setMessage] = useState('');

	useEffect(()=>{
		window.scrollTo(0, 0);
	},[])


	return (
		<>

			{/* InnerBanner Start Here */}
			<InnerBanner PageName="404 Not Found" />
			{/* InnerBanner End Here */}

			<section className="review-sec mt-5 mb-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 mx-auto">
							<div className="thanks-wrapper post-reviews not__found_wrapp">
								<h4>404</h4>
								<p>Page not found. Sorry, the requested page doesn't exist.</p>
								<Link to="/">Back to Homepage</Link>
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	);
};

export default Index;
