import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filePreviewer } from "../../constant/helpers";
import { getTeamRequest } from "../../redux/Setting/Action";

const Team = () => {
	const dispatch = useDispatch();
	const team = useSelector(state=> state?.setting?.teams);
	// console.log("team",team);
	const get= async()=>{
		await dispatch(getTeamRequest());
	}

	useEffect(() => {
		get();
	},[])

	return (
		<>
			<section className="about-third-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="meet-heading wow animate__animated animate__fadeInDown">
								<h4>Meet the Team</h4>
								<p>Meet Our team of volunteers(non paid employees)</p>
							</div>
						</div>
					</div>
					<div className="row">
						{team?.map((data, index) => (
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={index}>
								<div className="meet-img-wrapper wow animate__animated animate__flipInX">
									<figure>
										<img
											src={filePreviewer(data?.image)}
											className="img-fluid wow animate__animated animate__flipInY"
											alt=""
										/>
										<h4 className="meet-img-heading-both">{data?.name}</h4>
									</figure>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default Team;
