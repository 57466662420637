import React, { useEffect, useMemo, useState } from "react";
import { onInputChangeHandler } from "../../constant/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
	changePasswordRequest,
	updateProfileRequest,
} from "../../redux/Auth/Action";
import { getOrdersRequest } from "../../redux/CheckOut/Action";
import { ORDER_DETAIL, ORDERDETAIL } from "../../routes/RouteLinks";
import { useNavigate } from "react-router-dom";

const Orders = () => {
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const orders = useSelector((state) => state?.orders?.orders);
	const [loading, setLoading] = useState(false);

	const get = async () => {
		setLoading(true);
		await dispatch(getOrdersRequest());
		setLoading(false);
	};

	useEffect(() => {
		get();
	}, []);

	return (
		<>
			<section className="main-sec main-res">
				<div className="container-fluid p-lg-0 m-0">
					<div className="row">
						<div className="col-lg-12">
							<div className="main-top ">
								<h2>My Orders</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div>
				<div className="row">
					<div className="col-lg-12 p-lg-0">
						<div className="table-wrapper">
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th scope="col">
												<span>Order ID</span>
											</th>
											<th scope="col">
												<span>Price</span>
											</th>
											<th scope="col">
												<span>Date</span>
											</th>
											<th scope="col">
												<span>Order Status</span>
											</th>
											<th scope="col">
												<span>Payment Status</span>
											</th>
											<th scope="col">
												<span>Action</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{orders?.map((val, key) => (
											<tr key={key}>
												<td>
													<span>
														{" "}
														<span>
															{" "}
															ID #{val?.id?.toString()?.padStart(4, "0")}
														</span>
													</span>
												</td>
												<td>${parseFloat(val?.order_amount)?.toFixed(2)}</td>
												<td>{val?.created_at}</td>
												<td>
													<button
														type={"submit"}
														className={`btntable ${(() => {
															if (val?.order_status === "delivered") {
																return "btn-card-pending1";
															} else if (val?.order_status === "in_progress") {
																return "btn-card-pending2";
															} else {
																return "btn-card-pending";
															}
														})()}`}
													>
														<a>
															{(() => {
																if (val?.order_status === "delivered") {
																	return "Delivered";
																} else if (
																	val?.order_status === "in_progress"
																) {
																	return "In-Progress";
																} else {
																	return "Pending";
																}
															})()}
														</a>
													</button>
												</td>
												<td>
													<button
														type={"submit"}
														className={`btntable ${
															val?.payment_status === "paid"
																? "btn-card-pending1"
																: "btn-card-pending"
														}`}
													>
														<a>{val?.payment_status}</a>
													</button>
												</td>
												<td>
													<button
														onClick={() =>
															navigation(ORDERDETAIL + `/${val?.id}`)
														}
														className="btntable"
													>
														<a>View Detail</a>
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Orders;
