import React from "react";
import {cookies, rib, ribion , shopgroup3} from "../../constant/Index";


const FaqList = ({ faqs }) => {

    return (
        <section className="support-sec">
            <div className="side-image-1-row">
                <img src={cookies} className="img-fluid cookies" alt="" />
                    <img src={rib} className="img-fluid rib" alt="" />
                        <img src={ribion} className="img-fluid link" alt="" />
                            <img src={shopgroup3} className="img-fluid links" alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mx-auto px-lg-1 px-0">
                        <div className="faq-heading">
                            <h4>Here are some of the generally asked questions. Let us know if you don’t find what you
                                are
                                looking for; we’d love to resolve your questions.</h4>
                        </div>
                        <div className="accordions">
                            <div id="accordion">
                                {
                                    faqs?.map((val,key)=>(
                                        <div key={key} className="">
                                            <div className="card">
                                                <div className="card-header">
                                                    <a className="card-link collapsed" data-toggle="collapse"
                                                       href={`#collapse${val?.id}`}
                                                       aria-expanded="false">
                                                        <span>{val?.question}</span>
                                                        <i className="fa fa-plus"></i>
                                                    </a>
                                                </div>
                                                <div id={`collapse${val?.id}`} className="collapse" data-parent="#accordion"
                                                     aria-expanded="false">
                                                    <div className="card-body">
                                                        <p>{val?.answer}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
)
}

export default FaqList;