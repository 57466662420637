import React, { useState } from "react";
import "../../assets/css/contact-us.css";
import {
	onDirectChangeHandler,
	onInputChangeHandler,
} from "../../constant/helpers";
import { useDispatch } from "react-redux";
import { contactUsRequest } from "../../redux/ContactUs/Action";
// import 'react-phone-number-input/style.css';
// import Input from 'react-phone-number-input/input';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { CustomPhoneNumber } from "../../components/FormComponent";

const ContactForm = () => {
	const [loading, setLoading] = useState(false);
	const initial = {
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		message: "",
	};
	const dispatch = useDispatch();
	const [form, setForm] = useState({ ...initial });

	const onSubmitHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		let x = await dispatch(contactUsRequest(form));
		setLoading(false);
		if (x.payload) {
			window.scrollTo(0, 0);
			setForm({ ...initial });
		}
	};

	return (
		<div className="form-wrapper">
			<form onSubmit={onSubmitHandler}>
				<div className="row">
					<div className="col-lg-6">
						<div className="form-group">
							<label htmlFor="exampleFormControlInput1">First Name</label>
							<input
								type="text"
								name={"first_name"}
								required={true}
								className="form-control"
								value={form?.first_name}
								id="exampleFormControlInput1"
								onChange={(e) => onInputChangeHandler(e, setForm)}
								placeholder="Enter Name Here"
							/>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="form-group">
							<label htmlFor="exampleFormControlInput1">Last Name</label>
							<input
								type="text"
								name={"last_name"}
								required={true}
								className="form-control"
								id="exampleFormControlInput1"
								value={form?.last_name}
								onChange={(e) => onInputChangeHandler(e, setForm)}
								placeholder="Last Name Here"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="form-group">
							<label htmlFor="exampleFormControlInput1">Email</label>
							<input
								type="email"
								className="form-control"
								name={"email"}
								required={true}
								id="exampleFormControlInput1"
								value={form?.email}
								onChange={(e) => onInputChangeHandler(e, setForm)}
								placeholder="Enter Email Here"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="form-group">
							<label htmlFor="phone">Phone</label>
							{/* <div className={"form-group"}> */}
							{/*<Input*/}
							{/*	international*/}
							{/*	country="US"*/}
							{/*	className={"form-control"}*/}
							{/*	required={true}*/}
							{/*	style={{width:'100%'}}*/}
							{/*	placeholder="Your Phone Here"*/}
							{/*	value={form?.phone}*/}
							{/*	onChange={(e)=>{*/}
							{/*		if(e.length <= 12 ){*/}
							{/*			console.log("in",e);*/}
							{/*			onDirectChangeHandler('phone',e,setForm)*/}
							{/*		}else{*/}
							{/*			onDirectChangeHandler('phone',form?.phone,setForm)*/}
							{/*		}*/}
							{/*	}}/>*/}
							{/*<PhoneInput*/}
							{/*	// disableCountryCode={true}*/}
							{/*	// enableAreaCodes={false}*/}
							{/*	className="form-control"*/}
							{/*	// disableDropdown={true}*/}
							{/*	showDropdown={true}*/}
							{/*	onlyCountries={['us']}*/}
							{/*	masks={{at: '(....) ...-....'}}*/}
							{/*/>*/}
							<CustomPhoneNumber
								className={"form-control"}
								value={form?.phone}
								onChange={(e) => {
									onDirectChangeHandler("phone", e.target.value, setForm);
								}}
							/>
							{/* </div> */}
							{/*<input type="tel" className="for	m-control"*/}
							{/*	   name={'phone'} required={true}*/}
							{/*	   id="phone" value={form?.phone}*/}
							{/*	   onChange={(e)=>onInputChangeHandler(e,setForm)}*/}
							{/*	   placeholder="Your Phone Here"/>*/}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="form-group">
							<label htmlFor="message">Message</label>
							<textarea
								className="form-control textarea"
								name={"message"}
								value={form?.message}
								placeholder="Enter Message here"
								id="message"
								rows="3"
								onChange={(e) => onInputChangeHandler(e, setForm)}
							></textarea>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<button type={"submit"} className="btn btn1" disabled={loading}>
							{loading ? <i className={"fa fa-spin fa-spinner"}></i> : "Submit"}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
