import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCookiesRequest} from "../../redux/ChocolateAndPretzels/action";
import "../../assets/css/chocolate-product-detail.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {filePreviewer, onInputChangeHandler} from "../../constant/helpers";
import {setError} from "../../redux/Errors/Reducer";
import {
    addNewItem,
    removeItem,
    removePlattersItem,
} from "../../redux/Cart/Reducer";
import {
    getProductsDataRequest,
    getProductsRequest,
} from "../../redux/Products/Action";
import {productsApi} from "../../Netwrok/api";
import {setLoader} from "../../redux/Loader/Reducer";
import {useNavigate} from "react-router-dom";
import {SHOP} from "../../routes/RouteLinks";

const Cookies = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = useSelector((state) => state?.pretzels?.cookies);
    const flavours = useSelector((state) => state?.product?.data);
    const [size, setSize] = useState(0);
    const [sizeData, setSizeData] = useState(null);
    const [flavors, setFlavors] = useState([]);
    const [type, setType] = useState("");
    // const total = flavours?.reduce((total, item) => parseInt(total) + ( parseInt(item?.qty) * parseInt(item?.per_qty || 0 ) ), 0);
    const cart = useSelector((state) => state?.cart?.items);
    const [checkbox, setCheckbox] = useState(false);
    const [note, setNote] = useState('');

    const get = async () => {
        // setLoading(true);
        dispatch(setLoader(true));
        await dispatch(getCookiesRequest());
        await dispatch(getProductsDataRequest());
        await dispatch(getProductsRequest());
        dispatch(setLoader(false));
        // setLoading(false);
    };

    const onSelectFlavour = (id, key) => {
        if (id !== "") {
            setFlavors((prevState) => {
                return [
                    ...prevState.filter((value) => value?.key !== parseInt(key)),
                    {key: key, id: id},
                ];
            });
        }
    };

    const addToCart = () => {
        if (
            sizeData &&
            type !== 0 &&
            size !== 0 &&
            (
                cookies?.types?.find((val) => val?.id === type)?.type === 1?
                        checkbox? ( (note !== '')? true : false) : flavors.length >= parseInt(sizeData?.qty_in_dozen)
                    :
                        true
            )
        ) {
            let cartItem = cart?.find(
                (val) => parseInt(val?.id) === parseInt(cookies?.id),
            );
            if (cartItem) {
                // dispatch(
                //     removePlattersItem({
                //         id: cookies?.id,
                //         product_type: cookies?.product_type,
                //     }),
                // );
            }
            dispatch(
                addNewItem({
                    ...cookies,
                    title:
                    cookies?.types?.find((value) => value?.id === type)?.name || sizeData?.name,
                    sizeData : sizeData,
                    price: sizeData?.price,
                    type: type,
                    size: size,
                    flavours: flavors,
                    checkbox : checkbox,
                    note : checkbox ? note : ''
                }),
            );
            dispatch(
                setError([
                    {
                        message: "Successfully Added to Cart!",
                        color: "success",
                    },
                ]),
            );
        }
        else{
            if(checkbox && note === "") dispatch(
                setError([
                    {
                        message: "Note is required!",
                        color: "warning",
                    },
                ]),
            );
            // console.log("else",note,checkbox);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        get();
    }, []);

    return (
        <>
            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Product Details"/>
            {/* InnerBanner End Here */}

            <section className="testimoniel">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6">
                            <div className="row reverse-product">
                                <div className="col-lg-10 col-md-10 main-container">
                                    <div className="slider slider-main">
                                        <img
                                            src={filePreviewer(cookies?.image)}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="main-slider-cookies">
                                <div className="cookies-slider-heading-wrapper">
                                    <div className="cookies-heading1">
                                        <h2>{cookies?.title}</h2>
                                    </div>
                                    <div className="dollar-cookies">
                                        <h2 className="dollar-wrapper">
											<span>
												{cookies?.types
                                                    ?.find((val) => val?.id === type)
                                                    ?.sizes?.filter((val) => val?.id === size)
                                                    ?.map((val, key) => (
                                                        <React.Fragment key={key}>
                                                            ${val?.price}
                                                        </React.Fragment>
                                                    ))}
                                                {/*<small className="text-sm">(each)</small>*/}
                                                {/*<i className="fa fa-sharp fa-solid fa-heart"></i>*/}
											</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="description">
                                    <h4>Description:</h4>
                                    <p>{cookies?.description}</p>
                                </div>

                                {/*<div className="out-of-stock-wrapper">*/}
                                {/*  <ul>*/}
                                {/*    <li>*/}
                                {/*      <span>Stock :</span> <span>100</span>*/}
                                {/*    </li>*/}
                                {/*  </ul>*/}
                                {/*</div>*/}

                                <h5 className="mt-4">Select Type</h5>
                                <div className="d-flex gap-3 align-items-center">
                                    {cookies?.types?.map((val, key) => (
                                        <b key={key}>
                                            <label htmlFor={`type${val?.id}`} className="sorted mx-1">
                                                <input
                                                    type="radio"
                                                    name="type"
                                                    id={`type${val?.id}`}
                                                    value={val?.id}
                                                    className="checkedradio mx-1"
                                                    checked={type === parseInt(val?.id) ? true : false}
                                                    onClick={(e) => {
                                                        setType(parseInt(e.target?.value));
                                                    }}
                                                    style={{position: "unset"}}
                                                />
                                                <span>{val?.name}</span>
                                            </label>
                                        </b>
                                    ))}
                                </div>

                                {type && (
                                    <>
                                        <h5 className="mt-4 tohideheading1">Select Size</h5>
                                        <div className="assorted">
                                            {cookies?.types
                                                ?.find((val) => val?.id === type)
                                                ?.sizes?.map((val, key) => (
                                                    <React.Fragment key={key}>
                                                        <label
                                                            htmlFor={`size${val?.id}`}
                                                            className="d-block mt-2"
                                                        >
                                                            <input
                                                                type="radio"
                                                                name={"size"}
                                                                defaultChecked={
                                                                    size === parseInt(val?.id) ? true : false
                                                                }
                                                                // checked={ ( size === parseInt(val?.id) ) ? true : false}
                                                                onClick={(e) => {
                                                                    setSize(parseInt(e.target?.value));
                                                                    setSizeData(val);
                                                                }}
                                                                id={`size${val?.id}`}
                                                                className="masoor"
                                                                value={val?.id}
                                                                style={{position: "unset"}}
                                                            />

                                                            <span
                                                                className="pl-2">{`${val?.name} (${val?.qty_in_dozen} dozen) $${val?.price}`}</span>
                                                        </label>
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    </>
                                )}

                                <div className="row pt-4 pt-lg-0">
                                    <div className="col-lg-12  pe-lg-0 pr-lg-0">
                                        {cookies?.types?.find((val) => val?.id === type)?.type ==
                                            "1" &&
                                            sizeData && (
                                                <>
                                                    <h5 className="tohideheading">Select Flavors</h5>

                                                    <div className={"my-2"}>
                                                        <div className="form-group d-flex align-items-center">
                                                            <input
                                                                onClick={(e)=>{
                                                                    setCheckbox(e.target.checked);
                                                                }}
                                                                checked={checkbox}
                                                                defaultChecked={checkbox}
                                                                type="checkbox"
                                                                name="note"
                                                                className={"mr-2"}
                                                                id="note_box"
                                                            />
                                                            <label htmlFor="note_box" className={"m-0"}>
                                                                See note below for special instruction
                                                                or choose cookies below.
                                                            </label>
                                                        </div>
                                                        {
                                                            checkbox &&
                                                            <textarea
                                                                placeholder={'See note below for special instruction or choose cookies below.'}
                                                                name="note"
                                                                required={true}
                                                                value={note}
                                                                defaultValue={note}
                                                                onChange={(e)=>setNote(e.target.value)}
                                                                className={" form-control"}
                                                            ></textarea>
                                                        }
                                                    </div>

                                                    { !checkbox &&  [...Array(parseInt(sizeData?.qty_in_dozen))].map(
                                                        (val, key) => {
                                                            return (
                                                                <div className={"form-group"} key={key}>
                                                                    <label htmlFor="">
                                                                        Flavors for dozen {++key}
                                                                    </label>
                                                                    <select
                                                                        onChange={(e) =>
                                                                            onSelectFlavour(e.target.value, key)
                                                                        }
                                                                        className="form-control"
                                                                    >
                                                                        <option value=""> Select Flavor</option>
                                                                        {flavours?.map((val, key) => (
                                                                            <option key={key} value={val?.id}>
                                                                                {val?.title}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                </>
                                            )}
                                    </div>
                                </div>

                                <div className="duel-btns mt-5">
                                    {sizeData &&
                                        type !== 0 &&
                                        size !== 0 &&
                                        (cookies?.types?.find((val) => val?.id === type)?.type === 1
                                            ?
                                            (checkbox)? true : flavors.length >= parseInt(sizeData?.qty_in_dozen)
                                            : true) && (
                                            <>
                                                <button
                                                    type={"button"}
                                                    onClick={addToCart}
                                                    className="btn2"
                                                >
                                                    <i className="fa-solid fa-cart-shopping"></i>
                                                    Add to cart
                                                </button>
                                                <button onClick={() => navigate(SHOP)} className="btn2">
                                                    <i className="fa-solid fa-cart-shopping"></i>
                                                    Continue Shopping
                                                </button>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Cookies;
