import React, {useState} from 'react';
import Select from "react-select/base";

const MultiSelect = (props) => {

    const [menu , setMenu] = useState(false);
    const [input , setInput] = useState('');

    return (
        <Select
                {...props}
                onMenuClose={()=>setMenu(false)}
                inputValue={input}
                onInputChange={(val)=>setInput(val)}
                menuIsOpen={menu}
                onMenuOpen={()=>setMenu(true)}
        />
    )
}

export default MultiSelect;