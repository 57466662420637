import React, {useEffect, useState} from "react";
import ContactSection from "./ContactSection";
import {cookies, Rib, ribion, shopgroup3} from "../../constant/Index";
import ContactForm from "./ContactForm";

const BottomSection = () => {

    return (
        <>
            <section className="contact-us-main-page main-contact">
                <div className="side-image-1-row">
                    <img src={cookies} className="img-fluid cookies" alt=""/>
                    <img src={Rib} className="img-fluid rib" alt=""/>
                    <img src={ribion} className="img-fluid link" alt=""/>
                    <img src={shopgroup3} className="img-fluid links" alt=""/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <ContactForm />
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1821.5545926667503!2d-82.87978552508156!3d40.034721940800004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883861bb91f485ef%3A0x8095c24e5387b4b8!2s625%20Laurel%20Ridge%20Dr%2C%20Columbus%2C%20OH%2043230%2C%20USA!5e0!3m2!1sen!2s!4v1675327924817!5m2!1sen!2s"
                                    width="100%" height="504" borderRadius="50px" frameBorder="0" style={{
                                    border: 0
                                }}></iframe>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <ContactSection/>
        </>
    );
};

export default BottomSection;
