import React , { useEffect } from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {getFaqsRequest} from "../../redux/Faqs/Action";
import FaqList from "./FaqList";
import '../../assets/css/faqs.css';
import {setLoader} from "../../redux/Loader/Reducer";


const Faqs = ( ) => {

    const dispatch = useDispatch();
    const faqs = useSelector(state => state?.faqs?.faqs);

    const get = async () => {
        dispatch(setLoader(true));
        await dispatch(getFaqsRequest());
        window.scrollTo(0, 0);
        dispatch(setLoader(false));
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        get();
    },[])

    return (
        <>
            {/* Banner Start Here */}
            <InnerBanner PageName={'Faqs'}/>
            {/* Banner End Here */}

            <FaqList faqs={faqs} />
        </>
    )
}

export default Faqs;