import React from 'react';
import Package from "./Package";

const BottomSection = ({ list }) => {

    return (
        <section className="care-pakage">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h4 className="holiday"
                        // data-aos="fade-down"
                        // data-aos-anchor-placement="center-center"
                        // data-aos-duration="3000"
                        >Holiday and other special occasion themed baskets with cookies,
                            treats and
                            other special items.
                        </h4>
                    </div>
                </div>
                <div className="row">
                    {
                        list?.map((val, key) => (
                            <Package key={key} item={val} />
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default BottomSection;