import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {checkOutApi, newsletterApi, orderDetailApi, ordersApi, promoCodeApi} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {headers} from "../headers";
import {setOrder, setOrders} from "./Reducer";
import {logout} from "../Auth/Reducer";


export const sendPromoCodeRequest = createAsyncThunk('promo-code-request',async (
    data,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let x = await axios.post(promoCodeApi,data,headers());
        dispatch( setError([{
            message : 'Promo code is applied' , color : 'success'
        }] ));
        return fulfillWithValue(x.data?.response?.data);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const checkOutRequest = createAsyncThunk('check-out-request',async (
    data,
    {
        dispatch,getState,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let x = await axios.post(checkOutApi,data,headers(token));
        dispatch( setError([{
            message : 'Order Place Successfully' , color : 'success'
        }] ));
        // console.log("dsd",x.data?.response);
        return fulfillWithValue(x.data.response.data);
        // return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});


export const getOrdersRequest = createAsyncThunk('get-orders-request',async (
    data = null,
    {
        dispatch, getState,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let x = await axios.get(ordersApi,headers(token));
        dispatch(setOrders(x.data?.response?.data));
        // console.log("Orders",x.data?.response?.data);
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        if(e.response.status === 401) dispatch(logout());
        return  rejectWithValue(false);
    }
});

export const getOrderDetailRequest = createAsyncThunk('get-order-request',async (
    id,
    {
        dispatch, getState,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let x = await axios.get(orderDetailApi(id),headers(token));
        dispatch(setOrder(x.data?.response?.data));
        // console.log("Orders",x.data?.response?.data);
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});