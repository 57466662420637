export const MODE =  'development'; //process.env.MODE;
export const APP_NAME = 'Cookies4ACure'; // process.env.APP_NAME;
export const API_URL = 'https://cookies4acure.developer-ourbase-camp.com/api/'; //process.env.API_URL;
export const StripePublishedKey = process.env.REACT_APP_STRIPE_PUBLISHED_KEY;//'pk_test_51Ln1dCFJJmnlZOnNXtsqSkIDa03oWjE5YMHEyBRHrFs3rlCgPBLH2vNZ9aHqsNFazd9fN2rPVebmWXcbDrZEFNFg00cgM98wJu';
export const StripeSecret = process.env.REACT_APP_STRIPE_SECRET_KEY;//'sk_test_51Ln1dCFJJmnlZOnNpdVWsI3JNlS9JuoIId5my7FA7NKJB0ZHAsWt15UNvRW4HEO1Aoo1zkITStmA4dIx2vteXRDi00G10fkFFx';

/*
    @Live Keys
pk_live_51MRMHiIXWtKnuIr3nM6QjT6JLVIIn9ZaxodkZ8p9AcLZsY2Jf9uNFwxBXaoE3l2qrpugY0s7LYPBO03FFv9J1eoA00cmqO78sz


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
 */