import React, { useEffect, useState } from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import '../../assets/css/shop.css';
import { useDispatch } from "react-redux";
import { getCategoryRequest, getProductsRequest } from "../../redux/Products/Action";
import BottomSection from "./BottomSection";
import { useLocation, useParams } from "react-router-dom";
import { productsApi } from "../../Netwrok/api";
import { setLoader } from "../../redux/Loader/Reducer";
import BottomSectionCookies from "./BottomSectionCookies";


const Shop = () => {

    const dispatch = useDispatch();
    const params = useLocation();
    const { category } = useParams();
    // console.log("Category",category);

    const getProducts = async (page = null, filter = null, category = null, sorting = null) => {
        // console.log(params);
        let query = "";
        query += (params?.search !== '') ? `${params?.search}&` : "?";
        query += (page) ? `page=${page}&` : '';
        query += (filter) ? `filter=${filter}&` : '';
        query += (category) ? `category=${category}&` : '';
        query += (sorting) ? `sorting=${sorting}&` : '';

        dispatch(setLoader(true));

        await dispatch(getProductsRequest(productsApi + query));
        window.scrollTo(0, 0);
        dispatch(setLoader(false));
    }
    const getCategory = async () => {
        await dispatch(getCategoryRequest());
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getProducts(null, null, ((category) ? category : null), null);
        getCategory();
        return () => { }
    }, [])

    useEffect(() => {
        if (params?.search !== "") {
            getProducts();
        }
    }, [params?.search])

    return (
        <>
            {/* InnerBanner Start */}
            <InnerBanner PageName={'COOKIES'} />
            {/* InnerBanner End */}

             <BottomSection get={getProducts} />

        </>
    );
};

export default Shop;
