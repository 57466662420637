import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {SHOP} from "../../routes/RouteLinks";

const SearchBar = (props) => {

    const [value , setValue] = useState('');
    const navigation = useNavigate();
    const onSubmitHandler = (e) => {
        e.preventDefault();
        navigation(SHOP+`?search=${value}`);
        props?.setSearch('');
        setValue('');
        document.querySelector('#search').classList.remove('open')
    }

    return (
        <div id="search" className={`input-erapper ${props?.search}`}>
            <span onClick={()=>document.querySelector('#search').classList.remove('open')} className="close">x</span>
            <form
                role="search"
                id="searchform"
                onSubmit={onSubmitHandler}
            >
                <input
                    value={value}
                    name="value"
                    type="text"
                    onChange={(e)=>setValue(e.target.value)}
                    placeholder="type to search"
                />
            </form>
        </div>
    );
}

export default SearchBar;