import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FooterLogo } from "../../constant/Index";
import $ from "jquery";
import {
  ABOUT,
  CARE_PACKAGE,
  CART,
  CHOCOLATE_PLATTERS,
  CONTACTUS,
  DASHBOARD,
  DONATE,
  EVENT,
  FILTER,
  HOME,
  LOGIN,
  WISHLIST,
} from "../../routes/RouteLinks";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../Search/index";
import axios from "axios";
import { logout } from "../../redux/Auth/Reducer";
import { openSearchBar } from "../../constant/helpers";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menu, setmenu] = useState(false);
  const [drop, setdrop] = useState(false);
  const [drop2, setdrop2] = useState(false);
  const cartItem = useSelector((state) => state?.cart?.items);
  const CurrentLocation = location.pathname;
  const handleMobileMenu = () => {
    setmenu(true);
  };
  const closeMobileMenu = () => {
    setmenu(false);
  };
  const handleDrop = () => {
    setdrop(true);
    setdrop2(false);
  };
  const handleDrop2 = () => {
    setdrop2(true);
    setdrop(false);
  };
  useEffect(() => {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 150) {
        $("header").addClass("dark-header");
      } else {
        $("header").removeClass("dark-header");
      }
    });
  }, []);

  const [search, setSearch] = useState("");
  const auth = useSelector((state) => state?.auth);

  return (
    <>
      <header
        id="header"
        className={CurrentLocation == "/" ? "homePage" : "innerPage"}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-5">
              <div className="logo-wrapper">
                <Link to={HOME}>
                  <img
                    width="150px"
                    height="150px"
                    src={FooterLogo}
                    className="img-fluid"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="menu-item-wrapper">
                <ul className="navbar-nav flex-row">
                  <li className="nav-item1 active ">
                    <Link
                      to={HOME}
                      className={
                        CurrentLocation == HOME ? "actives" : "nav-link"
                      }
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item1">
                    <Link
                      to={ABOUT}
                      className={
                        CurrentLocation == ABOUT ? "actives" : "nav-link"
                      }
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item2 dropdown">
                    <a
                      style={{
                        cursor: "pointer",
                      }}
                      className={
                        CurrentLocation == `/shop`
                          ? "nav-links1 actives dropdown-toggle"
                          : "nav-links1 dropdown-toggle"
                      }
                      // to="javascript:void(0)"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Shop
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item" to={FILTER}>
                        Cookies
                      </Link>
                      <Link className="dropdown-item" to={CHOCOLATE_PLATTERS}>
                        Chocolates and Pretzels
                      </Link>
                      <Link className="dropdown-item" to={EVENT}>
                        Special Events
                      </Link>
                      <Link className="dropdown-item" to={CARE_PACKAGE}>
                        Care Packages
                      </Link>
                      {/*<Link className="dropdown-item" to="/other">*/}
                      {/*	other*/}
                      {/*</Link>*/}
                    </div>
                  </li>
                  <li className="nav-item1">
                    <Link to={DONATE} className="nav-link">
                      Donate
                    </Link>
                  </li>
                  <li className="nav-item1">
                    <Link to={CONTACTUS} className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="header-icon-wrapper">
                <ul className="d-flex">
                  <li className="icon-item">
                    <a
                      className="modal-serch-wrapper"
                      onClick={
                        () => openSearchBar("open")
                        // setSearch('open')
                      }
                    >
                      <i className="fa fa-search"></i>
                    </a>
                  </li>
                  <li className="icon-item">
                    <Link to={auth?.isLogin ? WISHLIST : LOGIN}>
                      <i className="fa fa-heart"></i>
                    </Link>
                  </li>
                  <li className="icon-item cart-icon">
                    <Link to={CART}>
                      <i className="fa fa-shopping-cart"></i>
                    </Link>
                    <span className="cart-number">{cartItem?.length}</span>
                  </li>
                  <li className="icon-item">
                    {auth?.isLogin ? (
                      <li>
                        <Link to={DASHBOARD} className="btn">
                          Profile
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link to={LOGIN} className="btn">
                          Login
                        </Link>
                      </li>
                    )}
                  </li>
                  <SearchBar search={search} setSearch={setSearch} />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="navigation navigation-bar-wrapper">
          <div className="nav-container">
            <div className="brand">
              <Link to="#!">Logo</Link>
            </div>
            <nav>
              <div className="nav-mobile">
                <Link id="navbar-toggle" onClick={handleMobileMenu} to="#">
                  <span></span>
                </Link>
              </div>
              <div className={menu ? "mobile-menu show" : "mobile-menu hide"}>
                <div
                  style={{ opacity: "1" }}
                  className="close w-100 px-3 position-relative d-flex justify-content-between"
                >
                  <Link to={HOME}>
                    <img
                      width="150px"
                      height="150px"
                      src={FooterLogo}
                      className="img-fluid off-canvas-logo"
                      alt=""
                    />
                  </Link>
                  <button onClick={closeMobileMenu}>
                    <i className="fa fa-close"></i>
                  </button>
                </div>
                <ul className={menu == true ? "nav-list d-block" : "nav-list"}>
                  <li>
                    <a
                      onClick={() => {
                        closeMobileMenu();
                        navigate(HOME);
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        closeMobileMenu();
                        navigate(ABOUT);
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    {/* <Link to="javascript:void(0)" onClick={handleDrop}>
											Shop
										</Link> */}
                    <div className="accordion" id="accordionExample">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Shop
                            </button>
                          </h2>
                        </div>

                        <div
                          id="collapseOne"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <ul>
                              <li>
                                {/* <Link to={SHOP}>Cookies</Link> */}
                                <a
                                  onClick={() => {
                                    closeMobileMenu();
                                    setdrop(false);
                                    navigate(FILTER);
                                  }}
                                >
                                  Cookies
                                </a>
                              </li>
                              <li>
                                {/* <Link to="/chocolate-platters">
													Chocolates and Pretzels
												</Link> */}
                                <a
                                  onClick={() => {
                                    closeMobileMenu();
                                    navigate(CHOCOLATE_PLATTERS);
                                  }}
                                >
                                  Chocolates and Pretzels
                                </a>
                              </li>
                              <li>
                                {/* <Link to={EVENT}>Special Events</Link> */}
                                <a
                                  onClick={() => {
                                    closeMobileMenu();
                                    navigate(EVENT);
                                  }}
                                >
                                  Special Events
                                </a>
                              </li>
                              <li>
                                {/* <Link to={CARE_PACKAGE}>Care Packages</Link> */}
                                <a
                                  onClick={() => {
                                    closeMobileMenu();
                                    navigate(CARE_PACKAGE);
                                  }}
                                >
                                  Care Packages
                                </a>
                              </li>
                              {/*<li>*/}
                              {/*    <Link to="product-details.php">other</Link>*/}
                              {/*</li>*/}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <a onClick={handleDrop} id="shop">
                      Shop
                    </a> */}
                    {/* <ul
                      className={
                        drop ? "navbar-dropdown show" : "navbar-dropdown"
                      }
                    >
                      <li>
                        <a
                          onClick={() => {
                            closeMobileMenu();
                            setdrop(false);
                            navigate(SHOP);
                          }}
                        >
                          Cookies
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            closeMobileMenu();
                            navigate(CHOCOLATE_PLATTERS);
                          }}
                        >
                          Chocolates and Pretzels
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            closeMobileMenu();
                            navigate(EVENT);
                          }}
                        >
                          Special Events
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            closeMobileMenu();
                            navigate(CARE_PACKAGE);
                          }}
                        >
                          Care Packages
                        </a>
                      </li>
                    </ul> */}
                  </li>
                  <li>
                    {/* <Link to={DONATE}>Donate</Link> */}
                    <a
                      onClick={() => {
                        closeMobileMenu();
                        navigate(DONATE);
                      }}
                    >
                      Donate
                    </a>
                  </li>
                  <li>
                    {/* <Link to="/contact-us">Contact us</Link> */}
                    <a
                      onClick={() => {
                        closeMobileMenu();
                        navigate(CONTACTUS);
                      }}
                    >
                      Contact us
                    </a>
                  </li>
                  {auth?.isLogin ? (
                    <li>
                      <a
                        onClick={() => {
                          closeMobileMenu();
                          navigate(DASHBOARD);
                        }}
                      >
                        Profile
                      </a>
                      {/* <Link to={DASHBOARD} className="btn">
														Profile
													</Link> */}
                    </li>
                  ) : (
                    <li>
                      {/* <Link to={LOGIN} className="btn">
														Login
													</Link> */}
                      <a
                        onClick={() => {
                          closeMobileMenu();
                          navigate(LOGIN);
                        }}
                      >
                        Login
                      </a>
                    </li>
                  )}
                  <li>
                    {/* <Link to="javascript:void(0)" onClick={handleDrop2}>
											Shop
										</Link> */}
                    {/* <a onClick={handleDrop2}>Shop</a> */}
                    <ul
                      // className={
                      // 	drop2 ? "navbar-dropdown show" : "navbar-dropdown hide"
                      // }
                      className="icons"
                    >
                      <li>
                        <a
                          onClick={() => {
                            closeMobileMenu();
                            navigate("#search");
                          }}
                          className="modal-serch-wrapper"
                        >
                          <i className="fa fa-search"></i>
                        </a>
                        {/* <Link className="modal-serch-wrapper" to="#search">
													<i className="fa fa-search"></i>
												</Link> */}
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            closeMobileMenu();
                            navigate(auth?.isLogin ? WISHLIST : LOGIN);
                          }}
                        >
                          <i className="fa fa-heart"></i>
                        </a>
                        {/* <Link to={auth?.isLogin ? WISHLIST : LOGIN}>
													<i className="fa fa-heart"></i>
												</Link> */}
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            closeMobileMenu();
                            navigate(CART);
                          }}
                        >
                          <i className="fa fa-shopping-cart"></i>
                        </a>
                        {/* <Link to={CART}>
													<i className="fa fa-shopping-cart"></i>
												</Link> */}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
