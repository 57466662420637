import React, {useEffect} from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {useDispatch, useSelector} from "react-redux";
import {getDonatePageRequest} from "../../redux/Setting/Action";
import DonateSection from "./DonateSection";
import '../../assets/css/add-donate.css';
import {setLoader} from "../../redux/Loader/Reducer";

const Donate = () => {

    const dispatch = useDispatch();
    const discount = useSelector(state => state?.setting?.donate);
    // const reansons = useSelector(state => state?.setting?.donationReason);

    const get = async () => {
        dispatch(setLoader(true));
        await dispatch(getDonatePageRequest());
        window.scrollTo(0, 0);
        dispatch(setLoader(false));
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        get();
    },[])

    return (
        <>
            {/* Banner Start Here */}
            <InnerBanner PageName={'DONATE'}/>
            {/* Banner End Here */}
            <DonateSection  discountContent={discount}/>
        {/*reansons={reansons} discountContent={discount}*/}
        </>
    )
}

export default Donate;