import React, { useEffect, useState } from "react";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import '../../assets/css/shop.css';
import { useDispatch } from "react-redux";
import {getCategoryRequest, getFilterPageRequest, getProductsRequest} from "../../redux/Products/Action";
import BottomSection from "./BottomSection";
import { useLocation, useParams } from "react-router-dom";
import { productsApi } from "../../Netwrok/api";
import { setLoader } from "../../redux/Loader/Reducer";
import BottomSectionCookies from "./BottomSectionCookies";


const LandingPage = () => {

    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);

    const get = async () => {
        dispatch(setLoader(true));
        await dispatch(getFilterPageRequest())
            .then((res)=>{
                // console.log("res",res);
                if(res?.payload?.status) setResponse(res?.payload.response);
            }).catch((e)=>console.log(''));
        dispatch(setLoader(false));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        get();
        return () => { }
    }, [])


    return (
        <>
            {/* InnerBanner Start */}
            <InnerBanner PageName={'COOKIES'} />
            {/* InnerBanner End */}

            <BottomSectionCookies response={response}/>
        </>
    );
};

export default LandingPage;
