import React, {useEffect} from 'react';
import '../../assets/css/care-pakage.css';
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import BottomSection from "../../components/CarePackage/BottomSection";
import {useDispatch, useSelector} from "react-redux";
import {getPackagesRequest} from "../../redux/Packages/Action";
import {setLoader} from "../../redux/Loader/Reducer";

const CarePackage = () => {

    const dispatch = useDispatch();
    const {data} = useSelector(state => state?.package?.packages);

    const get = async () => {
        dispatch(setLoader(true));
        await dispatch(getPackagesRequest());
        window.scrollTo(0, 0);
        dispatch(setLoader(false));
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        get();
    },[])

    return (
        <>
            <InnerBanner PageName={'CARE PACKAGES'}  />
            <BottomSection list={data} />
        </>
    )
}

export default CarePackage;