import React from "react";
import '../../assets/css/shop.css';
import CookiesItem from "../../components/Shop/CookiesItem";
import {Product1, Product2} from "../../constant/Index"
import {CHOCOLATE_PLATTERS, COOKIES, SHOP} from "../../routes/RouteLinks";
import {filePreviewer} from "../../constant/helpers";

const BottomSectionCookies = (props) => {
    // console.log("BottomSectionCookies",props)

    let data = [{
        id: 0,
        image: Product1,
        slug: SHOP,
        title: 'Cookies by the Dozen',
    },
        {
            id: 1,
            image: Product2,
            slug: COOKIES,
            title: 'Cookie Platters',
        }]


    return (
        <section className="support-sec">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-12 card-inline">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-card-shop">
                                    <CookiesItem item={{
                                        image : (props?.response?.cookie_dozen_image)? filePreviewer(props?.response?.cookie_dozen_image) : Product1,
                                        title : props?.response?.cookie_dozen_title || 'Cookies by the Dozen',
                                        slug  : SHOP
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-card-shop">
                                    <CookiesItem item={{
                                        image : (props?.response?.cookie_platter_image)? filePreviewer(props?.response?.cookie_platter_image) : Product2,
                                        title : props?.response?.cookie_platter_title || 'Cookie Platters',
                                        slug  : COOKIES
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BottomSectionCookies;
