import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDonationsRequest } from "../../redux/Donation/Action";

const Donations = () => {
	const dispatch = useDispatch();
	const donations = useSelector((state) => state?.donations?.donations);
	const [loading, setLoading] = useState(false);

	const get = async () => {
		setLoading(true);
		await dispatch(getDonationsRequest());
		setLoading(false);
	};

	useEffect(() => {
		get();
	}, []);

	return (
		<>
			<section className="main-sec main-res">
				<div className="container-fluid p-lg-0 m-0">
					<div className="row">
						<div className="col-lg-12">
							<div className="main-top ">
								<h2>Donations</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div>
				<div className="row">
					<div className="col-lg-12 p-lg-0">
						<div className="table-wrapper">
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th scope="col">
												<span>Order ID</span>
											</th>
											{/*<th scope="col">PriceDonation Reason</th>*/}
											<th scope="col">
												<span>Date</span>
											</th>
											<th scope="col">
												<span>Status</span>
											</th>
											<th scope="col">
												<span>Amount</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{donations?.map((val, key) => (
											<tr key={key}>
												<td>
													<span>
														{" "}
														<span>
															{" "}
															ID #{val?.id?.toString()?.padStart(4, "0")}
														</span>
													</span>
												</td>
												{/*<td>{val?.donation_reason?.name || ''}</td>*/}
												<td style={{ textAlign: "center" }}>
													{val?.created_at}
												</td>
												<td>
													<button
														className={`btntable ${
															val?.payment_status === "paid"
																? "btn-card-pending1"
																: "btn-card-pending"
														}`}
													>
														<a>{val?.payment_status}</a>
													</button>
												</td>
												<td>${parseFloat(val?.total_amount)?.toFixed(2)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
						<div className="table-cost-total-erapper">
							<div className="donation">
								<h5>Total Donations</h5>
								<h5>
									${" "}
									{donations
										?.reduce(
											(total, item) => total + parseFloat(item?.total_amount),
											0,
										)
										?.toFixed(2)}
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Donations;
