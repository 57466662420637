import React, { useEffect, useState } from "react";
import "../../assets/css/shop.css";
import { useNavigate } from "react-router-dom";
import { SHOP_DETAIL } from "../../routes/RouteLinks";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../redux/Cart/Reducer";
import { filePreviewer } from "../../constant/helpers";
import { deleteWishListRequest } from "../../redux/Wishlist/Action";

const BottomSection = () => {
	const [adres, setadres] = useState(false);
	const navigate = useNavigate();
	const items = useSelector((state) => state?.wishlist?.list);
	const dispatch = useDispatch();
	useEffect(() => {
		if (window.innerWidth <= 480) {
			setadres(true);
		}
	}, []);
	return (
		<section className="whishlist-sec">
			<div className="container">
				<div className="row">
					<div className="col-lg-11  mx-auto">
						<div className={adres ? "table-wrapper lol" : "table-wrapper"}>
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th className="wishlist-no" scope="col">
												<span style={{ textAlign: "center" }}>S No</span>
											</th>
											<th className="wishlist-NAME" scope="col">
												<span>PRODUCT NAME</span>
											</th>
											<th className="wishlist-PRICE" scope="col">
												<span>UNIT PRICE</span>
											</th>
											<th className="wishlist-AVAILABILITY" scope="col">
												<span>AVAILABILITY</span>
											</th>
											<th className="wishlist-ACTION" scope="col">
												<span>ACTION</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{items?.map((val, index) => (
											<tr key={index}>
												<th scope="row">{++index}</th>
												<td>
													<div className="name-img-wrapper">
														<img
															src={filePreviewer(val?.product?.image)}
															width={"10%"}
															className="img-fluid"
															alt=""
														/>
														{/* <span> */}
														<span className="title-name">
															{val?.product?.title}
														</span>
														{/* </span> */}
													</div>
												</td>
												<td>${parseFloat(val?.product?.price)?.toFixed(2)}</td>
												<td>
													{val?.product?.stock_qty > 0
														? "IN STOCK"
														: "OUT STOCK"}
												</td>
												<td>
													<div className="d-flex align-items-center justify-content-center">
														<button
															type={"button"}
															onClick={() =>
																navigate(SHOP_DETAIL(val?.product?.slug))
															}
															className="btntable"
														>
															{/*<a href="">*/}
															View
															{/*</a>*/}
														</button>
														<a
															onClick={() =>
																dispatch(deleteWishListRequest(val?.id))
															}
															className="d-block ml-3"
														>
															<i className="fa fa-solid fa-close"></i>
														</a>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BottomSection;
