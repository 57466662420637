import React from "react";
import {CardElement} from "@stripe/react-stripe-js";

const CardHandler = () => {
    return <CardElement id="stripeCardHandler" options={
        {
            style: {
                base: {
                    color: "#6a6a6a",
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                    fontSmoothing: "antialiased",
                    "::placeholder": {
                        color: "#6a6a6a"
                    },
                },
                invalid: {
                    color: "#e5424d",
                    ":focus": {
                        color: "#303238"
                    }
                }
            }
        }
    }/>
}

export default CardHandler;