import React, {useEffect, useMemo, useState} from 'react';
import {onDirectChangeHandler, onInputChangeHandler} from "../../constant/helpers";
import {useDispatch, useSelector} from "react-redux";
import {changePasswordRequest, updateProfileRequest} from "../../redux/Auth/Action";
import {CustomPhoneNumber} from "../FormComponent";
// import Input from 'react-phone-number-input/input';
// import PhoneInput from "react-phone-input-2";
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';



const Profile = () => {

    const dispatch = useDispatch();
    const [edit , setEdit] = useState(true);
    const profile = useSelector(state => state?.auth?.user);
    const [loading , setLoading] = useState(false);
    const [form , setForm] = useState({
        first_name : '',
        last_name : '',
        email : '',
        contact_no : '',
    });

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        await dispatch(updateProfileRequest(form));
        setLoading(false);
        setEdit(true);
    }

    useEffect(()=>{
        setForm((prevState) => {
            return {
                first_name : profile?.first_name,
                last_name : profile?.last_name,
                email : profile?.email,
                contact_no : profile?.contact_no,
            }
        })
    },[])
    // console.log("Profi;le",form);



    return (
        <>
            <div className="form-tabs">
                <form onSubmit={onSubmitHandler}>
                    <div className="row align-items-start" style={{ height: '350px' }}>
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="first_name">First
                                            Name</label>
                                        <input type="text"
                                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                                               name={"first_name"} defaultValue={form?.first_name} required={true}
                                               className="form-control bg-white"
                                               id="first_name"
                                               readOnly={edit}
                                               placeholder="John"/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="last_name">Last
                                            Name</label>
                                        <input type="text"
                                               readOnly={edit}
                                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                                               name={"last_name"} defaultValue={form?.last_name} required={true}
                                               className="form-control bg-white"
                                               id="last_name"
                                               placeholder="Doe"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    edit &&
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="formGroupExampleInput">Email</label>
                                            <input type="text" readOnly={true} disabled={true}
                                                   value={form?.email} className="form-control bg-white"/>
                                            {/*<input type="email" */}
                                            {/*       defaultValue=*/}
                                            {/*       id="formGroupExampleInput"*/}
                                            {/*       placeholder="Johndoe@email.com"/>*/}
                                        </div>
                                    </div>
                                }
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="contact_no">Phone</label>
                                        {
                                            edit ?

                                                <input type="tel" className="form-control bg-white"
                                                       readOnly={edit}
                                                       onChange={(e) => onInputChangeHandler(e, setForm)}
                                                       name={"contact_no"} defaultValue={form?.contact_no}
                                                       required={true}
                                                       id="contact_no"
                                                       placeholder="000 123 456 789"/>
                                                :
                                                <div className={"form-group"}>
                                                    <CustomPhoneNumber
                                                        value={form?.contact_no}
                                                        onChange={(e)=>{
                                                            onDirectChangeHandler('contact_no',e.target.value,setForm)
                                                        }}
                                                    />
                                                    {/*<PhoneInput*/}
                                                    {/*    onlyCountries={['at']}*/}
                                                    {/*    masks={{at: '(....) ...-....'}}*/}
                                                    {/*/>*/}
                                                    {/*<Input*/}
                                                    {/*    style={{width:'100%'}}*/}
                                                    {/*    placeholder="+00 000 0000000"*/}
                                                    {/*    value={form?.contact_no}*/}
                                                    {/*    onChange={(e)=>onDirectChangeHandler('contact_no',e,setForm)}/>*/}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {
                                        edit?
                                            <button onClick={()=>setEdit(false)} type={"button"} className="btn13 btn-jquiry">
                                                Edit Profile
                                            </button>
                                            :
                                            <>
                                                <button onClick={()=>setEdit(true)} type={"button"} className="btn13 btn-jquiry">
                                                    Cancel
                                                </button>
                                                <button disabled={loading} type={"submit"} className="ml-1 btn13 btn-jquiry">
                                                    {
                                                        (loading)?
                                                            <i className={"fa fa-spin fa-spinner"}></i>
                                                            :
                                                            'Save Changes'
                                                    }
                                                </button>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Profile;