import React from "react";
import { cookies, ribion, rib, ring, link2, arrow_blog } from "../../constant/Index";
import { chunks, filePreviewer } from "../../constant/helpers";
import Item from "./Item";
import { Link } from "react-router-dom";
import { BLOG_DETAIL } from "../../routes/RouteLinks";

const ListSection = (props) => {
    // console.log("Blogs",props?.blogs?.filter((val, key) => key === 0));

    return (
        <section className="blogs-sec">
            <div className="side-image-1-row">
                <img src={cookies} className="img-fluid cookies" alt="" />
                <img src={rib} className="img-fluid rib" alt="" />
                <img src={ribion} className="img-fluid link" alt="" />
                <img src={ring} className="img-fluid links" alt="" />
                <img src={link2} className="img-fluid links1" alt="" />
            </div>
            <div className="container">
                <div className="blog-pages">
                    {
                        props?.blogs?.filter((val, key) => key === 0)?.map((val, index) => (
                            <div key={index} className="row">
                                <div className="col-lg-7">
                                    <div className="blogs-wrapper">
                                        <img src={filePreviewer(val?.banner_image)} className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="blogs-heading"
                                    // data-aos="fade-left"
                                    // data-aos-anchor-placement="center-center"
                                    // data-aos-duration="1000"
                                    >
                                        <h2>{val?.title}</h2>
                                        <p>{val?.sub_title}</p>
                                        <h4>
                                            <Link to={BLOG_DETAIL(val?.slug)}>
                                                Continue Reading
                                                <img src={arrow_blog} className="img-fluid" alt="" />
                                            </Link>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="main-blog-wrapper">
                    {
                        chunks(props?.blogs?.filter((val,key)=>key !== 0), 3)?.map((items, key) => (
                            <div key={key} className="row">
                                {
                                    items?.map((val, index) => (
                                        <Item item={val} key={index} />
                                    ))
                                }
                            </div>
                        ))
                    }
                    {/*<div className="d-flex justify-content-center mt-5">*/}
                    {/*    <Link to="" className="btn">View all</Link>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*	<div className="col-lg-4 col-md-6">*/}
                    {/*		<div className="blog-img" data-aos="flip-left" data-aos-easing="ease-out-cubic"*/}
                    {/*			 data-aos-duration="1500">*/}
                    {/*			<img src="./img/pics16.webp" className="img-fluid baby-wrapper" alt="">*/}
                    {/*				<h4>Lorem ipsum dolor sit amet, consectetur</h4>*/}
                    {/*				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                    {/*					sed do eiusmod tempor incididunt ut labore et.</p>*/}
                    {/*				<h4><a href="blog-detail.php">Continue Reading <img src="./img/arrow-blog.webp"*/}
                    {/*																	className="img-fluid"*/}
                    {/*																	alt=""></a>*/}
                    {/*				</h4>*/}
                    {/*		</div>*/}
                    {/*	</div>*/}
                    {/*	<div className="col-lg-4 col-md-6">*/}
                    {/*		<div className="blog-img" data-aos="flip-left" data-aos-easing="ease-out-cubic"*/}
                    {/*			 data-aos-duration="1500">*/}
                    {/*			<img src="./img/pic9.webp" className="img-fluid baby-wrapper" alt="">*/}
                    {/*				<h4>Lorem ipsum dolor sit amet, consectetur</h4>*/}
                    {/*				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                    {/*					sed do eiusmod tempor incididunt ut labore et.</p>*/}
                    {/*				<h4><a href="blog-detail.php">Continue Reading <img src="./img/arrow-blog.webp"*/}
                    {/*																	className="img-fluid"*/}
                    {/*																	alt=""></a>*/}
                    {/*				</h4>*/}
                    {/*		</div>*/}
                    {/*	</div>*/}
                    {/*	<div className="col-lg-4 col-md-6">*/}
                    {/*		<div className="blog-img" data-aos="flip-left" data-aos-easing="ease-out-cubic"*/}
                    {/*			 data-aos-duration="1500">*/}
                    {/*			<img src="./img/pic10.webp" className="img-fluid baby-wrapper" alt="">*/}
                    {/*				<h4>Lorem ipsum dolor sit amet, consectetur</h4>*/}
                    {/*				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                    {/*					sed do eiusmod tempor incididunt ut labore et.</p>*/}
                    {/*				<h4><a href="blog-detail.php">Continue Reading <img src="./img/arrow-blog.webp"*/}
                    {/*																	className="img-fluid"*/}
                    {/*																	alt=""></a>*/}
                    {/*				</h4>*/}
                    {/*		</div>*/}
                    {/*	</div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*	<div className="col-lg-12 text-center">*/}
                    {/*		<a href="blog-detail.php">*/}
                    {/*			<button className="btn12 text-center-blogs">View All</button>*/}
                    {/*		</a>*/}
                    {/*	</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    )
};

export default ListSection;
