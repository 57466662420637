import React, { useEffect } from 'react';
import '../../assets/css/care-pakage1.css';
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { useDispatch, useSelector } from "react-redux";
import { getPackageRequest, getPackagesRequest } from "../../redux/Packages/Action";
import BottomDetailSection from "../../components/CarePackage/BottomDetailSection";
import { useParams } from "react-router-dom";
import { setLoader } from "../../redux/Loader/Reducer";

const CarePackageDetail = () => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const data = useSelector(state => state?.package?.package);

    const get = async () => {
        dispatch(setLoader(true));
        await dispatch(getPackageRequest(id));
        window.scrollTo(0, 0);
        dispatch(setLoader(false));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        get();
    }, [id])

    return (
        <>
            <InnerBanner PageName={'Care Packages'} />
            <BottomDetailSection item={data} />
        </>
    )
}

export default CarePackageDetail;