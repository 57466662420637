import React from "react";
import { useSelector } from "react-redux";
import { LineImg } from "../../constant/Index";
import { SHOP } from "../../routes/RouteLinks";

const Tagline = () => {
	const home = useSelector((state) => state?.setting?.home);
	return (
		<>
			<section className="tagline-sec">
				<div className="container">
					<img src={LineImg} alt="" className="line" />
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="tagline-wrapper">
								<div className="heading-wrapper wow animate__animated animate__fadeInDown">
									{/* <h3>Homemade with quality ingredients</h3> */}
									<div
										dangerouslySetInnerHTML={{
											__html: home?.sub_header_heading,
										}}
									></div>
								</div>
								<div className="des-wrapper wow animate__animated animate__fadeInDown">
									{/* <p>
										Your Support helps fund pediatric cancer research,
										development of pediatric cancer treatments and provide hope
										to children impacted by pediatric Cancer
									</p> */}
									<div
										dangerouslySetInnerHTML={{ __html: home?.sub_header_para }}
									></div>
								</div>
								<div className="btn-wrapper">
									<a href={SHOP} className="btn">
										Shop Now
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Tagline;
