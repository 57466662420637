import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {
    addWishListApi,
    blogDetailApi,
    blogsApi, deleteWishListApi,
    packageApi,
    packagePlaceOrderApi,
    packagesApi,
    wishListApi
} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {headers} from "../headers";
import {removeWishList, setNewWishList, setWishList} from "./Reducer";

export const getWishListRequest = createAsyncThunk('get-wishlist-request',async (
    url = null,
    {
        getState,
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let resp = await axios.get(wishListApi,headers(token));
        dispatch(setWishList(resp.data.response.data));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const addWishListRequest = createAsyncThunk('add-wishlist-request',async (
    data,
    {
        getState,
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        let token = getState().auth?.token;
        let resp = await axios.post(addWishListApi,data,headers(token));
        await dispatch(setNewWishList(resp.data.response.data));
        await dispatch(setError([
            { message : 'Item added to wishlist', color : 'success'}
        ]));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});

export const deleteWishListRequest = createAsyncThunk('delete-wishlist-request',async (
    id,
    {
        getState,
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    }) => {
    try {
        let token = getState().auth?.token;
        dispatch(removeWishList(id));
        let resp = await axios.delete(deleteWishListApi(id),headers(token));
        await dispatch(setError([
            { message : 'Item removed from wishlist', color : 'success'}
        ]));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});