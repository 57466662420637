import React from 'react';
import '../../assets/css/check-out.css';
import CalenderCop from "../Calender";
import {useSelector} from "react-redux";
import {filterMatchDateData, filterMonthData} from "../../constant/helpers";
import moment from "moment";
import useCalender from "../../hooks/useCalender";

const PickUpDate = (props) => {

    const dates = useSelector((state => state?.setting?.dates?.filter((val)=>{
        return moment(val?.date).month() === moment(props?.orderDate)?.month()
    })));
    const { checkCurrentDateGreaterThan } = useCalender();
    // console.log("dates",dates);

    return (
        <>
            <section className="picupdate">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="btn-review-wrapper12">
                                <button className="btn-reviewpicup">Available Pickup Dates</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            {
                                moment(props?.orderDate, 'DD-MM-YYYY').format('MMM') === "Dec" &&
                                <div className="available">
                                    <h4>Available Pickup dates are as following:</h4>
                                    <h6><span>
                                        {
                                            filterMonthData(props?.orderDate, dates)?.map((val, key) => (
                                                <React.Fragment key={key}>
                                                    {new Date(val)?.getDate() + ','}
                                                </React.Fragment>
                                            ))
                                        }
                                    </span> for this month.</h6>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="calender">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="calender-wrapper">
                                <div className="calender-heading-multiple">
                                </div>
                                <div className="calendert-wrapper">
                                    {/*<div id="datepicker_scnd">*/}

                                    {/*</div>*/}
                                    <CalenderCop
                                        getDates={props?.getDates}
                                        setOrderDate={props?.setOrderDate}
                                        dates={dates}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="post-reviews">
                                <h3>
                                    Select Order Pickup Date
                                </h3>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {
                                            ( props?.orderDate !== null && filterMatchDateData(props?.orderDate, dates).length <= 0 )?
                                            <p className={"text-danger"}>
                                                {/*{filterMatchDateData(props?.orderDate, dates).length}*/}
                                                {/*{moment(props?.orderDate).format('YYYY-MM-DD')}*/}
                                                Selected date is not available
                                            </p>
                                                :
                                            <>
                                                {
                                                    (!checkCurrentDateGreaterThan(props?.orderDate))?
                                                        <p className={"text-danger"}>Previous date is not available</p>
                                                        :
                                                    <div className="btn-review-wrapper">
                                                            <button type={"button"}
                                                                    onClick={()=>props?.onHandleStep(1,'pay_now')}
                                                                    className="btn-review1">Pay Now</button>
                                                            <button type={"button"}
                                                                    onClick={()=>props?.onHandleStep(1,'pay_pickup')}
                                                                    className="btn-review1">Pay at Pickup</button>
                                                        </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PickUpDate;