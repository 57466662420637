import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/style.css";
import {getHomeRequest, homeShopPageRequest} from "../../redux/Setting/Action";
import Shop from "../Home/Shop";
import Banner from "./Banner";
import Facts from "./Facts";
import Tagline from "./Tagline";
import WhatWeDo from "./WhatWeDo";
import {setLoader} from "../../redux/Loader/Reducer";

const Home = () => {
	const dispatch = useDispatch();
	const home = useSelector(state=> state?.setting?.home);

	const get = async()=>{
		dispatch(setLoader(true));
		await dispatch(getHomeRequest());
		await dispatch(homeShopPageRequest());
		window.scrollTo(0, 0);
		dispatch(setLoader(false));
	}

	useEffect(()=>{
		window.scrollTo(0, 0);
		get();
	},[])
	
	return (
		<>
			{/* Header Start Here */}
			{/*<Header />*/}
			{/* Header End Here */}

			{/* Banner Start Here */}
			<Banner />
			{/* Banner End Here */}

			{/* Tagline Sec Start Here */}
			<Tagline />
			{/* Tagline Sec End Here */}

			{/* Shop Section Start Here */}
			<Shop />
			{/* Shop Section End Here */}

			{/* Fact Section Start Here */}
			<Facts />
			{/* Fact Section End Here */}

			{/* What We Do Section Start Here */}
			<WhatWeDo />
			{/* What We Do Section End Here */}

			{/* Footer Start Here */}
			{/*<Footer />*/}
			{/* Footer End Here */}
		</>
	);
};

export default Home;
