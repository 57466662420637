import { createSlice } from '@reduxjs/toolkit'


export const Orders = createSlice({
    name: 'orders',
    initialState : {
        orders : [],
        order : null,
    },
    reducers: {
        setOrders : (state,action) => {
            state.orders = [
                // ...state.orders,
                ...action.payload
            ]
        },
        setOrder : (state,action) => {
            state.order = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setOrders , setOrder } = Orders.actions

export default Orders.reducer