import { createSlice } from '@reduxjs/toolkit'


export const Setting = createSlice({
    name: 'setting',
    initialState : {
        dates : [],
        donationReason : [],
        page : null,
        setting : null,
        donate : null,
        ingredient : null,
        home : null,
        shop : [],
        fact : null,
        about : null,
        teams : [],
        event : null,
        paypalFee : {
            paypal_fee : 0 ,
            total : 0
        },
        stripeFee : {
            stripe_fee : 0,
            total : 0
        },
    },
    reducers: {
        setPage(state,action) {
          state.page = action.payload;
        },
        setDates(state,action) {
            state.dates = action.payload;
        },
        setSetting(state,action) {
            state.setting = action.payload;
        },
        setDonateReason(state,action) {
            state.donationReason = action.payload;
        },
        setDonate(state,action) {
            state.donate = action.payload;
        },
        setIngredient(state,action) {
            state.ingredient = action.payload;
        },
        setHome(state,action) {
            state.home = action.payload;
        },
        setHomeShop(state,action) {
            state.shop = action.payload;
        },
        setFact(state,action) {
            state.fact = action.payload;
        },
        setAbout(state,action) {
            state.about = action.payload;
        },
        setTeam(state,action) {
            state.teams = [...action.payload];
        },
        setEvent(state,action) {
            state.event = action.payload;
        },
        setPaypal(state,action) {
            state.paypalFee = action.payload;
        },
        setStripe(state,action) {
            state.stripeFee = action.payload;
        },
    },
})

export const { setPage ,
    setSetting ,
    setDonate ,
    setDonateReason ,
    setHome,
    setHomeShop,
    setFact,
    setAbout,
    setTeam,
    setEvent,
    setIngredient,
    setPaypal,
    setStripe,
    setDates
} = Setting.actions

export default Setting.reducer