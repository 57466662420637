

import {useState, useEffect} from 'react';
import moment from "moment";

const daysShortArr = [
    'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
];

const monthNamesArr = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
];

const useCalendar = (daysShort = daysShortArr, monthNames = monthNamesArr) => {

    /*
    *   @comment 1
    *   Today Date
    */
    const today = new Date();
    /*
        @ Today Formatted Date E:g // 01-01-2022
    */
    const todayFormatted = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

    /*   @ Days in Week note : 0 is sunday and 1 mon to 6 sat    */
    const daysInWeek = [1, 2, 3, 4, 5, 6, 0];

    const [mode, setMode] = useState('week');

    /* @Selected Date By User By Default is today Date @comment 1  */
    const [selectedDate, setSelectedDate] = useState(today);
    const duplicateSelectDate = selectedDate;

    /* @Get Month Last Date using selected Date  */
    const selectedMonthLastDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

    /* @Get Previous Month Last Date using selected Date  */
    const prevMonthLastDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 0);

    /* @Get Days in Selected Month */
    const daysInMonth = selectedMonthLastDate.getDate();

    /* @Get First Date in Selected Month */
    const firstDayInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).getDay();
    // console.log("firstDayInMonth",firstDayInMonth,daysInMonth,selectedDate.getMonth()+1);

    const startingPoint = daysInWeek.indexOf(firstDayInMonth) + 1;
    // console.log("Today Date AlERT",startingPoint);

    let prevMonthStartingPoint = prevMonthLastDate.getDate() - daysInWeek.indexOf(firstDayInMonth) + 1;

    let currentMonthCounter = 1;

    let nextMonthCounter = 1;

    const rows = 6;
    const cols = 7;

    const calendarRows = {};

    const [calenderWeekRows, setCalenderWeekRows] = useState([]);

    const [noOfWeek, setNoOfWeek] = useState(1);

    const calenderTwoWeekRows = {};

    const calenderDayRows = [];


    /*
  ********************* For Week *********************
*/

    // let newSelectedDate = new Date(selectedDate);
    // let startDateOfWeek = new Date(
    //   newSelectedDate.setDate(
    //     newSelectedDate.getDate() - ( newSelectedDate.getDay() == 0 ? (7) : (newSelectedDate.getDay()) )
    //     )
    // );

    const filterWeekDates = () => {
        let rows = [];

        let newSelectedDate = new Date(selectedDate);
        let startDateOfWeek = new Date(
            newSelectedDate.setDate(
                newSelectedDate.getDate() - (newSelectedDate.getDay() == 0 ? (7) : (newSelectedDate.getDay()))
            )
        );
        // console.log(" Start Week Date", startDateOfWeek);

        daysShort.map((val, key) => {

            let newDate = new Date(startDateOfWeek.setDate(startDateOfWeek.getDate() + 1));

            rows.push({
                day: val,
                dateObj: new Date(newDate),
                today: (newDate.getDate() === today.getDate() && newDate.getMonth() === today.getMonth() && newDate.getFullYear() === today.getFullYear()) ? true : false,
                date: newDate.getDate(),
                month: newDate.getMonth() + 1,
                monthName: monthNamesArr[newDate.getMonth()],
                year: newDate.getFullYear(),
            });
        })
        // console.log("Rows", rows);
        setCalenderWeekRows(() => rows);
    }

    useEffect(() => {
        filterWeekDates();
    }, [selectedDate])

    useEffect(() => {
        // console.log("calenderWeekRows");
        // console.log("calenderWeekRows",calenderWeekRows);
    }, [calenderWeekRows])


    const getPrevWeek = () => {
        let test = new Date(selectedDate.setDate(
            selectedDate.getDate() - (7)
        ));
        setSelectedDate(test);
    }

    const getNextWeek = (date) => {
        // console.log("getNextWeek",date);
        setSelectedDate(date);
    }

    const setCustomDate = (date) => {
        // console.log("setCustomDate", date);
        setSelectedDate(date);
    }
    /*
      ********************* For Week *********************
    */


    /*
      ********************* For Month *********************
  */
    for (let i = 1; i < rows + 1; i++) {
        for (let j = 1; j < cols + 1; j++) {
            //**************if i is empty set empty array = [] */
            if (!calendarRows[i]) {
                calendarRows[i] = [];
            }

            if (i === 1) {
                if (j < startingPoint) {
                    calendarRows[i] = [...calendarRows[i], {
                        classes: 'in-prev-month',
                        dateObj: new Date(`${selectedDate.getMonth() === 0 ? selectedDate.getFullYear() - 1 : selectedDate.getFullYear()}-${selectedDate.getMonth() === 0 ? 12 : selectedDate.getMonth()}-${prevMonthStartingPoint}`),
                        date: `${selectedDate.getMonth() === 0 ? selectedDate.getFullYear() - 1 : selectedDate.getFullYear()}-${selectedDate.getMonth() === 0 ? 12 : selectedDate.getMonth()}-${prevMonthStartingPoint}`,
                        value: prevMonthStartingPoint
                    }];
                    prevMonthStartingPoint++;
                } else {
                    calendarRows[i] = [...calendarRows[i], {
                        classes: '',
                        dateObj: new Date(`${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${currentMonthCounter}`),
                        date: `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${currentMonthCounter}`,
                        value: currentMonthCounter
                    }];
                    currentMonthCounter++;
                }
            } else if (i > 1 && currentMonthCounter < daysInMonth + 1) {
                calendarRows[i] = [...calendarRows[i], {
                    classes: '',
                    dateObj: new Date(`${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${currentMonthCounter}`),
                    date: `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${currentMonthCounter}`,
                    value: currentMonthCounter
                }];
                currentMonthCounter++;
            } else {
                calendarRows[i] = [...calendarRows[i], {
                    classes: 'in-next-month',
                    dateObj: new Date(`${selectedDate.getMonth() + 2 === 13 ? selectedDate.getFullYear() + 1 : selectedDate.getFullYear()}-${selectedDate.getMonth() + 2 === 13 ? 1 : selectedDate.getMonth() + 2}-${nextMonthCounter}`),
                    date: `${selectedDate.getMonth() + 2 === 13 ? selectedDate.getFullYear() + 1 : selectedDate.getFullYear()}-${selectedDate.getMonth() + 2 === 13 ? 1 : selectedDate.getMonth() + 2}-${nextMonthCounter}`,
                    value: nextMonthCounter
                }];
                nextMonthCounter++;
            }
        }
    }

    const getPrevMonth = () => {
        setSelectedDate(prevValue => new Date(prevValue.getFullYear(), prevValue.getMonth() - 1, 1));
    }

    const getNextMonth = () => {
        setSelectedDate(prevValue => new Date(prevValue.getFullYear(), prevValue.getMonth() + 1, 1));
    }

    const matchCurrentDate = (date) => {
        return (`${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}` === date) ? true : false;
    }

    const checkCurrentDateGreaterThan = (date) => {
        let date1 = moment(date);//.format('Y-MM-DD');
        let date2 = moment(new Date()).subtract(1,'days');//.format('Y-MM-DD');
        // console.log((date1 < date2)?false : true)
        return (date1 < date2)?false : true;
    }

    /*
        ********************* For Month *********************
    */

    return {
        daysShort,
        monthNames,
        todayFormatted,
        calendarRows,
        selectedDate,
        duplicateSelectDate,
        setSelectedDate,
        setCustomDate,
        getPrevMonth,
        getNextMonth,
        setMode,
        calenderWeekRows,
        getPrevWeek,
        getNextWeek,
        calenderDayRows,
        matchCurrentDate,
        checkCurrentDateGreaterThan
        // getPrevDay,
        // getNextDay,
    }


}

export default useCalendar;