import {createAsyncThunk} from "@reduxjs/toolkit";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {newsletterApi} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {headers} from "../headers";


export const newsletterRequest = createAsyncThunk('newsletter-request',async (
    data,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        await axios.post(newsletterApi,data,headers());
        dispatch( setError([{
            message : 'Thank you for subscribe newsletter' , color : 'success'
        }] ));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});