import React, {useEffect, useState} from "react";
import {log_out} from "../../constant/Index";
import {onDirectChangeHandler, onInputChangeHandler} from "../../constant/helpers";
import {Link, useNavigate} from "react-router-dom";
import {FORGOT, HOME, LOGIN, RESET_PASSWORD, SIGNUP} from "../../routes/RouteLinks";
import {useDispatch, useSelector} from "react-redux";
import {forgotRequest, loginRequest, signUpRequest, verifyOtpRequest} from "../../redux/Auth/Action";

const Forgot = () => {

    const initial = {
        email: '',
        otp: '',
    }
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [form, setForm] = useState({...initial});
    const [step, setStep] = useState(0);
    const [tryLoader, setTryLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const isLogin = useSelector(state => state?.auth?.isLogin);

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        setLoading(true);
        let x = await dispatch((step === 0) ? forgotRequest(form) : verifyOtpRequest(form));
        setLoading(false);
        if (x.payload) {
            if (step === 1) {
                setForm({...initial});
                navigation(RESET_PASSWORD, {state: {email: form?.email, otp: form?.otp}});
            }
            if (step === 0) setStep(1);
        }
    }


    const tryAgain = async () => {
        setTryLoader(true);
        let x = await dispatch(forgotRequest(form));
        if(x.payload) onDirectChangeHandler('otp','',setForm)
        setTryLoader(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (isLogin) {
            navigation(HOME);
        }
    }, [isLogin])

    return (
        <section className="review-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="img-check-out">
                            <img src={log_out} className="img-fluid" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <form onSubmit={onSubmitHandler}>
                            <div className="post-reviews">
                                <h3>
                                    Forgot Password?
                                </h3>
                                <p>Please Enter Your Email Below</p>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            {
                                                step === 0 ?
                                                    <input type="email" className="form-control"
                                                           id="email"
                                                           value={form?.email} required={true} name={'email'}
                                                           onChange={(e) => onInputChangeHandler(e, setForm)}
                                                           placeholder="Enter your email"/>
                                                    :
                                                    <div
                                                        className={"form-control d-flex align-items-center justify-content-between"}>
                                                        <span>
                                                            {form?.email}
                                                        </span>
                                                        <i onClick={() => setStep(0)}
                                                           className={"text-info fa fa-pencil"}></i>
                                                    </div>
                                            }

                                        </div>
                                        {
                                            step === 1 &&
                                            <div className="form-group">
                                                <label htmlFor="otp">Enter Otp</label>
                                                <input type="number" className="form-control" id="otp"
                                                       value={form?.otp} required={true} name={'otp'}
                                                       onChange={(e) => onInputChangeHandler(e, setForm)}
                                                       placeholder="Enter your otp code"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {
                                            step !== 0 &&
                                            <h4 className="sign-up"> An email has been sent to reset the password <br/>
                                                Haven't Received the email yet? <a onClick={() => {
                                                    if (!tryLoader) tryAgain()
                                                }}> {(tryLoader) ? 'sending...' : 'Try again'}</a></h4>
                                        }
                                        <button type={"submit"} disabled={loading} className="btn-review">
                                            {loading ?
                                                <i className={"fa fa-spin fa-spinner"}></i> : `${(step === 0) ? 'Submit' : 'Verify Otp'}`}
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Forgot;
