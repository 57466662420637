import {fileUrl} from "../Netwrok/api";
import moment from "moment";

export const total = (cart) => {
    let x = 0;
    cart?.map((val) => {
        if (val?.product_type === "pretzel-rods") {
            x += parseFloat(val?.price) * parseInt(val?.qty);
        } else if (val?.product_type === "chocolate-covered-oreos") {
            x += parseFloat(val?.price) * parseInt(val?.qty);
        } else if (val?.product_type === "cookies") {
            x += parseFloat(val?.price);
        } else if (val?.product_type === "hard-tack-candy") {
            x += parseFloat(val?.price) * parseInt(val?.qty);
        } else if (val?.product_type === "apples") {
            x += parseFloat(val?.price) * parseInt(val?.qty);
        } else if (val?.product_type === "bags-of-pretzels") {
            x += parseFloat(val?.price) * parseInt(val?.qty);
        } else {
            x += parseFloat(val?.price) * parseInt(val?.qty);
        }
    });
    return x;
};

export const amountTypeConvert = ( cart , codeData ) => {
    let totalVal = total(cart);

    if (codeData?.discount_type === "amount") {
        return parseFloat(codeData?.discount).toFixed(2);
    } else if (codeData?.discount_type === "percentage") {
        return ((parseFloat(codeData?.discount) / 100) * totalVal).toFixed(2);
    }
    // return 0;
};


export const chunks = (array,length) => {
    const perChunk = length // items per chunk
    const result = array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index/perChunk);
        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
    // console.log(result)
    return result;
}

export const filePreviewer = (file) => {
    // console.log(file);
    return fileUrl+file
}

export const openSearchBar = (className) => {
    let query = document.querySelector('#search');
    query.classList.add(className);
}

export const onInputChangeHandler = (e,setState) => {
    let { name , value } = e.target;
    // console.log("Change",name,value);
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onDirectChangeHandler = (name,value,setState) => {
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onImageChangeHandler = (e,setState) => {
    let { name } = e.target;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {

        setState((preVal)=>{
            return {
                ...preVal,
                [name] : file
            }
        });

    };
    reader.readAsDataURL(file);
};

export const toFormData = (columns = [],form) => {
    let x = new FormData();
    columns.map( (val) => {
        x.append(val, form[val]);
    })
    return x;
}

export const filterMonthData = (currentMonth , data) => {
    return data?.filter((val)=>{
        let x = moment(currentMonth);
        return  x.isSame(val,'month');
    })
}

export const changeElementValueInArray = (id , data , name , value) => {
    let x = [...data];
    let indexNo = x.findIndex((val)=>parseInt(val?.id) === parseInt(id));
    if(indexNo >= 0){
        x[indexNo] = {
            ...x[indexNo],
            [name] : value
        }
    }
    return x;
}

export const filterMatchDateData = (date , data) => {
    // console.log("FIlter Data",data?.filter((val)=>{
    //     let x = moment(date);
    //     return  x.isSame(val);
    // }).length);
    let x = moment(date);
    return data?.filter((val)=>{
        let y = moment(val?.date);
        return  x.format('YYYY-MM-DD') === y.format('YYYY-MM-DD');
    })
}

export const profilePic = (name) => {
    return `https://ui-avatars.com/api/?name=${name}&rounded=true`;
}

export const filterInSelectForm = (data) => {
    let x = [];
    data?.map((val)=>{
        x.push({
            value : val?.id ,
            label : val?.title
        })
    })
    return x;
}