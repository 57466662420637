import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getApplesRequest,
} from "../../redux/ChocolateAndPretzels/action";
import '../../assets/css/chocolate-product-detail.css';
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {changeElementValueInArray, filePreviewer} from "../../constant/helpers";
import {setError} from "../../redux/Errors/Reducer";
import {addNewItem, removePlattersItem, updateQty, updateQtyNumber} from "../../redux/Cart/Reducer";
import {setLoader} from "../../redux/Loader/Reducer";
import {useNavigate} from "react-router-dom";
import {SHOP} from "../../routes/RouteLinks";

const Apples = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apples = useSelector(state => state?.pretzels?.apples);
    const [flavours, setFlavours] = useState([]);
    const cart = useSelector(state => state?.cart?.items);
    const [loading, setLoading] = useState(false);
    const get = async () => {
        // setLoading(true);
        dispatch(setLoader(true));
        await dispatch(getApplesRequest());
        dispatch(setLoader(false));
        // setLoading(false);
    }

    const onQtyChangeHandler = async (id, name, value, type , stock) => {
        value = (type === "+") ? value + 1 : value - 1;
        if (type === "-" && value >= 0 ) {
            setFlavours(() => changeElementValueInArray(id, flavours, name, value));
        } else if ( type === "+" && value >= 0 && value <= stock ) {
            setFlavours(() => changeElementValueInArray(id, flavours, name, value));
        }
    }

    const onLoadFlavours = (data) => {
        let arr = [];
        data?.map((val)=>{
            arr.push({
                ...val,
                image : apples?.image,
                parentTitle : apples?.title,
                price : apples?.price,
                checked: false,
                qty: 1,
            });
        });
        return arr;
    }

    const addToCart = () => {
        flavours.map((value)=>{
            if( value?.checked === true && value?.qty > 0 && value?.qty < parseInt(value?.stock_qty)) {
                let cartItem = cart?.find((val)=> parseInt(val?.id) === parseInt(value?.id));
                if(cartItem){
                    dispatch(removePlattersItem({
                        id : value?.id,
                        product_type : "apples"
                    }));
                }
                // else{
                    dispatch(addNewItem({...value}));
                    dispatch(setError([{
                        message : 'Successfully Added to Cart!' , color : 'success'
                    }]));
                // }
            }
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        get();
    }, []);

    useMemo(() => {
        setFlavours(onLoadFlavours(apples?.flavors || []));
    }, [apples])
    // console.log("Test",
    //     flavours,
    // );

    return (
        <>
            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Product Details"/>
            {/* InnerBanner End Here */}

            <section className="testimoniel">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6">
                            <div className="row reverse-product">
                                {/*<div className="col-lg-2 col-md-2  p-0  nav-container">*/}
                                {/*    <div className="slider-nav">*/}
                                {/*        <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*            <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*                <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*                    <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-lg-10 col-md-10 main-container">
                                    <div className="slider slider-main">
                                        <img src={filePreviewer(apples?.image)} className="img-fluid" alt=""/>
                                        {/*<img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                        {/*    <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                        {/*        <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="main-slider-cookies">
                                <div className="cookies-slider-heading-wrapper">
                                    <div className="cookies-heading1">
                                        <h2>{apples?.title}</h2>
                                    </div>
                                    <div className="dollar-cookies">
                                        <h2 className="dollar-wrapper">
                                            <span>${apples?.price}
                                                <small className="text-sm">(each)</small>
                                            {/*<i className="fa fa-sharp fa-solid fa-heart"></i>*/}
                                        </span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="description">
                                    <h4>Description:</h4>
                                    <p>{apples?.description}</p>
                                </div>
                                <div className="slect_quantity">
                                    <div className="falvours mt-5">
                                        <h3 className="mb-4">Flavors</h3>
                                        {
                                            flavours?.map((val, key) => (
                                                <div key={key}
                                                     className="single_flavour mt-3 d-flex gap-2 align-items-center">
                                                    <div className="form-group-checkbox" style={{width: '44%'}}>
                                                        <input type="checkbox" id={val?.id}
                                                               defaultChecked={val?.checked}
                                                               onClick={(e)=>{
                                                                   setFlavours(changeElementValueInArray(val?.id,flavours,'checked',e.target.checked));
                                                                   // setFlavours(changeElementValueInArray(val?.id,flavours,'qty',0));
                                                               }}
                                                               className="the_input_check"/>
                                                        <label htmlFor={val?.id} className="mb-0">
                                                            <span>{val?.title}</span></label>
                                                    </div>
                                                    {
                                                        val?.checked &&
                                                        <div className="form_row d-flex gap-2 align-items-center qty1">
                                                            <p className="qty mb-0">
                                                                <button type={"button"}
                                                                        onClick={()=>onQtyChangeHandler(val?.id,'qty',parseInt(val?.qty),'-',val?.stock_qty)}
                                                                        className=" minus_in_input"
                                                                        aria-hidden="true">−
                                                                </button>
                                                                <div className={"qtyDiv"}>{val?.qty}</div>
                                                                {/*<input type="number" name="qty" id="qty"*/}
                                                                {/*       className="qtyinp"*/}
                                                                {/*       min="1"*/}
                                                                {/*       onChange={(e)=>onQtyChangeHandler(val?.id,'checked',e.target.value,'qty','val?.stock_qty')}*/}
                                                                {/*       // onChange={(e)=>setFlavours(changeElementValueInArray(val?.id,flavours,'qty',e.target.value))}*/}
                                                                {/*       minLength="1"*/}
                                                                {/*       max={val?.stock_qty}*/}
                                                                {/*    // step="1"*/}
                                                                {/*       defaultValue={val?.qty}/>*/}
                                                                <button type={"button"}
                                                                        onClick={()=>onQtyChangeHandler(val?.id,'qty',parseInt(val?.qty),'+',val?.stock_qty)}
                                                                        className="plus_in_input"
                                                                        aria-hidden="true">+
                                                                </button>
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                        <p id="ShowError" style={{color: "red"}}></p>
                                    </div>
                                </div>
                                <div className="duel-btns mt-5">
                                    {/*<a href="cart.php">*/}
                                    {
                                        flavours.filter((value)=> value?.checked === true && value?.qty > 0 && value?.qty <= parseInt(value?.stock_qty)).length > 0 &&
                                        <>
                                            <button type={"button"}
                                                    onClick={addToCart}
                                                    className="btn2">
                                                <i className="fa-solid fa-cart-shopping"></i>Add to
                                                cart
                                            </button>
                                            <button onClick={() => navigate(SHOP)} className="btn2">
                                                <i className="fa-solid fa-cart-shopping"></i>
                                                Continue Shopping
                                            </button>
                                        </>
                                    }
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Apples;