import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getApplesRequest,
    getCandyRequest, getChocolatePlattersRequest,
    getOreosRequest,
} from "../../redux/ChocolateAndPretzels/action";
import "../../assets/css/chocolate-product-detail.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
    changeElementValueInArray,
    filePreviewer,
} from "../../constant/helpers";
import {setError} from "../../redux/Errors/Reducer";
import {
    addNewItem,
    removeItem,
    removePlattersItem,
    updateQty,
    updateQtyNumber,
} from "../../redux/Cart/Reducer";
import {setLoader} from "../../redux/Loader/Reducer";
import {SHOP} from "../../routes/RouteLinks";
import {useNavigate} from "react-router-dom";

const ChocolatePlatters = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const chocolatePlatters = useSelector((state) => state?.pretzels?.chocolatePlatters);
    const [flavours, setFlavours] = useState([]);
    const [qty, setQty] = useState(1);
    const [ oreoFlavor , setOreoFlavor ] = useState('Milk');
    const total = flavours?.reduce(
        (total, item) =>
            parseInt(total) + parseInt(item?.qty) * parseInt(item?.per_qty || 0),
        0
    );
    const cart = useSelector((state) => state?.cart?.items);

    const get = async () => {
        // setLoading(true);
        dispatch(setLoader(true));
        await dispatch(getChocolatePlattersRequest());
        dispatch(setLoader(false));
    };

    const onQtyChangeHandler = async (id, name, value, type, per_qty) => {
        value = type === "+" ? value + 1 : value - 1;
        if (type === "-" && value >= 0) {
            setFlavours(() => changeElementValueInArray(id, flavours, name, value));
        } else if (type === "+" && value >= 0 && total < parseInt(chocolatePlatters?.stock)) {
            setFlavours(() => changeElementValueInArray(id, flavours, name, value));
        }
    };

    const addToCart = () => {
        // flavours.map((value) => {
        //     if (value?.checked === true && value?.qty > 0) {
                let cartItem = cart?.find(
                    (val) => parseInt(val?.id) === parseInt(chocolatePlatters?.id)
                );
                if (cartItem) {
                    dispatch(
                        removePlattersItem({
                            id: chocolatePlatters?.id ,
                            product_type: chocolatePlatters?.slug,
                        })
                    );
                }
                dispatch(addNewItem({
                    ...chocolatePlatters,
                    product_type: chocolatePlatters?.slug,
                    qty: qty,
                    stock_qty : chocolatePlatters?.stock,
                }));
                dispatch(
                    setError([
                        {
                            message: "Successfully Added to Cart!",
                            color: "success",
                        },
                    ])
                );
            // }
        // });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        get();
    }, []);


    return (
        <>
            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Product Details"/>
            {/* InnerBanner End Here */}

            <section className="testimoniel">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6">
                            <div className="row reverse-product">
                                {/*<div className="col-lg-2 col-md-2  p-0  nav-container">*/}
                                {/*    <div className="slider-nav">*/}
                                {/*        <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*            <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*                <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*                    <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-lg-10 col-md-10 main-container">
                                    <div className="slider slider-main">
                                        <img
                                            src={filePreviewer(chocolatePlatters?.image)}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        {/*<img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                        {/*    <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                        {/*        <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="main-slider-cookies">
                                <div className="cookies-slider-heading-wrapper">
                                    <div className="cookies-heading1">
                                        <h2>{chocolatePlatters?.title}</h2>
                                    </div>
                                    <div className="dollar-cookies">
                                        <h2 className="dollar-wrapper">
                      <span>
                        ${chocolatePlatters?.price}
                          {/*<small className="text-sm">(each)</small>*/}
                          {/*<i className="fa fa-sharp fa-solid fa-heart"></i>*/}
                      </span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="description">
                                    <h4>Description:</h4>
                                    <p>{chocolatePlatters?.description}</p>
                                </div>
                                <div className="out-of-stock-wrapper">
                                    <ul>
                                        <li>
                                            <span>Stock :</span> <span>{chocolatePlatters?.stock}</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="slect_quantity">
                                    <div className="single_quantity mt-4">
                                        <div className="form_row d-flex gap-2 align-items-center qty1">
                                            <label className="text" htmlFor="single">
                                                <b>Quantity: </b>
                                            </label>
                                            <p className="qty mb-0">
                                                <button
                                                    type={"button"}
                                                    onClick={() => {
                                                        if(qty > 0) {
                                                            setQty((preVal) => ((preVal - 1) < 0) ? preVal : preVal - 1);
                                                        }
                                                        // resetFlavours();
                                                        // onSelectFlavour('-');
                                                    }}
                                                    className=" minus_in_input"
                                                    aria-hidden="true"
                                                >
                                                    −
                                                </button>
                                                <div className={"qtyDiv"}>{qty}</div>
                                                <button
                                                    type={"button"}
                                                    onClick={() => {
                                                        if(qty < chocolatePlatters?.stock) {
                                                            setQty((preVal) => preVal + 1);
                                                        }
                                                        // onSelectFlavour('+');
                                                    }}
                                                    className=" plus_in_input"
                                                    aria-hidden="true"
                                                >
                                                    +
                                                </button>
                                            </p>
                                            <p id="ShowError" style={{color: "red"}}></p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    ( qty > parseInt(chocolatePlatters.stock) ) ?
                                        <p id="ShowError" style={{color: "red"}}>Out of stock</p>
                                        :
                                        <div className="duel-btns mt-5">
                                            {parseInt(qty) > 0 && (
                                                <>
                                                    <button
                                                        type={"button"}
                                                        onClick={addToCart}
                                                        className="btn2"
                                                    >
                                                        <i className="fa-solid fa-cart-shopping"></i>Add to cart
                                                    </button>
                                                    <button onClick={() => navigate(SHOP)} className="btn2">
                                                        <i className="fa-solid fa-cart-shopping"></i>
                                                        Continue Shopping
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ChocolatePlatters;
