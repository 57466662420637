import {createAsyncThunk} from "@reduxjs/toolkit";
import header from "../../components/Header/Header";
import {setError} from "../Errors/Reducer";
import axios from "axios";
import {contactUsApi, newsletterApi, productsApi} from "../../Netwrok/api";
import {responseError} from "../../Netwrok/responseHandler";
import {setProducts} from "./Reducer";
import {headers} from "../headers";


export const contactUsRequest = createAsyncThunk('contact-us-request',async (
    data,
    {
        dispatch,
        rejectWithValue ,
        fulfillWithValue
    })=>{
    try {
        await axios.post(contactUsApi,data,headers());
        dispatch( setError([{
            message : `Thanks for Contacting us - we'll reply as soon as possible` , color : 'success'
        }] ));
        return fulfillWithValue(true);
    }catch (e){
        dispatch(setError(responseError(e)));
        return  rejectWithValue(false);
    }
});