import React, {useEffect,useState} from "react";
import {log_out} from "../../constant/Index";
import {onDirectChangeHandler, onInputChangeHandler} from "../../constant/helpers";
import {Link, useNavigate} from "react-router-dom";
import {FORGOT, HOME, LOGIN, SIGNUP} from "../../routes/RouteLinks";
import {useDispatch, useSelector} from "react-redux";
import {loginRequest, signUpRequest} from "../../redux/Auth/Action";
import {CustomPhoneNumber} from "../../components/FormComponent";
// import 'react-phone-number-input/style.css';
// import Input from 'react-phone-number-input/input';

const SignUp = () => {

    const initial = {
        first_name: '',
        last_name : '',
        email: '',
        contact_no : '',
        password: '',
        confirm_password: '',
    }
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [newPass , setNewPass] = useState('password');
    const [confirm,setConfirm] = useState('password');
    const [form, setForm] = useState({...initial});
    const [loading, setLoading] = useState(false);
    const isLogin = useSelector(state => state?.auth?.isLogin);

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        let x = await dispatch(signUpRequest(form));
        setLoading(false);
        if( x.payload ) {
            setForm({...initial});
            navigation(LOGIN);
        }
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        if(isLogin){
            navigation(HOME);
        }
    },[isLogin])

    return (
        <section className="review-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="img-check-out h-100">
                            <img src={log_out} className="img-fluid h-100" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <form onSubmit={onSubmitHandler}>
                            <div className="post-reviews mb-0 mt-4">
                            <h3>
                                Register Your Account
                            </h3>
                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                null Duis
                                aute irure dolor</p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">First Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1"
                                               value={form?.first_name} required={true} name={'first_name'}
                                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                                               placeholder="Enter Name" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">Last Name</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1"
                                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                                               value={form?.last_name} required={true}
                                               placeholder="Enter Last Name" name={'last_name'} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">Email</label>
                                        <input type="email" className="form-control" id="exampleFormControlInput1"
                                               value={form?.email} required={true} name={'email'}
                                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                                               placeholder="Enter Email" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlInput1">Phone</label>
                                        <div className={"form-group"}>
                                            <CustomPhoneNumber
                                                value={form?.contact_no}
                                                onChange={(e)=>{
                                                    onDirectChangeHandler('contact_no',e.target.value,setForm)
                                                }}
                                            />
                                            {/*<Input*/}
                                            {/*    className={"form-control"}*/}
                                            {/*    required={true}*/}
                                            {/*    style={{width:'100%'}}*/}
                                            {/*    placeholder="Enter Phone"*/}
                                            {/*    value={form?.contact_no}*/}
                                            {/*    onChange={(e)=>onDirectChangeHandler('contact_no',e,setForm)}/>*/}
                                        </div>
                                        {/*<input type="tel" className="form-control" id="exampleFormControlInput1"*/}
                                        {/*       value={form?.contact_no} required={true} name={'contact_no'}*/}
                                        {/*       onChange={(e)=>onInputChangeHandler(e,setForm)}*/}
                                        {/*       placeholder="" />*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group password">
                                        <label htmlFor="exampleFormControlInput1">Password</label>

                                        <i onClick={()=> {
                                            setNewPass((preVal)=>(preVal === "password")? 'text':"password");
                                        }}
                                        className={(newPass === "password")?"fa fa-eye-slash" : "fa fa-eye"}
                                        style={{ right : '0px' }}></i>

                                        <input type={newPass} className="form-control" id="exampleFormControlInput1"
                                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                                               placeholder="" value={form?.password} required={true} name={'password'}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group password">
                                        <label htmlFor="exampleFormControlInput1">Confirm Password</label>

                                        <i onClick={() => {
                                            setConfirm((preVal)=>(preVal === "password")? 'text':"password");
                                        }}
                                           className={(confirm === "password")?"fa fa-eye-slash" : "fa fa-eye"}
                                           style={{ right : '0px' }}></i>

                                        <input type={confirm} className="form-control" id="exampleFormControlInput1"
                                               onChange={(e)=>onInputChangeHandler(e,setForm)}
                                               placeholder="" value={form?.confirm_password} required={true} name={"confirm_password"}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <button type={"submit"} disabled={loading} className="btn-review">
                                        {loading? <i className={"fa fa-spin fa-spinner"}></i> : 'Register'}
                                    </button>
                                    <h4 className="sign-up"> Already Have An Account?
                                        <Link to={LOGIN}> Sign in</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
);
};

export default SignUp;
