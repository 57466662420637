import React from "react";

const InnerBanner = (props) => {
	return (
		<>
			<section className="main-sec">
				<div className="container-fluid p-0 m-0">
					<div className="row">
						<div className="col-lg-12">
							<div className="main-top wow animate__animated animate__fadeInUp animate__delay-2s">
								<h2>{props.PageName}</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default InnerBanner;
