import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getApplesRequest,
    getBagsRequest,
} from "../../redux/ChocolateAndPretzels/action";
import "../../assets/css/chocolate-product-detail.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import {
    changeElementValueInArray,
    filePreviewer,
} from "../../constant/helpers";
import {setError} from "../../redux/Errors/Reducer";
import {
    addNewItem,
    removeItem,
    removePlattersItem,
    updateQty,
    updateQtyNumber,
} from "../../redux/Cart/Reducer";
import {setLoader} from "../../redux/Loader/Reducer";
import {useNavigate} from "react-router-dom";
import {SHOP} from "../../routes/RouteLinks";

const Bags = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bags = useSelector((state) => state?.pretzels?.bags);
    const [selections, setSelections] = useState([]);
    const [flavours, setFlavours] = useState([]);
    const [qty, setQty] = useState(1);
    const cart = useSelector((state) => state?.cart?.items);
    const [loading, setLoading] = useState(false);
    const total = flavours?.reduce(
        (total, item) => parseInt(total) + parseInt(item?.qty),
        0
    );
    const cups = parseInt(qty) * 3;

    const get = async () => {
        // setLoading(true);
        dispatch(setLoader(true));
        await dispatch(getBagsRequest());
        // setLoading(false);
        dispatch(setLoader(false));
    };

    const onQtyChangeHandler = async (id, name, value, type) => {
        value = type === "+" ? value + 1 : value - 1;
        if (type === "-" && value >= 0) {
            setFlavours(() => changeElementValueInArray(id, flavours, name, value));
        } else if (type === "+" && value >= 0 && total < cups) {
            setFlavours(() => changeElementValueInArray(id, flavours, name, value));
        }
    };

    const onSelectFlavour = (type) => {
        if (type === "+") {
            let flavourId = flavours.find((val, key) => key === 0)?.id || 0;
            setSelections((prevState) => {
                return [
                    ...prevState,
                    flavourId
                ];
            })
        } else {
            setSelections((prevState) => {
                return [
                    ...prevState.filter((val, key) => key !== (selections.length - 1)),
                ];
            })
        }
    }

    const resetFlavours = () => {
        let arr = [];
        flavours?.map((val) => {
            arr.push({
                ...val,
                qty: 0,
            });
        });
        setFlavours(arr);
    };

    const onLoadFlavours = (data) => {
        let arr = [];
        data?.map((val) => {
            arr.push({
                ...val,
                image: bags?.image,
                // price: bags?.price,
                checked: false,
                qty: 0,
            });
        });
        return arr;
    };

    const addToCart = () => {
        // let outOfStockFlavour = flavours.filter((value) => {
        //     if (value?.checked === true && value?.qty > 0) {
        //         let cartItem = cart?.find(
        //             (val) => parseInt(val?.id) === parseInt(value?.id)
        //         );
        //         if (cartItem && cartItem?.qty >= value?.stock_qty) {
        //             return value;
        //         }
        //     }
        // });

            dispatch(
                removePlattersItem({
                    id: bags?.id,
                    product_type: bags?.slug,
                })
            );
            dispatch(
                addNewItem({
                    ...bags,
                    product_type: bags?.slug,
                    qty: qty,
                    variants: selections,
                })
            );
            dispatch(
                setError([
                    {
                        message: "Successfully Added to Cart!",
                        color: "success",
                    },
                ])
            );
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        get();
        setSelections([
            (flavours.find((val, key) => key === 0)?.id || 0)
        ]);
    }, []);

    // console.log("selections",selections);

    useMemo(() => {
        setFlavours(onLoadFlavours(bags?.flavors || []));
    }, [bags]);

    return (
        <>
            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Product Details"/>
            {/* InnerBanner End Here */}

            <section className="testimoniel">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6">
                            <div className="row reverse-product">
                                {/*<div className="col-lg-2 col-md-2  p-0  nav-container">*/}
                                {/*    <div className="slider-nav">*/}
                                {/*        <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*            <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*                <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*                    <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-lg-10 col-md-10 main-container">
                                    <div className="slider slider-main">
                                        <img
                                            src={filePreviewer(bags?.image)}
                                            className="img-fluid"
                                            alt=""
                                        />
                                        {/*<img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                        {/*    <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                        {/*        <img src="./img/chip17.webp" className="img-fluid" alt="" />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="main-slider-cookies">
                                <div className="cookies-slider-heading-wrapper">
                                    <div className="cookies-heading1">
                                        <h2>{bags?.title}</h2>
                                    </div>
                                    <div className="dollar-cookies">
                                        <h2 className="dollar-wrapper">
                      <span>
                        ${bags?.price}
                          <small className="text-sm" style={{fontSize: "50%"}}>
                          (per bag)
                        </small>
                          {/*<i className="fa fa-sharp fa-solid fa-heart"></i>*/}
                      </span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="description">
                                    <h4>Description:</h4>
                                    <p>{bags?.description}</p>
                                </div>

                                <div className="slect_quantity">
                                    <div className="single_quantity mt-4">
                                        <div className="form_row d-flex gap-2 align-items-center qty1">
                                            <label className="text" htmlFor="single">
                                                <b>Quantity: </b>
                                            </label>
                                            <p className="qty mb-0">
                                                <button
                                                    type={"button"}
                                                    onClick={() => {
                                                        // if(qty < 0)
                                                        setQty((preVal) => ((preVal - 1) < 0) ? preVal : preVal - 1);
                                                        resetFlavours();
                                                        onSelectFlavour('-');
                                                    }}
                                                    className=" minus_in_input"
                                                    aria-hidden="true"
                                                >
                                                    −
                                                </button>
                                                <div className={"qtyDiv"}>{qty}</div>
                                                <button
                                                    type={"button"}
                                                    onClick={() => {
                                                        setQty((preVal) => preVal + 1);
                                                        onSelectFlavour('+');
                                                    }}
                                                    className=" plus_in_input"
                                                    aria-hidden="true"
                                                >
                                                    +
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                    {/*<div className="out-of-stock-wrapper">*/}
                                    {/*  <ul>*/}
                                    {/*    <li>*/}
                                    {/*      <span>Stock :</span> <span>100</span>*/}
                                    {/*    </li>*/}
                                    {/*  </ul>*/}
                                    {/*</div>*/}

                                    {
                                        [...Array(qty)]?.map((val, arrayIndex) => (
                                            <div className={"my-5"} key={arrayIndex}>
                                                <h5 className="mb-1">Bag {arrayIndex + 1}</h5>
                                                <div className="falvours mt-1 d-flex">
                                                    {
                                                        flavours?.map((val, key) => (
                                                            <div
                                                                key={key}
                                                                className="single_flavour mt-3 d-flex gap-2 align-items-center"
                                                            >
                                                                <div className="form-group-checkbox">
                                                                    <input
                                                                        name={`flavour${arrayIndex}`}
                                                                        type="radio"
                                                                        id={arrayIndex + '' + val?.id}
                                                                        value={arrayIndex}
                                                                        defaultChecked={selections.find((value, index) => arrayIndex === index && parseInt(value) === parseInt(val?.id)) ? true : false}
                                                                        checked={selections.find((value, index) => (arrayIndex === index && parseInt(value) === parseInt(val?.id))) ? true : false}
                                                                        onClick={(e) => {
                                                                            let x = [...selections];
                                                                            x[parseInt(e.target.value)] = val?.id;
                                                                            setSelections([...x]);
                                                                        }}
                                                                        className="the_input_check"
                                                                    />
                                                                    <label htmlFor={arrayIndex + '' + val?.id}
                                                                           className="mb-0">
                                                                        <span>{val?.title}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }


                                    {/*<div className="falvours mt-5">*/}
                                    {/*    <h3 className="mb-4">Flavors</h3>*/}
                                    {/*    {flavours?.map((val, key) => (*/}
                                    {/*        <div*/}
                                    {/*            key={key}*/}
                                    {/*            className="single_flavour mt-3 d-flex gap-2 align-items-center"*/}
                                    {/*        >*/}
                                    {/*            <div*/}
                                    {/*                className="form-group-checkbox"*/}
                                    {/*                style={{width: "44%"}}*/}
                                    {/*            >*/}
                                    {/*                <input*/}
                                    {/*                    type="checkbox"*/}
                                    {/*                    id={val?.id}*/}
                                    {/*                    defaultChecked={val?.checked}*/}
                                    {/*                    checked={val?.checked}*/}
                                    {/*                    onClick={(e) => {*/}
                                    {/*                        setFlavours(*/}
                                    {/*                            changeElementValueInArray(*/}
                                    {/*                                val?.id,*/}
                                    {/*                                flavours,*/}
                                    {/*                                "checked",*/}
                                    {/*                                e.target.checked*/}
                                    {/*                            )*/}
                                    {/*                        );*/}
                                    {/*                    }}*/}
                                    {/*                    className="the_input_check"*/}
                                    {/*                />*/}
                                    {/*                <label htmlFor={val?.id} className="mb-0">*/}
                                    {/*                    <span>{val?.title}</span>*/}
                                    {/*                </label>*/}
                                    {/*            </div>*/}
                                    {/*            {val?.checked && (*/}
                                    {/*                <div className="form_row d-flex gap-2 align-items-center qty1">*/}
                                    {/*                    <p className="qty mb-0">*/}
                                    {/*                        <button*/}
                                    {/*                            type={"button"}*/}
                                    {/*                            onClick={() =>*/}
                                    {/*                                onQtyChangeHandler(*/}
                                    {/*                                    val?.id,*/}
                                    {/*                                    "qty",*/}
                                    {/*                                    val?.qty,*/}
                                    {/*                                    "-"*/}
                                    {/*                                )*/}
                                    {/*                            }*/}
                                    {/*                            className=" minus_in_input"*/}
                                    {/*                            aria-hidden="true"*/}
                                    {/*                        >*/}
                                    {/*                            −*/}
                                    {/*                        </button>*/}
                                    {/*                        <div className={"qtyDiv"}>{val?.qty}</div>*/}
                                    {/*                        <button*/}
                                    {/*                            type={"button"}*/}
                                    {/*                            onClick={() =>*/}
                                    {/*                                onQtyChangeHandler(*/}
                                    {/*                                    val?.id,*/}
                                    {/*                                    "qty",*/}
                                    {/*                                    val?.qty,*/}
                                    {/*                                    "+"*/}
                                    {/*                                )*/}
                                    {/*                            }*/}
                                    {/*                            className="plus_in_input"*/}
                                    {/*                            aria-hidden="true"*/}
                                    {/*                        >*/}
                                    {/*                            +*/}
                                    {/*                        </button>*/}
                                    {/*                    </p>*/}
                                    {/*                </div>*/}
                                    {/*            )}*/}
                                    {/*        </div>*/}
                                    {/*    ))}*/}
                                    {/*</div>*/}
                                </div>
                                {
                                    ( qty > parseInt(bags.stock) ) ?
                                        <p id="ShowError" style={{color: "red"}}>Out of stock</p>
                                        :
                                        <div className="duel-btns mt-5">
                                            {parseInt(qty) > 0 && (
                                                <>
                                                    <button
                                                        type={"button"}
                                                        onClick={addToCart}
                                                        className="btn2"
                                                    >
                                                        <i className="fa-solid fa-cart-shopping"></i>Add to cart
                                                    </button>
                                                    <button onClick={() => navigate(SHOP)} className="btn2">
                                                        <i className="fa-solid fa-cart-shopping"></i>
                                                        Continue Shopping
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Bags;
