import React, { useEffect, useState } from "react";
import "../../assets/css/login-your-account.css";
import { log_out } from "../../constant/Index";
import { Link, useNavigate } from "react-router-dom";
import {
  DASHBOARD,
  FORGOT,
  HOME,
  LOGIN,
  SIGNUP,
} from "../../routes/RouteLinks";
import {
  onDirectChangeHandler,
  onInputChangeHandler,
} from "../../constant/helpers";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../redux/Auth/Action";

const Login = () => {
  const initial = {
    email: "",
    password: "",
    remember_me: false,
  };
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [form, setForm] = useState({ ...initial });
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loading, setLoading] = useState(false);
  const [hideIcon, setHideIcon] = useState("password");

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let x = await dispatch(loginRequest(form));
    setLoading(false);
    if (x.payload) {
      setForm({ ...initial });
      navigation(DASHBOARD);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLogin) {
      navigation(HOME);
    }
  }, [isLogin]);

  return (
    <section className="review-sec">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="img-check-out">
              <img src={log_out} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <form onSubmit={onSubmitHandler} className={"login"}>
              <div className="post-reviews">
                <h3>Login Your Account</h3>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat Duisaute irure dolor
                </p>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        value={form?.email}
                        onChange={(e) => onInputChangeHandler(e, setForm)}
                        placeholder="Email"
                        required={true}
                        name={"email"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">Password</label>
                      <div className="eye-btn-wrapper">
                        <i
                          onClick={() => {
                            setHideIcon((preVal) =>
                              preVal === "password" ? "text" : "password"
                            );
                          }}
                          className={
                            hideIcon === "password"
                              ? "far fa fa-eye-slash"
                              : "far fa fa-eye"
                          }
                          style={{ right: "0px" }}
                        ></i>
                      </div>

                      <input
                        type={hideIcon}
                        className="form-control"
                        required={true}
                        id="exampleFormControlInput1"
                        value={form?.password}
                        onChange={(e) => onInputChangeHandler(e, setForm)}
                        placeholder="Your Password"
                        name={"password"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="new">
                      <div
                        className={"d-flex flex-row justify-content-between"}
                      >
                        <div className="form-group-checkbox">
                          <input
                            type="checkbox"
                            id="html"
                            checked={form?.remember_me}
                            name={"remember_me"}
                            onClick={(e) => {
                              onDirectChangeHandler(
                                "remember_me",
                                e.target.checked,
                                setForm
                              );
                            }}
                          />
                          <label htmlFor="html">
                            {" "}
                            <span> Remember Me</span>
                          </label>
                        </div>
                        <h5>
                          <Link to={FORGOT}>Forgot Password?</Link>
                        </h5>
                      </div>
                    </div>
                    {/*<a href="profile.php">*/}
                    <button
                      disabled={loading}
                      type={"submit"}
                      className="btn-review"
                    >
                      {loading ? (
                        <i className={"fa fa-spin fa-spinner"}></i>
                      ) : (
                        "Login"
                      )}
                    </button>
                    {/*</a>*/}
                    <h4 className="sign-up">
                      Don't Have An Account? <Link to={SIGNUP}>Sign Up</Link>
                    </h4>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
