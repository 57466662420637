import { createSlice } from '@reduxjs/toolkit'
import {chocolatePlattersApi} from "../../Netwrok/api";


export const Pretzels = createSlice({
    name: 'pretzels',
    initialState : {
        cookies : null,
        apples : null,
        rods : null,
        oreos : null,
        candy : null,
        bags : null,
        chocolatePlatters : null,
    },
    reducers: {
        setCookies(state,action) {
            state.cookies = action.payload;
        },
        setApples(state,action) {
            state.apples = action.payload;
        },
        setRods(state,action) {
            state.rods = action.payload;
        },
        setOreos(state,action) {
            state.oreos = action.payload;
        },
        setCandy(state,action) {
            state.candy = action.payload;
        },
        setBags(state,action) {
            state.bags = action.payload;
        },
        setChocolatePlatters(state,action) {
            state.chocolatePlatters = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setApples ,
    setOreos ,
    setRods ,
    setBags ,
    setCandy ,
    setCookies,
    setChocolatePlatters
} = Pretzels.actions

export default Pretzels.reducer