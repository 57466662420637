import React from "react";
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import StripeInjected from "./StripeInjected";
import {StripePublishedKey, StripeSecret} from "../../constant/Variables";

const stripePromise = loadStripe(StripePublishedKey);


const StripeElementsWrapper = (props) => {

    const options = {
        clientSecret : StripeSecret,
    };

    return (
        <Elements stripe={stripePromise}>
                {props?.children}
        </Elements>
    )
}

export default StripeElementsWrapper;